import React from 'react'

import { ThemeProvider } from "./themeContext"

const Layout = ({ children }:any) => {
  return (
    <ThemeProvider>
      {/* <Headers /> */}
      <main>{children}</main>
    </ThemeProvider>
  )
}

export default Layout