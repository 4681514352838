import React from 'react'
import { MdClose, MdSearch, MdArrowDropDown, MdArrowDropUp, MdKeyboardArrowLeft } from 'react-icons/md'

export default function VendorList(props: any) {  

    const [sendInvite, setSendInvite] = React.useState(false);  
    const [vendorsList, setVendorsList] = React.useState([]as any);
    const [inviteButton, setInviteButton] = React.useState(true)
    const [getArrayIndex, setGetArrayIndex] = React.useState(0);
    const [container, setContainer] = React.useState(0);
    const [selectedVendor, setSelectedVendor] = React.useState([]as any); 
    const [hideBackButton, setHideBackButton] = React.useState(false)
    
    const ViewVendorDetail = (index: any) => { 
        for(let i = 0; i < selectedVendor.length; i++){
            if(vendorsList[index].firstname === selectedVendor[i].firstname){  
                return(HideInviteButton(index))
            } 
        } 
        props.newIndex(index)
        props.showInvite(true); 
        props.setview(true)
    }

    const HideInviteButton = (index: any)=> { 
        props.newIndex(index)
        props.showInvite(true);
        props.setview(false); 
        setHideBackButton(true)
    }

    const AddVendor = async(index: any) => {  
        props.showInvite(false);   
        props.slides(true);
        props.progressButton(false);
        props.newContainer(container+1);
        const newArr: any =[...selectedVendor]
        newArr[container] = vendorsList[index];
        props.newVendors(newArr);  
        props.close(false)  
    }

    const CloseModal =()=> {
        props.close(false)
        props.showInvite(false); 
    }

    React.useEffect(() => {
        setVendorsList(props.array);
        setContainer(props.container);
        setSendInvite(props.invite);
        setSelectedVendor(props.vendors);
        setGetArrayIndex(props.index);
        setInviteButton(props.view)
    },[vendorsList, container, selectedVendor, sendInvite, getArrayIndex, inviteButton, props.array, props.container, props.vendors, props.invite, props.index, props.view])

    return (
        <>
            {!sendInvite ? 
                <div className="relative rounded-md w-auto mx-auto"> 
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-secondary outline-none focus:outline-none"> 
                        <div className="flex items-center bg-primary justify-center p-2 mb-px rounded-t">
                            <div className='w-full flex flex-1 justify-center rounded-t-lg ' >
                                <h3 className=" font-rubik-medium text-msm text-primary ">{props.vendorType}</h3>
                            </div>
                            <div className=" cursor-pointer h-8 w-8 bg-secondary flex justify-center items-center rounded-full text-primary text-2xl" onClick={() => props.close(false)} >
                                <MdClose size='18' />
                            </div>
                        </div> 
                        <div className='w-full bg-primary rounded-b-lg flex flex-col px-4'>
                            <div className='w-full flex flex-row py-4 items-center' >
                                <div className=' relative w-250px h-10 flex mx-2'>
                                    <input type="text" className=" rounded-md bg-secondary flex items-center text-sm font-rubik-regular text-primary w-full h-full pl-2 " placeholder='Search' />
                                    <div  className='flex items-center flex-row ml-200px absolute '>
                                        <button className='w-auto h-10 cursor-pointer px-3 rounded-md ml-2' >
                                            <MdSearch size='20' className=' bg-secondary' />
                                        </button>
                                    </div>        
                                </div> 
                                <p className='font-rubik-medium text-sm text-primary mx-2' >Sorting</p>
                                <div className="relative  w-200px h-10 mx-2 inline-flex ">
                                    <div className="w-2 h-2 bg-transparent absolute right-0 mt-3 mr-8 pointer-events-none" >
                                        <MdArrowDropDown size='25px' />
                                    </div>
                                    <div className="w-2 h-2 bg-transparent absolute right-0 mt-0 mr-8 pointer-events-none" >
                                        <MdArrowDropUp size='25px' />
                                    </div>
                                    <select className=" cursor-pointer w-full rounded-md text-primary font-rubik-regular text-center pl-5 h-10 text-sm bg-secondary hover:border-gray-400 focus:outline-none appearance-none">
                                        <option>Rating (Highest)</option>
                                        <option>Give me more Option</option>
                                    </select>                                    
                                </div>
                                <p className='font-rubik-medium text-sm text-primary mx-2' >Location</p>
                                <div className="relative  w-200px h-10 mx-2 inline-flex ">
                                    <div className="w-2 h-2 bg-transparent absolute right-0 mt-3 mr-8 pointer-events-none" >
                                        <MdArrowDropDown size='25px' />
                                    </div>
                                    <div className="w-2 h-2 bg-transparent absolute right-0 mt-0 mr-8 pointer-events-none" >
                                        <MdArrowDropUp size='25px' />
                                    </div>
                                    <select className=" cursor-pointer w-full rounded-md text-primary font-rubik-regular text-center pl-5 h-10 text-sm bg-secondary hover:border-gray-400 focus:outline-none appearance-none">
                                        <option>Port Harcourt</option>
                                        <option>Give me more Option</option>
                                    </select>                                    
                                </div>
                            </div>
                            <div className= 'w-full h-350px grid grid-cols-2 gaps-1 px-10 overflow-y-auto mb-10' >
                                {vendorsList.map((item: any, index: any) =>
                                    <div key={index} className='w-auto m-2' >
                                        <div  className=' w-350px h-24 flex flex-wrap  bg-secondary p-px rounded-md' >
                                            <div className=' w-full px-3 flex flex-row items-center bg-primary rounded-md' > 
                                                <div style={{height:'75px', width:'75px'}} className='  rounded-full bg-gray-200 flex justify-center items-center' >
                                                    <img src = {item.displaypic} className='w-full h-full flex flex-1 rounded-full object-cover' alt=""/>
                                                </div>
                                                <div className='w-auto flex flex-col mx-2' >
                                                    <p className='font-rubik-medium text-sm mb-1' >{item.firstname+' '+item.lastname}</p>
                                                    <p className='font-rubik-light text-xs mb-1' >{item.rating}</p>
                                                    <p className='font-rubik-light text-xs' >{item.prize}</p>
                                                </div>
                                                <div className='w-full flex flex-1' />
                                                <button onClick={()=> ViewVendorDetail(index)} className='py-2 w-auto px-6 flex ml-6  bg-ventlyRed text-xs text-white font-heebo-medium' style={{borderRadius:'3px'}} >View</button>
                                            </div>
                                        </div>                                                    
                                    </div>
                                )}
                            </div>
                        </div> 
                    </div>
                </div>
            :
                <div className="relative rounded-md w-auto text-primary mx-auto"> 
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-secondary outline-none focus:outline-none">
                        <div className="flex items-center bg-primary p-2 mb-px rounded-t">
                            {inviteButton || hideBackButton ?
                                <div onClick={()=> props.showInvite(false)} className=" cursor-pointer h-8 w-8 bg-secondary flex  justify-center items-center rounded-full text-primary text-2xl" >
                                    <MdKeyboardArrowLeft size='20' className='bg-secondary ' />
                                </div>  
                            :null }
                            <div className='w-full flex flex-1' />
                            <div className='w-full flex flex-1 justify-center rounded-t-lg ' >
                                <h3 className=" font-rubik-medium text-msm text-primary ">{props.vendorType}</h3>
                            </div>
                            <div className='w-full flex flex-1' />
                            <div className=" cursor-pointer h-8 w-8 bg-secondary flex mr-4 justify-center items-center rounded-full text-primary text-2xl" 
                            onClick={()=> CloseModal()} 
                            >
                                <MdClose size='18' />
                            </div>
                        </div> 
                        <div className='w-full bg-primary flex flex-row px-4 pb-8 rounded-b-lg'>
                            <div className=' flex flex-col px-8 ' style={{width:'380px'}} >
                                <div  className='w-full my-8 flex flex-row' >
                                    <div style={{height:'75px', width:'75px'}} className='  rounded-full bg-gray-200 flex justify-center items-center' >
                                        <img src = {vendorsList[getArrayIndex].displaypic} className='w-full h-full flex flex-1 rounded-full object-cover' alt=""/>
                                    </div>
                                    {/* <img src={props.array[detail].img} alt='' className='w-20 h-20 rounded-full'  /> */}
                                    <div className='w-auto flex flex-col mx-2' >
                                        <p className='font-rubik-medium text-sm mb-1' >{vendorsList[getArrayIndex].firstname+' '+vendorsList[getArrayIndex].lastname}</p>
                                        <p className='font-rubik-light text-xs mb-1' >{vendorsList[getArrayIndex].rating}</p>
                                        <p className='font-rubik-light text-xs' >{vendorsList[getArrayIndex].prize}</p>
                                    </div>
                                </div> 
                                <div className='w-full flex flex-col my-2' >
                                    <p className='text-sm font-rubik-medium' >About</p>
                                    <p className='text-sm font-rubik-regular mt-2'>I'm a world class photographer based in Lagos, Nigeria. My creative style of photography is second to none. When I'm not taking pictures, I'm reading or making sweaters</p>
                                </div>
                                <div className='w-full grid grid-cols-3 gap-1 mt-4' >
                                    <img src={require('../../../../../../assets/images/scott.png')} alt='' sizes='20' className='mb-2' />
                                    <img src={require('../../../../../../assets/images/sergio.png')} alt='' sizes='20' className='mb-2' />
                                    <img src={require('../../../../../../assets/images/sylvia.png')} alt='' sizes='20' className='mb-2' />
                                    <img src={require('../../../../../../assets/images/scott.png')} alt='' sizes='20' className='mb-2' />
                                    <img src={require('../../../../../../assets/images/sergio.png')} alt='' sizes='20' className='mb-2' />
                                    <img src={require('../../../../../../assets/images/sylvia.png')} alt='' sizes='20' className='mb-2' />
                                </div>
                            </div>
                            <div className='flex flex-col px-8 ' style={{width:'380px'}}>
                                <div className='w-full flex flex-row items-center h-24 mb-20'>
                                    <div className='w-auto mt-10'>
                                        <p className='text-md font-rubik-medium' >455</p>
                                        <p className='text-xs font-rubik-light'>Events</p>
                                    </div>
                                    <div className='w-full flex flex-1' />
                                    <div className='w-auto mt-10'>
                                        <p className='text-md font-rubik-medium' >2000</p>
                                        <p className='text-xs font-rubik-light'>Photos</p>
                                    </div>
                                    <div className='w-full flex flex-1' />
                                    <div className='w-auto mt-10'>
                                        <p className='text-md font-rubik-medium' >15040</p>
                                        <p className='text-xs font-rubik-light'>Followers</p>
                                    </div>                                                
                                </div>
                                {sendInvite ? (
                                    <div className='w-full h-full rounded-lg flex justify-center items-center flex-col pt-4 px-4' >
                                        <p className='font-rubik-medium text-md w-full flex justify-center mt-2'>{'Send Offer To '+vendorsList[getArrayIndex].firstname+' '+vendorsList[getArrayIndex].lastname}</p>
                                        <p className='font-rubik-light text-xs w-full flex justify-center' >{props.vendorType}</p>
                                        <p className='font-rubik-medium text-lg w-full flex justify-center mt-2'>$100,000</p> 
                                        <p className='font-rubik-light text-xs w-full flex justify-center'>Minimum</p> 
                                        {inviteButton ?
                                        <>
                                            <input type="text" className=" rounded-md bg-secondary text-primary items-center text-xs px-5 font-Rubik-regular w-full h-10 mt-10" placeholder='min $1000' />          
                                            <textarea className="w-full h-24 rounded-md bg-secondary text-primary text-xs p-2 font-Rubik-regular mt-2 " placeholder='Additional Message' /> 
                                            <div className='w-full flex'>
                                                <div className='w-full flex flex-1' />
                                                <button onClick={()=> AddVendor(getArrayIndex)} className='py-3 w-24 h-10 mt-4 bg-ventlyRed text-xs text-white font-rubik-medium' style={{borderRadius:'3px'}} >Send Invite</button>          
                                            </div>   
                                        </>
                                        :null }  
                                    </div>
                                ):(
                                    <div className='w-full h-full rounded-lg bg-secondary flex flex-col items-center pt-4 px-5 pb-10'>
                                        <p className='font-rubik-medium text-md w-full flex justify-center mt-auto'>Invite Sent</p>
                                        <p className='font-rubik-regular text-xs w-full flex justify-center text-center mt-4 mb-auto' >Your invite has been sent successfully, you can keep negotiating with this user from your messages</p>
                                    </div>
                                )}
                            </div>                               
                        </div> 
                    </div>
                </div>}
        </>
    )
}
