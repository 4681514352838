import React from 'react';

import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker, KeyboardTimePicker } from '@material-ui/pickers';
import * as yup from "yup";
import { useFormik } from 'formik';
import { MdArrowDropDown } from 'react-icons/md';
import Map from './Event_Components/GoogleMap'
import { motion } from 'framer-motion';
import EventPhoto from './Event_Components/EventPhoto'
import EventMedia from './Event_Components/EventMedia'
import * as axios from "axios";
import { BASEURL } from "../../../../globals/URL";
import {EventSchema, EventContext} from '../../../../Contexts/EventContext'; 


export default function EventInfo(props: any) {
  
    const [media, SetMedia] = React.useState([])   
    const eventContext: EventSchema = React.useContext(EventContext);
    const [markers, SetMarkers] = React.useState({}as any)
    const [place, SetPlace] = React.useState('')
    const [selectedDate, setSelectedDate] = React.useState(new Date());
    const [open, setOpen] = React.useState(false);
    const [time, setTime] = React.useState(false);
    const [interests, setInterest] = React.useState([]); 
    
    const loginSchema = yup.object({
        title: yup.string()
            .required('Required')
            .min(6, 'A minimium of 6 characters'),
        description: yup.string()
            .required('Required')
            .min(15, 'A minimium of 15 characters'),
        category: yup.string()
            .required('Required')
            .oneOf(interests, 'Invalid Event Category'),
        // TimeZone: yup.string()
        //     .required('Required')
        //     .oneOf(['Give me more Option'], 'Invalid TimeZone'), 
        privacy: yup.string()
            .required('Required')
            .oneOf(['Public', 'Private'], 'Invalid EventPrivacy'), 
        Tickets: yup.string()
            .required('Required')
            .oneOf(['Enable', 'Disable'], 'Invalid Ticket'),
    }) 
     
        // formik
        const formik = useFormik({
            initialValues: {
                title: '',
                description: '',
                category: '',   
                photo: '', 
                VideoUrl:'',
                privacy:'',
                Tickets:''
            },
            validationSchema: loginSchema,
            onSubmit:() => { },
        });

        const handleDateChange = (date: any) => { 
            setSelectedDate(date);
            setOpen(false);
        };

        const handleTimeChange = (date: any) => {
            setSelectedDate(date);
            setTime(false)
        }; 

        const handleSubmit = async() => {
            if (!formik.dirty) {
                alert("Please complete your event information")
            } else if (!formik.isValid) {
                alert("Please fillin the form properly")
            } else if (markers.lat === 0) {
                alert("Please specify the location on the map")
            } 
            else if (!eventContext.bannerfile) {
                alert("Please provide an image for Your Event")
            } else{ 

                eventContext.setEventData({...eventContext.eventData, 
                    title: formik.values.title,
                    description: formik.values.description,
                    location: place,
                    latitude: markers.lat,
                    longitude: markers.lng,
                    start_date: selectedDate.toDateString()+', '+selectedDate.toLocaleString('en-US', { hour: 'numeric', hour12: true, minute: '2-digit' }),
                    end_date: '', 
                    privacy: formik.values.privacy === 'Private' ? true : false ,
                    category: formik.values.category, 
                }) 
                eventContext.setMarker(markers) 
                eventContext.setFeaturedImage(media)
                eventContext.setVideoUrl(formik.values.VideoUrl)

                if(formik.values.Tickets === 'Enable'){
                    props.click()
                } else { 
                    props.skip()
                } 
            }
        }  

    React.useMemo(() => {
        (async function () {
            const result = await axios.default.get(`${BASEURL.URL}/user/interests`); 
            const interests = result.data.data;
            setInterest(interests); 
            })();
        }, []); 

    const renderDate = (props: any) => {
        return(
            <div className='w-full h-12 cursor-pointer bg-secondary flex flex-row items-center justify-center text-primary text-xs font-Rubik-regular rounded-md' onClick={() => setOpen(isOpen => !isOpen)}>
                <img className='mr-8 ' width='20' src={require('../../../../assets/icons/date.svg')} alt='' />
                <p>{props.value}</p>
            </div>
        )
    }
    const renderTime = (props: any) => {
        return(
            <div className='w-full h-12 cursor-pointer bg-secondary flex flex-row items-center justify-center text-primary text-xs font-Rubik-regular rounded-md' onClick={() => setTime(isOpen => !isOpen)}>
                <img className='mr-8 ' width='20' src={require('../../../../assets/icons/clock.svg')} alt='' />
                <p>{props.value}</p>
            </div>
        )
    }
  return (
    <div className='w-full bg-primary pt-12 px-5 rounded-md pb-16 mt-5'>
        <EventPhoto />
        <div className='flex flex-col px-5'>
            <div className='flex mt-16 flex-row' >
                <div className='flex flex-col w-300px'>
                    <h1 className='text-sm text-primary mb-2 font-rubik-medium'>Name & Category</h1>
                    <p className='text-xs text-primary font-rubik-regular'>Name your event and select the category it falls under to help people discover your event</p>
                </div>                                
                <div className='flex flex-1 flex-row' >
                    <div className='w-full mx-8'>
                        <p className="text-xs font-rubik-medium  mb-2">Event Name</p>
                        <input type="text" 
                        name='title' 
                        value={formik.values.title}
                        onChange={formik.handleChange}
                        onFocus={() => formik.setFieldTouched('title', true, true)}
                        className=" rounded-md bg-secondary text-primary items-center text-xs pl-5 font-Rubik-regular text-darkmodefont w-full h-12 " placeholder='Event Name' />
                        <div className="w-full h-5 max-h-5">
                            {
                                formik.touched.title && formik.errors.title && (
                                    <motion.p 
                                        initial={{ y: -100, opacity: 0 }}
                                        animate={{ y: 0, opacity: 1 }}
                                        className="text-xs text-ventlyRed mt-2">{formik.errors.title}</motion.p>
                                )
                            }
                        </div>
                    </div>
                    <div className='w-full mx-8'>
                        <p className="text-xs font-rubik-medium mb-2">Event Category</p>
                           
                        <div className="relative  w-full h-12 inline-flex ">
                            <div className="w-2 h-2 bg-transparent absolute right-0 mt-3 mr-8 pointer-events-none" >
                                <MdArrowDropDown size='25px' />
                            </div>
                            <select className=" cursor-pointer w-full rounded-md text-primary font-rubik-regular text-center pl-5 text-xs bg-secondary hover:border-gray-400 focus:outline-none appearance-none"
                                name='category'
                                onChange={formik.handleChange}
                                onFocus={() => formik.setFieldTouched('category', true, true)}>
                                <option>Select Category</option>
                                {interests.map((item, index)=> {
                                    return(
                                        <option key={index} >{item}</option> 
                                    )
                                })}
                            </select>                         
                        </div>
                        <div className="w-full h-5 max-h-5">
                            {
                                formik.touched.category && formik.errors.category && (
                                    <motion.p 
                                        initial={{ y: -100, opacity: 0 }}
                                        animate={{ y: 0, opacity: 1 }}
                                        className="text-xs text-ventlyRed mt-2">{formik.errors.category}</motion.p>
                                )
                            }
                        </div>  
                    </div>
                </div>
            </div>
            <div className='flex mt-16 flex-row' >
                <div className='flex flex-col w-300px'>
                    <h1 className='text-sm text-primary mb-2 font-rubik-medium'>Date & Time</h1>
                    <p className='text-xs text-primary font-rubik-regular'>Let People know when your event is happening, set your timezone date and time and we'll do the rest </p>
                </div>  
                <div className='flex flex-1 flex-row'>
                    <div className='w-full flex flex-col mx-8'>
                        {/* <div className='w-full flex flex-col mb-5'>
                            <p className="text-xs font-rubik-medium  mb-2">Timezone</p>
                                   
                            <div className="relative w-full h-12 inline-flex ">
                                <div className="w-2 h-2 absolute right-0 mt-3 mr-8 pointer-events-none" >
                                    <MdArrowDropDown size='25px' />
                                </div>
                                <select className=" cursor-pointer w-full rounded-md text-primary font-rubik-regular text-center pl-5 text-xs bg-secondary hover:border-gray-400 focus:outline-none appearance-none"
                                    name='TimeZone'
                                    onChange={formik.handleChange}
                                    onFocus={() => formik.setFieldTouched('TimeZone', true, true)}>
                                    <option>Select Time Zone</option>
                                    <option>Give me more Option</option>
                                </select>                                    
                            </div>
                            <div className="w-full h-5 max-h-5">
                                {
                                    formik.touched.TimeZone && formik.errors.TimeZone && (
                                        <motion.p 
                                            initial={{ y: -100, opacity: 0 }}
                                            animate={{ y: 0, opacity: 1 }}
                                            className="text-xs text-ventlyRed mt-2">{formik.errors.TimeZone}</motion.p>
                                    )
                                }
                            </div>
                        </div> */}
                        <div className='w-full flex flex-col'>
                            <p className="text-xs font-rubik-medium mb-2">Date</p>
                            <MuiPickersUtilsProvider  utils={DateFnsUtils}>
                                <DatePicker
                                    disablePast
                                    open={open}
                                    onOpen={() => setOpen(true)}
                                    onClose={() => setOpen(false)}
                                    format="dd/MM/yyyy"
                                    name='StartTime'
                                    value={selectedDate} 
                                    onChange={handleDateChange}
                                    TextFieldComponent={renderDate}  />
                                                   
                            </MuiPickersUtilsProvider>
                        </div>
                    </div>
                    <div className='w-full mx-8'>
                        <p className="text-xs font-rubik-medium  mb-2">Start Time</p>
                        <MuiPickersUtilsProvider  utils={DateFnsUtils}>
                            <Grid container justify="space-around">
                                <KeyboardTimePicker
                                    open={time}
                                    onOpen={() => setTime(true)}
                                    onClose={() => setTime(false)}
                                    id="time-picker"
                                    value={selectedDate}
                                    onChange={handleTimeChange}
                                    TextFieldComponent={renderTime} 
                                    InputProps={{
                                    disableUnderline: true
                                    }}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change time',
                                        }}/>
                            </Grid>
                        </MuiPickersUtilsProvider>
                    </div>
                </div>
            </div>
            <div className='flex  mt-16 flex-row' >
                <div className='flex flex-col w-300px'>
                    <h1 className='text-sm text-primary mb-2 font-rubik-medium'>Location</h1>
                    <p className='text-xs text-primary font-rubik-regular'>Select where the event is holding on the map or by typing in the location</p>
                </div>  
                <div className='flex flex-1' >
                    <Map marker={SetMarkers} location={SetPlace} />
                </div>
            </div>
            <div className='flex mt-16 flex-row' >
                <div className='flex flex-col w-300px'>
                    <h1 className='text-sm text-primary mb-2 font-rubik-medium'>Media</h1>
                    <p className='text-xs text-primary font-rubik-regular'>Add picture and embed videos related to your event here</p>
                </div>  
                <div className='flex flex-1 flex-col mx-8'>
                    <div className='w-full '>
                        <p className="text-xs font-rubik-medium  mb-1">Media</p>
                        <p className="text-xs font-rubik-regular mt-1 mb-2">You can add up to 5 pictures</p>
                        <EventMedia media={SetMedia} />
                    </div>
                <div className='w-full mt-8'>
                    <p className="text-xs font-rubik-medium  mb-1">Video Url</p>
                    <p className="text-xs font-rubik-regular mt-1 mb-2">Enter Youtube video link</p>
                    <input type="text" 
                        name='VideoUrl' 
                        value={formik.values.VideoUrl}
                        onChange={formik.handleChange}
                        onFocus={() => formik.setFieldTouched('VideoUrl', true, true)}
                        className=" rounded-md bg-secondary text-primary items-center text-xs pl-5 font-Rubik-regular text-darkmodefont w-full h-12 " placeholder='Youtube Video Url' />
                        <div className="w-full h-5 max-h-5">
                            {
                                formik.touched.VideoUrl && formik.errors.VideoUrl && (
                                    <motion.p 
                                        initial={{ y: -100, opacity: 0 }}
                                        animate={{ y: 0, opacity: 1 }}
                                        className="text-xs text-ventlyRed mt-2">{formik.errors.VideoUrl}</motion.p>
                                )
                            }
                        </div>
                    {/* <input type="text" className=" rounded-md bg-secondary text-primary items-center  text-xs pl-5 font-Rubik-regular text-darkmodefont w-full h-12 " placeholder='Youtube Video Url' /> */}
                </div>
            </div>
        </div>
        <div className='flex mt-16 flex-row' >
            <div className='flex flex-col w-300px'>
                <h1 className='text-sm text-primary mb-2 font-rubik-medium'>Description & Setting</h1>
                <p className='text-xs text-primary font-rubik-regular'>Add picture and embed videos related to your event here</p>
            </div>  
            <div className='flex flex-1 flex-col'>
                <div className='w-full'>
                    <div className='w-full px-8 '>
                        <p className="text-xs font-rubik-medium mb-2">Event Description</p>
                        <textarea 
                            name='description' 
                            // value={formik.values.EventDescription}
                            onChange={formik.handleChange}
                            onFocus={() => formik.setFieldTouched('description', true, true)}
                            className="w-full  h-40 rounded-md bg-secondary text-primary text-xs p-5 font-Rubik-regular text-darkmodefont " />
                        <div className="w-full h-5 mb-8 max-h-5">
                            {
                                formik.touched.description && formik.errors.description && (
                                    <motion.p 
                                        initial={{ y: -100, opacity: 0 }}
                                        animate={{ y: 0, opacity: 1 }}
                                        className="text-xs text-ventlyRed mt-2">{formik.errors.description}</motion.p>
                                    )
                            }
                        </div>
                    </div>
                </div>                                    
                <div className='flex flex-row mt-4' >
                    <div className='w-full mx-8'>
                        <p className="text-xs font-rubik-medium mb-2">Event Privacy</p>
                                           
                        <div className="relative w-full h-12 inline-flex ">
                            <div className="w-2 h-2 absolute right-0 mt-3 mr-8 pointer-events-none" >
                                <MdArrowDropDown size='25px' />
                            </div>
                            <select className=" cursor-pointer w-full rounded-md text-primary font-rubik-regular text-center pl-5 text-xs bg-secondary hover:border-gray-400 focus:outline-none appearance-none"
                                name='privacy'
                                onChange={formik.handleChange}
                                onFocus={() => formik.setFieldTouched('privacy', true, true)}>
                                <option>Select Event Privacy</option>
                                <option>Public</option>
                                <option>Private</option>
                            </select>                                    
                        </div>
                        <div className="w-full h-5 max-h-5">
                            {
                                formik.touched.privacy && formik.errors.privacy && (
                                    <motion.p 
                                        initial={{ y: -100, opacity: 0 }}
                                        animate={{ y: 0, opacity: 1 }}
                                        className="text-xs text-ventlyRed mt-2">{formik.errors.privacy}</motion.p>
                                )
                            }
                        </div>
                    </div>
                    <div className='w-full mx-8'>
                        <p className="text-xs font-rubik-medium mb-2">Tickets</p>
                                      
                        <div className="relative w-full h-12 inline-flex ">
                            <div className="w-2 h-2 absolute right-0 mt-3 mr-8 pointer-events-none" >
                                <MdArrowDropDown size='25px' />
                            </div>
                            <select className=" cursor-pointer w-full rounded-md text-primary font-rubik-regular text-center pl-5 text-xs bg-secondary hover:border-gray-400 focus:outline-none appearance-none"
                                name='Tickets'
                                onChange={formik.handleChange}
                                onFocus={() => formik.setFieldTouched('Tickets', true, true)}>
                                <option>Select Ticket Options</option>
                                <option>Enable</option>
                                <option>Disable</option>
                            </select>                                    
                        </div>
                        <div className="w-full h-5 max-h-5">
                            {
                                formik.touched.Tickets && formik.errors.Tickets && (
                                    <motion.p 
                                        initial={{ y: -100, opacity: 0 }}
                                        animate={{ y: 0, opacity: 1 }}
                                        className="text-xs text-ventlyRed mt-2">{formik.errors.Tickets}</motion.p>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className=' w-full flex mt-8 '>
            <div className='w-full flex flex-1 flex-row mx-8'>
                <div className='w-full flex' />
                    <button onClick={handleSubmit} type='submit'  className='py-3 w-24 bg-ventlyRed text-xs text-white font-rubik-medium' style={{borderRadius:'3px'}} >Continue</button>
                </div>
            </div>
        </div>
    </div>
  );
}