import React from 'react'
import { Route, useHistory } from 'react-router-dom' 

export default function AccessedRoutes({ isAuthenticated, ...props }: any) { 

    const history = useHistory();

    React.useEffect(() => { 

        const token = localStorage.getItem('token') as string;
        const signuptoken = sessionStorage.getItem('token') as string; 
        const userId = localStorage.getItem('id') as string; 

        if(!token){
            if(!signuptoken){
                if(userId){ 
                    history.push('/verifyemail')
                }
            } else {
                history.push('/feed')
            }
        }else { 
            history.push('/feed')
        }
    },[history])

    return  <Route {...props} /> 
    
    
    // return !isAuthenticated ?
    //     (   
    //         !id ?(
    //             <Route {...props} />  
    //         ):(
    //             <Redirect to= '/verifyemail' />
    //         )
    //     ):(
    //         <Redirect to= '/feed' />
    //     )
}
