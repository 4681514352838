import * as axios from 'axios'
// import firebase from 'firebase'
import { IReturnObject } from '../../globals/ReturnOject'
// import { Firebase } from '../../globals/Firebase'
import { BASEURL } from '../../globals/URL'

type userPayload = {
    interests : Array<string>
}

let userID = localStorage.getItem('id') as string; 

export const UpdateInterests = async(payload: userPayload): Promise<IReturnObject> => {
    const ReturnObj: IReturnObject = {
        error: false,
        data: null,
        errorMessage: '',
        errorData: null,
        successMessage: '',
        statusCode: 200
    }
    try {
        // get the full name
        // make request to vently server
        const request = await axios.default.put(`${BASEURL.URL}/user/interests/${userID}`, payload, {
                headers: { 'content-type': 'application/json'}
            })   
            sessionStorage.setItem('token', userID);
            return request.data as IReturnObject;
    } catch (error) {
        ReturnObj.error = true;
        ReturnObj.errorMessage = 'An error occured while trying to Create your account';
        ReturnObj.errorData = error;
        ReturnObj.statusCode = 400;
        return ReturnObj;
    }
}