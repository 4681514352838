import {
    useQuery,
    } from "react-query";
import axios from 'axios'; 
import { BASEURL } from "../../globals/URL"; 
 
export function FeedData() {


    let token = localStorage.getItem("token")
    // console.log(token)

    return useQuery("posts", async () => {
        const request = await axios.get(`${BASEURL.URL}/feeds/${localStorage.getItem('id')+''}`, {
            headers: { 
                'content-type': 'application/json',
                Authorization : `Bearer ${token}`}
        })    
        console.log(request)
        return request.data;
        });
}