import React from 'react'
import FeedChip from './Components/FeedChip';
import FeedSkeleton from './Components/FeedSkeleton';
import OtherFeedsContents from './Components/OtherFeedsContents';
import Newevent from './Components/PostEvent'

export default function FeedData(props: any) {

    const [loading, SetLoading] = React.useState(true)   

    React.useEffect(() => {
        SetLoading(true); 
        const timing = setTimeout(() => {
            SetLoading(false);
        }, 5000);
        return () => clearTimeout(timing); 
    },[]);   
    
    return (
        <div >
            <div className={!loading ? 'flex flex-col' : 'hidden'} > 
                <Newevent />
                {props.data.events.map((item: any, index: any) => {
                    return(
                        <div key={index} className='w-full'> 
                            {index % 2 === 0 && index !== 0 ? 
                                <div key={index}>
                                    <OtherFeedsContents events={props.data.events} index={index} vendor={props.data.users} />
                                </div>
                            : null
                            }
                            <FeedChip userId={item.user_id} img={item.banner_image} date={item.start_date} title={item.title} location={item.location} description={item.description} month={item.start_date.substring(4,8)} day={item.start_date.substring(8,11)} /> 
                        </div>
                    )
                })} 
            </div> 
            <div className={!loading ? 'hidden' : 'flex flex-col'}>
                <FeedSkeleton /> 
            </div> 
        </div> 
    )
}
