import React from 'react'; 
import { useHistory} from 'react-router-dom';
import {motion} from 'framer-motion'
import Photo from '../../Universal-Components/Background_Photo';
import Mobile from '../../Mobile-Screens/HomeScreen/Home'

export default function Homepage() {
    const history = useHistory();  

    return (
        <>
            <div className="w-screen h-screen flex sm:hidden md:hidden lg:flex"> 
            
                <Photo img={require('../../../assets/images/4.png')} />

                <div className="flex-1 bg-white py-20 px-10 flex flex-col"> 

                    <div className="w-full flex flex-1 justify-center items-center">
                        <motion.div 
                            initial={{ opacity: 0, y: -200 }}
                            animate={{ y: 0, opacity: 1 }}
                            transition={{ delay: 1.5, type: 'spring', stiffness: 120 }}
                            className="flex flex-col">

                            <div className="w-full">
                                <img src={require('../../../assets/logo.svg')} width="30" alt=""/>
                            </div>
                                <p className="text-2xl font-poppins-medium mt-6">Events Should Be Awesome <br/> Join The Community</p>
                                <p className="text-sm mt-2 text-gray-600 font-heebo-regular">We can't wait to see you on board. Get started today</p>

                            <div className="w-full flex mt-6">
                                <motion.button 
                                whileHover={{ scale: 1.1 }}
                                onClick={() => history.push('/login')}
                                className=" bg-ventlyRed text-white rounded w-32 h-10 text-xs font-heebo-medium">LOG IN</motion.button>
                                <motion.button 
                                whileHover={{ scale: 1.1 }}
                                onClick={() => history.push('/signup')}
                                className="flex-1 border-1 ml-3 border-ventlyRed text-ventlyRed w-32 rounded h-10 text-xs font-heebo-medium">CREATE ACCOUNT</motion.button>
                            </div>
                        </motion.div>
                    </div>


                </div>
            </div>
            <div className='w-full h-full lg:hidden' >
                <Mobile />
            </div>
        </>
    )
}
