import React from "react";
import { motion } from "framer-motion";
// import { BASEURL } from "../globals/URL";
// import * as axios from "axios";
// import "./styles/Setusernamestyle.css";
// import { IReturnObject } from "../globals/ReturnOject";
import { useFormik } from "formik";
import * as yup from "yup";
import { useHistory } from "react-router-dom"; 
// import { DownloadURLHook, useDownloadURL} from 'react-firebase-hooks/storage';
// import {Spinner} from '@chakra-ui/core'
// import * as firebase from 'firebase';
import { Signup } from '../../Actions/updateUsername'; 
import { Display } from '../../Actions/DisplayPicAction';
import { MdArrowDropDown } from "react-icons/md";
import Photo from '../../Universal-Components/Background_Photo'
import Axios from "axios";
import { BASEURL } from "../../../globals/URL";
import { IReturnObject } from "../../../globals/ReturnOject";

export default function Setusername() {

  let type:any = null
  const [accountType, setAccountType] = React.useState(1); 
  const [businessTypes, setBusinessTypes] = React.useState([]);
  const [businessType, setBusinessType] = React.useState(type);
  const [image, setImage] = React.useState(null);
  const [selectedImage, setselectedImage] = React.useState('')
  const [submitting, setSubmitting] = React.useState(false); 
  const history = useHistory();   

  const validationSchema = yup.object({
    username: yup
      .string()
      .required("This field is required")
      .min(3, "A minimium of 3 characters"),
  });

  const HandleBusinesType =(e: any)=> {
    setBusinessType(e.target.value)
  }

  const formik = useFormik({
    initialValues: { 
      username: "",  
    },
    validationSchema: validationSchema,
    onSubmit: () => undefined,
  });

  const HandleisBusiness = async (e: any)=> { 
    setAccountType(parseInt(e.target.value));  
  }

  React.useMemo(() => {
    (async function () {
      const request = await Axios.get(
        `${BASEURL.URL}/user/businesstypes`
      );
      const data: any = request.data.data; 
      setBusinessTypes(data);
    })();
  }, []); 

  // methods
  const handleFilePicker = (e: any) => {
    const file = e["target"].files[0];
    setselectedImage(file)
    const reader = new FileReader(); 
    reader.readAsDataURL(file);
    reader.onload = () => {
      setImage(reader.result as any);
    };
  };

  const uploadImage = () => {
      const formData = new FormData(); 
      formData.append('file', selectedImage);
      formData.append('folder', 'vently-Profile');
      formData.append('upload_preset', 'svt578zq');

      Axios.post(
        'https://api.cloudinary.com/v1_1/dm0jlrcft/upload',
        formData
      ).then(async (response)=> {  

          const profile = { 
            displaypic: response.data.secure_url
          }  
          const request: IReturnObject = await Display(profile) as IReturnObject;
          if (request.statusCode === 200) {
            console.log('sucessfull')
            setSubmitting(false); 
          }else if (request.statusCode === 400) {
            setSubmitting(false);
            alert(request.errorMessage);
          }else {
            setSubmitting(false);
            alert(request.errorMessage);
          }
      })
  }

  const handleSubmit = async () => {
   try {
    
    if (!formik.touched) {
      alert("Please fill in the form");
    } else if (!formik.isValid) {
      alert("Please fillin the form correctly")
    }else if (!formik.dirty) {
      alert("Please fillin the form correctly")
    }else if (selectedImage === '') {
      alert("Please provide an Image")
    }else if (businessType === 'Categories') {
      alert("Please Select a Business Type")
    }else {
      setSubmitting(true);
      uploadImage();  
      const Obj = {
        username: formik.values.username, 
        isBusiness: accountType === 2 ? true : false,
        BusinessType: businessType 
      }
      const request: IReturnObject = (await Signup(
        Obj
    )) as IReturnObject;   
      if (request.statusCode === 200) {
        setSubmitting(false);
        history.push('/interests')
      }else if (request.statusCode === 400) {
        setSubmitting(false);
        alert(request.errorMessage);
      }else {
        setSubmitting(false);
        alert(request.errorMessage);
      }
      
    } 
   } catch (error) {
     setSubmitting(false);
     alert(error);
   }
  }

  return (
    <>
      <input
        onChange={(e)=> {
          handleFilePicker(e);
        }}
        type="file"
        accept="image/png, image/jpeg"
        hidden
        id="imagePicker"
      />
      <div className="w-screen h-screen flex"> 

        <Photo img={require('../../../assets/images/5.png')} />

        <div className="flex-1 bg-white py-20 px-10 flex flex-col">
          <motion.div
            initial={{ y: -50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 1.2, type: "tween" }}
            className="w-full flex justify-end"
          >
            <img src={require("../../../assets/icons/logo.png")} width="100" alt="" />
          </motion.div>

          <div className="w-full flex justify-center flex-col items-center flex-1">
            <motion.div
              initial={{ y: -50, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 2, type: "tween" }}
              className="flex flex-col"
            >
              <p className="text-2xl font-bold">Welcome, {localStorage.getItem('firstname')+''}</p>
              <p className="text-sm mt-2 text-gray-600 text-justify">
                We'd need you to complete your profile in <br /> order to finish
                your account setup
              </p> 
              <div className="w-full flex mt-5 flex flex-col">
                {image !== null ? (
                  <div className="w-full flex justify-center mt">
                    <motion.div
                      onClick={() =>
                        document.getElementById("imagePicker")?.click()
                      }
                      whileHover={{ scale: 1.09 }}
                      className="w-20 h-20 rounded-full bg-gray-200 flex justify-center items-center hover:shadow-md cursor-pointer"
                    >
                      <img src = {image as any} className='w-full h-full flex flex-1 rounded-full object-cover' alt=""/>
                    </motion.div>
                  </div>
                ) : (
                  <motion.div
                    onClick={() =>
                      document.getElementById("imagePicker")?.click()
                    }
                    className="w-full flex justify-center mt-2"
                  >
                    <motion.div
                      whileHover={{ backgroundColor: "#ff6884", scale: 1.09 }}
                      className="w-20 h-20 rounded-full bg-gray-200 flex justify-center items-center hover:shadow-md cursor-pointer"
                    >
                      <img
                        src={require("../../../assets/icons/camera.png")}
                        width="30"
                        alt=""
                      />
                    </motion.div>
                  </motion.div>
                )}

                <div className="w-full flex flex-col mb-5 mt-8">
                  <div>
                    <img
                      src={require("../../../assets/icons/at-sign.svg")}
                      width="20"
                      className="z-10 mt-3 ml-2 absolute"
                      alt=""
                    />
                    <input
                      name="username"
                      value={formik.values.username}
                      onChange={formik.handleChange}
                      onFocus={() =>
                        formik.setFieldTouched("username", true, true)
                      }
                      placeholder="username"
                      type="text"
                      max={6}
                      className="w-300px py-3 rounded bg-gray-300 absolute pl-8 text-xs placeholder-gray-700"
                    />
                  </div>
                  <div className="h-5 max-h-5 mt-8">
                    {formik.touched.username && formik.errors.username && (
                      <motion.p
                        initial={{ y: -100, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        className="text-ventlyRed text-xs mt-3"
                      >
                        {formik.errors.username}
                      </motion.p>
                    )}
                  </div>
                </div>

                <div className="w-full flex rounded  flex-col mb-5">
                  <div>
                    <img
                      src={require("../../../assets/icons/person2.svg")}
                      width="20"
                      className="z-10 mt-3 ml-2 absolute"
                      alt=""
                    />
                    <div className="w-2 h-2 bg-transparent absolute z-10 mt-2 ml-64 pl-3 pointer-events-none" >
                      <MdArrowDropDown size='25px' />
                    </div>
                    <select
                      onChange={(e) => HandleisBusiness(e)}  
                      className="w-300px py-3 rounded bg-gray-300 absolute mr-4 pl-8 text-xs appearance-none"
                    >
                      <option value={1}>Personal Account</option>
                      <option value={2}>Business Account</option>
                    </select>
                  </div>
                </div>

                <div className="w-full">
                  <div>
                    {accountType === 1 ? (
                      <p className=" text-xs mt-8">
                        This account type works best if you want to use Vently{" "}
                        <br /> for managing events you attend and create.
                      </p>
                    ) : (
                      <p className=" text-xs mt-8">
                        This account type works best if you want to use Vently{" "}
                        <br /> for offering services and creating and managing
                        events.
                      </p>
                    )}
                  </div>

                  {accountType === 2 ? (
                    <motion.div
                      initial={{ scale: 1.7, opacity: 0 }}
                      animate={{ scale: 1, opacity: 1 }}
                      className="w-full flex flex-col mb-5 mt-2"
                    >
                      <div>
                        <img
                          src={require("../../../assets/icons/person2.svg")}
                          width="20"
                          className="z-10 mt-3 ml-2 absolute"
                          alt=""
                        /> 
                      <div className="w-2 h-2 bg-transparent absolute z-10 mt-2 ml-64 pl-3 pointer-events-none" >
                        <MdArrowDropDown size='25px' />
                      </div>
                        <select  
                          onChange={(e)=> HandleBusinesType(e)} 
                          defaultValue="Business type"
                          className="w-300px py-3 rounded bg-gray-300 absolute pl-8 text-xs appearance-none"
                        >
                          <option>Categories</option>
                          {businessTypes.map((item, index) => (
                            <option key={index} value={item}>
                              {item}
                            </option>
                          ))}
                        </select>
                      </div>
                    </motion.div>
                  ) : (
                    <div></div>
                  )}

                  <motion.button
                    onClick={handleSubmit}
                    whileHover={{ scale: 1.05 }}
                    className="mt-8 w-300px bg-ventlyRed flex justify-center rounded text-white text-xs p-3 absolute"
                  >
                    {
                      submitting ? 
                      <div className="progressloader ease-linear rounded-full flex border-2 border-ventlyRed h-4 w-4"/> :
                      'continue'
                    }
                  </motion.button>
                </div>
              </div> 
            </motion.div>
          </div>
        </div>
      </div>
    </>
  );
}
