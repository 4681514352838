import React from 'react'

import { FiMoreVertical} from 'react-icons/fi'
import { FaTicketAlt } from 'react-icons/fa';
import { BiTime} from 'react-icons/bi'
import { ImLocation2 } from 'react-icons/im' 
import { motion } from 'framer-motion'; 

export default function GetTicket(props: any) { 

    return (
        <div className="w-full h-auto rounded-md shadow bg-primary text-primary flex flex-col mb-8"> 
            <div className="w-full flex justify-between px-5 pt-5 ">
                <div className="flex">
                    <div style={{height:'55px', width:'55px'}} className=' rounded-full bg-gray-200 flex mr-2 justify-center items-center' >
                        <img src = {props.userpic} className='w-full h-full flex flex-1 rounded-full object-cover' alt=""/>
                    </div>
                    {/* <img src={require('../../../../assets/images/avatar.png')} width="50" alt=""/> */}
                    <div className="ml-2">
                        <p className="font-rubik-medium text-sm mt-1">Sandra Michaels</p>
                        <p className="text-xs font-rubik-regular">@sandra124</p>
                    </div>
                </div>

                <div className="flex">
                    <div className="flex pt-2 mr-6">
                        <p className="font-rubik-medium text-sm ml-2 mt-1">Sponsored</p>
                    </div>
                    <div className="pt-2">
                        <FiMoreVertical color="white" size={25} />
                    </div>
                </div>
            </div>

            <div className="w-full h-72 px-5 mt-5 mb-5">
                <img src={props.img} alt="" className="w-full h-full rounded-md object-cover"/>
                <div style={{ marginTop: -50 }} className="p-2 z-20 ml-6 w-32 just flex rounded-full opacity-75 bg-black text-white text-center text-xs">
                    <FaTicketAlt color="white" size={20} className="mr-3 transform rotate-45" />
                    <p>348.9K going</p>
                </div>
            </div>

            <div className='w-full flex flex-row'>
                <div className='w-full'>
                    <div className="px-5">
                        <p className="font-rubik-medium text-sm">New Decade 2020 VIP Pool Party</p>
                    </div>

                    <div className="flex px-5 mt-5">
                        <div className="flex text-xs border-r-1 border-gray-200 pr-5 mr-5">
                            <BiTime className='bg-primary' size={20} />
                            <p className="ml-3">Friday - </p>
                            <p>29 Oct, 1 – 7 pm</p>
                        </div>

                        <div className="flex text-xs">
                            <ImLocation2 color="#FF517B" size={20} />
                            <p className="ml-3">La Casa Royale, Bronx, NYC</p>
                        </div>
                    </div>
                </div>
                <div className='w-20 h-15 rounded-md bg-ventlyRed mr-5 flex flex-col items-center justify-center'>
                    <p className="text-sm font-rubik-medium text-white">Nov</p>
                    <p className="text-md font-rubik-medium font-bold mt-px text-white ">16</p>
                </div>
            </div>            

            <div className="px-5 mt-5">
                <p className="text-xs font-rubik-regular">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus similique officiis facilis ipsa fugiat maxime quas! Nisi nihil nemo autem eos, beatae magnam officiis consequuntur soluta omnis recusandae necessitatibus harum?
                </p>
            </div>
            <motion.button 
                whileHover={{ scale: 1.02 }}
                className=" bg-ventlyRed text-white rounded w-auto flex items-center justify-center h-10 text-xs font-rubik-medium mx-5 mt-3 mb-8">
                    GET TICKET NOW
            </motion.button> 
        </div>
    )
}
