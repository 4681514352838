import React from 'react'

import { motion } from 'framer-motion';
import { MdArrowDropDown, MdClose } from 'react-icons/md'
import Layout from '../../../../Darkmode/Layout';
import { NavLink } from 'react-router-dom';
// import Skeleton from 'react-loading-skeleton'; 
import { IUser, UserContext } from '../../../../Contexts/UserContext';

export default function PostEvent() {

    const [showModal, setShowModal] = React.useState(false); 
    // const [loader, SetLoader] = React.useState(true)
    const userContext: IUser = React.useContext(UserContext);

    // React.useEffect(() => {
    //     SetLoader(true); 
    //     const timing = setTimeout(() => {
    //         SetLoader(false);
    //     }, 4000);
    //     return () => clearTimeout(timing); 
    //   }, []);

    return (
        <Layout>
            <div className = 'w-full text-primary'> 
                <div className='rounded-t-md p-5 bg-primary text-primary' >
                    <h1 className="font-rubik-medium text-sm">Post A New Event</h1>
                </div>
                <div className='w-full flex flex-1 flex-row rounded-b-md items-center mt-px p-4 mb-5 bg-primary'>
                    <div style={{height:'50px', width:'50px'}} className=' rounded-full bg-gray-200 flex mr-2 justify-center items-center' >
                        <img src = {userContext.userData.displaypic} className='w-full h-full flex flex-1 rounded-full object-cover' alt=""/>
                    </div>
                    <motion.div className='w-full flex-1 m-2 fill-current text-secondary hover:fill-white flex flex-row items-center hover:bg-ventlyRed hover:text-white bg-secondary p-3 rounded-md cursor-pointer'
                        whileHover={{ scale: 1.05 }} onClick={() => setShowModal(true)}>
                        <div className='p-3 bg-eventborder rounded-md'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="22.726" viewBox="0 0 22.726 22.726">
                                <path className="a" d="M347.332,5.326A1.332,1.332,0,0,1,346,3.995V1.332a1.332,1.332,0,1,1,2.663,0V3.995A1.332,1.332,0,0,1,347.332,5.326Z" transform="translate(-330.642)"/>
                                <g transform="translate(0 2.663)">
                                    <path className="a" d="M20.284,60h-.932v1.332a2.663,2.663,0,0,1-5.326,0V60H8.7v1.332a2.663,2.663,0,0,1-5.326,0V60H2.441A2.444,2.444,0,0,0,0,62.441v2.974H22.726V62.441A2.444,2.444,0,0,0,20.284,60Z" transform="translate(0 -60)"/>
                                </g>
                                <path className="a " d="M107.332,5.326A1.332,1.332,0,0,1,106,3.995V1.332a1.332,1.332,0,0,1,2.663,0V3.995A1.332,1.332,0,0,1,107.332,5.326Z" transform="translate(-101.295)"/>
                                <path className="a" d="M0,212v10.875a2.444,2.444,0,0,0,2.441,2.441H20.284a2.444,2.444,0,0,0,2.441-2.441V212Zm12.062,8.655a2.663,2.663,0,1,1-5.326,0,.666.666,0,0,1,1.332,0,1.357,1.357,0,0,0,.013.187A1.332,1.332,0,1,0,9.4,219.324a.666.666,0,0,1,0-1.332,1.332,1.332,0,1,0-1.307-1.59.666.666,0,1,1-1.307-.257,2.663,2.663,0,1,1,4.372,2.513A2.657,2.657,0,0,1,12.062,220.655Zm3.927,2a.666.666,0,1,1-1.332,0v-7.324H13.616a.666.666,0,1,1,0-1.332h1.708a.666.666,0,0,1,.666.666Z" transform="translate(0 -202.59)"/>
                            </svg>
                        </div>
                        <p className='ml-5 mr-8 font-rubik-medium text-sm' >New Physical Event</p>
                    </motion.div>
                    <motion.div className='w-full flex-1 fill-current text-secondary hover:fill-white m-2 flex flex-row items-center hover:bg-ventlyRed hover:text-white bg-secondary p-3 rounded-md cursor-pointer'
                        whileHover={{ scale: 1.05 }}>
                        <div className='p-3 bg-eventborder rounded-md'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" viewBox="0 0 28.235 30.141">
                                <g transform="translate(0 -0.001)">
                                    <path className="a" d="M210.609,88.426a1.912,1.912,0,1,1,0-2.7A1.917,1.917,0,0,1,210.609,88.426Zm0,0" transform="translate(-195.136 -80.15)"/>
                                    <path className="a" d="M292.714,53.99a.91.91,0,0,1-.643-1.554,3.8,3.8,0,0,0,0-5.383.91.91,0,0,1,1.285-1.288,5.621,5.621,0,0,1,0,7.959.906.906,0,0,1-.643.266Zm0,0" transform="translate(-274.626 -42.82)"/>
                                    <path className="a" d="M327.667,13.85a.91.91,0,0,1-.643-1.554,7.583,7.583,0,0,0,0-10.74A.91.91,0,0,1,328.309.269a9.4,9.4,0,0,1,0,13.316.907.907,0,0,1-.642.266Zm0,0" transform="translate(-307.521 -0.002)"/>
                                    <path className="a" d="M135.7,53.989a.907.907,0,0,1-.643-.266,5.621,5.621,0,0,1,0-7.959.91.91,0,0,1,1.285,1.288,3.8,3.8,0,0,0,0,5.383.91.91,0,0,1-.643,1.554Zm0,0" transform="translate(-125.549 -42.819)"/>
                                    <path className="a" d="M82.972,13.848a.906.906,0,0,1-.643-.266,9.4,9.4,0,0,1,0-13.316.91.91,0,0,1,1.285,1.288,7.583,7.583,0,0,0,0,10.74.91.91,0,0,1-.643,1.554Zm0,0" transform="translate(-74.883 0)"/>
                                    <path className="a" d="M26.107,261.938H2.128A2.128,2.128,0,0,0,0,264.066v10.465a2.128,2.128,0,0,0,2.128,2.128H26.107a2.128,2.128,0,0,0,2.128-2.128V264.066a2.128,2.128,0,0,0-2.128-2.128ZM7,273.929H3.646a.91.91,0,0,1-.909-.91v-7.441a.91.91,0,1,1,1.819,0v6.531H7a.91.91,0,1,1,0,1.819Zm3.728-.91a.91.91,0,0,1-1.819,0v-7.441a.91.91,0,1,1,1.819,0Zm7.654-7.178-2.246,7.441v0a.922.922,0,0,1-1.74,0v0l-2.246-7.441a.91.91,0,1,1,1.742-.526l1.376,4.556,1.376-4.556a.91.91,0,1,1,1.742.526Zm5.6,2.079a.91.91,0,1,1,0,1.819H21.395v2.369h3.072a.91.91,0,0,1,0,1.819H20.485a.91.91,0,0,1-.91-.91v-7.441a.91.91,0,0,1,.91-.91h4.1a.91.91,0,1,1,0,1.819H21.394v1.433Zm0,0" transform="translate(0 -246.517)"/>
                                </g>
                            </svg>
                        </div>
                        <p className='ml-5 mr-10 font-rubik-medium text-sm' >New Online Event</p>
                    </motion.div>
                </div>  
                
                {showModal ? (
                    <>
                        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                            <div className="relative rounded-md w-850px my-6 mx-auto max-w-3xl"> 
                                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-secondary outline-none focus:outline-none"> 
                                    <div className="flex items-center bg-primary justify-center p-5 mb-px rounded-t">
                                        <div className='w-full flex flex-1 justify-center rounded-t-lg ' >
                                            <h3 className=" font-rubik-bold text-md text-primary ">
                                                Post New Event
                                            </h3>
                                        </div>
                                        <div className=" cursor-pointer h-8 w-8 bg-secondary flex justify-center items-center rounded-full text-primary text-2xl" onClick={() => setShowModal(false)} >
                                            <MdClose size='18' />
                                        </div>
                                    </div> 
                                    <div className='w-full bg-primary rounded-b-lg justify-center flex'>
                                        <div className=" w-300px flex flex-col justify-center m-8">
                                            <img src={require('../../../../assets/icons/calendar.svg')} className=' w-20 ml-auto mr-auto mt-5 mb-4' alt=''  />
                                            <p className="my-4 text-secondary font-rubik-regular text-center text-sm leading-relaxed">
                                                Let's help you get your event ready, what kind of event do you want to create 
                                            </p>
                                            <div className="relative inline-flex ">
                                                <div className="w-2 h-2 absolute right-0 mt-3 mr-8 pointer-events-none" > 
                                                    <MdArrowDropDown size='25px' /> 
                                                </div>
                                                <select className=" cursor-pointer w-full rounded-md text-secondary font-rubik-regular text-center text-sm pl-5 pr-10 py-3 bg-secondary hover:border-gray-400 focus:outline-none appearance-none"> 
                                                    <option>Single Day Event</option>
                                                    <option>Multi Day Event</option> 
                                                </select>                                    
                                            </div>
                                            <NavLink to='./newevent' >
                                                <button className=' w-full bg-ventlyRed rounded-md my-4 p-3 font-rubik-medium text-sm text-white'>Continue</button>
                                            </NavLink>
                                        </div>
                                    </div> 
                                </div>
                            </div>
                        </div>
                        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                    </>
                ) : null}                        
            </div>
        </Layout>
    )
}