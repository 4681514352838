import React from "react";
import { useHistory } from "react-router-dom";
import { motion } from "framer-motion";
// import "./styles/InterestStyle.css";
import * as axios from "axios";
import { BASEURL } from "../../../globals/URL";
import { UpdateInterests } from '../../Actions/UpdateInterests'; 
import Photo from '../../Universal-Components/Background_Photo'
import { IReturnObject } from "../../../globals/ReturnOject";
import {IoMdCheckmark} from "react-icons/io";

export default function Interests() {
  
  const history = useHistory();
  const [loading, setLoading] = React.useState(true);
  const [interests, setInterest] = React.useState([]);   
  const [selectedInterets, setSelectedInterests] = React.useState(
    [] as Array<string>
  );  

  React.useMemo(() => {
    (async function () {
      const result = await axios.default.get(`${BASEURL.URL}/user/interests`); 
      const interests = result.data.data;
      setInterest(interests);
      setLoading(false);
    })();
  }, []);

  // methods
  const handleSelect = (item: string) => {
    if (selectedInterets.includes(item)) {
        const clone = [...selectedInterets];
        const index = clone.indexOf(item); 
        clone.splice(index, 1); 
        setSelectedInterests(clone); 
    }else{ 
      const clone = [...selectedInterets, item];
      setSelectedInterests(clone); 
    }
  };

  const handleSubmit = async () => {
      if (selectedInterets.length < 3) {
          alert("Please select up to 3 interests");
      }else {
          setLoading(true);
          const obj = {
              interests : selectedInterets,
          }
          const result : IReturnObject  = await UpdateInterests(obj) as IReturnObject;  
          if (result.statusCode === 200) {
              setLoading(false);
              history.push('/feed'); 
          }else if (result.statusCode === 400) {
              alert(result.errorMessage);
          }else {
            alert(result.errorMessage);
          }
      }
  }

  const InterestList = (source: any) => { 

    return source.map((item: any, index: any) => {
        return(
          <div
            key={index}
            onClick={() => handleSelect(item)}
            className={ 
            !selectedInterets.includes(item)
              ? "text-sm p-2 flex flex-row justify-center items-center text-gray-600 font-heebo-regular rounded bg-gray-200 mb-2 cursor-pointer mr-3"
              : "text-sm p-2 flex flex-row justify-center items-center text-white font-heebo-regular rounded bg-ventlyRed mb-2 cursor-pointer mr-3"
            }> 
          <p> {item} </p>
          <div className=
          {
            !selectedInterets.includes(item)
            ? "hidden"
            : 'w-4 h-4 flex justify-center items-center rounded-full border-2 ml-2 border-white'
          } 
          >
              <IoMdCheckmark className='text-white w-4'   />
          </div>
          </div>
        )
      }) 
  }

  return (
    <>
      <div className="w-screen h-screen flex"> 
      
        <Photo img={require('../../../assets/images/6.png')} /> 

        <div className="flex-1 bg-white py-20 px-10 flex flex-col">
          <motion.div
            initial={{ y: -50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 1.2, type: "tween" }}
            className="w-full flex justify-end"
          >
            <img src={require("../../../assets/icons/logo.png")} width="100" alt="" />
          </motion.div> 
            <div className="w-full flex justify-center items-center flex-1">
              <motion.div
                initial={{ y: -50, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: 2, type: "tween" }}
                className="flex flex-col"
              >
                <p className="text-2xl font-poppins-medium">
                  Select Your Interests
                </p>
                <p className="text-sm mt-2 text-gray-600 text-justify font-heebo-regular">
                  Please select at least 3 interest categories, we'll use this to{" "}
                  <br /> set up your event feed
                </p>

                <div className="w-full flex mt-10 flex flex-col ">
                  <div className="w-auto h-300px flex overflow-auto">
                    {loading ? (
                      <div className="w-full h-full flex">
                        <div className="flex-1 flex justify-center items-center">
                          <div className="flex flex-col">
                            <div className="w-full flex justify-center">
                              <div className="loader ease-linear rounded-full border-2 border-t-2 border-l-2 border-r-2 border-gray-200 h-8 w-8"/>
                            </div>
                            <p className=" font-heebo-medium text-xs mt-2">
                              Loading interests...
                            </p>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="px-4 w-400px flex flex-wrap">
                        {InterestList(interests)}
                      </div>
                    )}
                  </div>

                  <div className="w-full flex justify-center">
                    <motion.button
                      whileHover={{ scale: 1.05 }}
                      onClick={handleSubmit}
                      type='submit'
                      className="bg-ventlyRed mt-8 w-400px rounded font-heebo-bold text-white flex justify-center text-xs p-3 absolute"
                    >
                      {
                          loading ? <div className="loader ease-linear rounded-full border-2 border-t-2 border-l-2 border-r-2 border-gray-200 h-4 w-4"/> :
                          'Save & Continue'
                      }
                    </motion.button>
                  </div>
                </div>
              </motion.div>
            </div> 
        </div>
      </div>
    </>
  );
}
