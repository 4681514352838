import React from 'react'
import { Route, useHistory } from 'react-router-dom' 

export default function SignupRoutes({ isAuthenticated, ...props }: any) {

    const userId = localStorage.getItem('id'); 
    const history = useHistory();

    React.useEffect(() => {

        const token = localStorage.getItem('token') as string;
        const signuptoken = sessionStorage.getItem('token') as string; 

        if(!token){
            if(!signuptoken){ 
                if(!userId){ 
                    history.push('/')
                } 
            }else {
                history.push('/feed')
            }
        } else {
            history.push('/feed')
        }
    },)

    return  <Route {...props} /> 

//     const userContext: IUser = React.useContext(UserContext);  
//  const userId = localStorage.getItem('id')
    // const [userId, setUserId] = React.useState(userContext.userData.id); 


    // React.useEffect(() => {
    //     setUserId(userContext.userData.id) 
    // }, []) 
    
    // return !isAuthenticated ?
    //     (   
    //         !userId ?( 
    //             <Redirect to= '/' />
    //         ):( 
    //             <Route {...props} />  
    //         ) 
    //     ):(
    //         <Redirect to= '/feed' />
    //     )
}
