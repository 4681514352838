import React from 'react'
import {
  GoogleMap,
  useLoadScript,
  // InfoWindow,
  Marker
} from "@react-google-maps/api";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";
import "@reach/combobox/styles.css";
import {MdClose, MdPlace, MdMyLocation} from 'react-icons/md'
// import { formatRelative } from "date-fns";
import '../../../../../styles/loader.css';

const containerStyle = {
  width: '50vw',
  height: '57vh',
};
 
let center: any = {
  lat: 9.0820,
  lng: 8.6753,
}

let defaultzoom = 8;

let location_address: any = 'Location'

export default function MyGoogleMap(props: any) {

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: 'AIzaSyB_p6rCYMrZmZf2WxlRIVqGPOO4qO48fYw',
    libraries: ["places"],
  });

  const options = {
    disableDefaultUI: true,
    zoomControl: true,
  };

  // const [map, setMap]: any = React.useState(null)

  // let select: any

  // const [markers, setMarkers] = React.useState([
  //   {
  //     lat: '',
  //     lng: ''
  //   }
  // ]as any);
  const [markers, setMarkers] = React.useState( {
      lat: 0,
      lng: 0
    } );
  // const [selected, setSelected] = React.useState(select);
  const [position, setPositions] = React.useState(center);
  const [place, setPlace] = React.useState(location_address);
  const [zoom, setZoom] = React.useState(defaultzoom);
  const [showModal, setShowModal] = React.useState(false);
  
  function elements(){ 
    setPlace(location_address);
    setZoom(defaultzoom)
    setPositions(center)
    props.marker(markers);
    props.location(place)
  }

  React.useEffect(()=> {
    elements()
  })
  const [container, SetContainer] =React.useState('w-full px-8')

  const modal =()=> {
    setShowModal(true);
    SetContainer('fixed top-0 right-0 left-0 bottom-0 w-full h-full flex items-center bg-black bg-opacity-75 justify-center')
  } 

  const close=()=>{
    setShowModal(false);
    SetContainer('w-full px-8')
  }
 
  const onUnmount = React.useCallback(function callback(map) {
    // setMap(null)
  }, [])

  const mapRef:any = React.useRef();
  const onMapLoad = React.useCallback((map) => {
    mapRef.current = map;
  }, []);

  const panTo = React.useCallback(({ lat, lng }) => {
    mapRef.current.panTo({ lat, lng });
    mapRef.current.setZoom(14);
  }, []);

  const onMapClick = React.useCallback((e: any) => {
    // setMarkers((current: any):any => [
    //   ...current,
    //   {
    //     lat: e.latLng.lat(),
    //     lng: e.latLng.lng(),
    //     time: new Date(),
    //   },
    // ]);
    setMarkers({
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    })
  }, []);

  if (loadError) return <div className=' w-full flex justify-center' ><p>Error Loading Map</p></div>
  if (!isLoaded) return <div className=' w-full flex justify-center'>
        <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-10 w-10"></div>    
      </div>
 
  return (
    <div className={container}>
          {!showModal? (
            <div>
                <p className="text-xs font-rubik-medium  mb-2">Event Location </p>
                <div onClick={()=>modal()} className=" rounded-md bg-secondary text-primary items-center text-sm pl-5 font-Rubik-regular text-darkmodefont flex flex-row justify-center cursor-pointer w-full h-12 "  ><MdPlace size='20' className='text-ventlyRed m-2' />{place}</div>
            </div>
            ) : null}
          {showModal? (
            <div className=' w-map_width relative  flex flex-col mt-8 justify-center items-center'>
              <div className='w-full h-12 flex flex-row items-center justify-center bg-white text-black' style={{borderTopLeftRadius: '20px', borderTopRightRadius:'20px'}}>
                <div className='w-full flex flex-1 justify-center'>
                  <p className=' text-md font-rubik-medium' >Select Location on map</p>
                </div>
                <div onClick={() => close()} className=' cursor-pointer bg-black bg-opacity-25 rounded-full  mr-4 '>
                  <MdClose size='20' className=' text-black m-1' />
                </div>  
              </div>
              <GoogleMap
                id="map"
                mapContainerStyle={containerStyle}
                center={position}
                zoom={zoom}
                options={options}
                onLoad={onMapLoad}
                onUnmount={onUnmount}
                onClick={onMapClick}>

                <Search panTo={panTo} />  
                <div>
                  <Locate panTo={panTo}/>  
                </div>      
                <Marker 
                  position={{ lat: markers.lat, lng: markers.lng }}
                  /> 
              </GoogleMap>
              <div className=' relative w-full flex flex-row items-center px-8 py-3 justify-center bg-white text-black' style={{borderBottomLeftRadius: '20px', borderBottomRightRadius:'20px'}} >
                <div className='w-full flex flex-1' > 
                </div>                   
                <button onClick={() => close()} className='bg-ventlyRed text-white font-rubik-medium text-xs py-2 px-4 ' style={{borderRadius: '5px'}} >Confirm</button>
              </div>
          </div>      
          ) : null}
        </div>
  )
  
}

function Locate({ panTo }: any) {
    return (
        <div className="absolute w-10 h-10 bg-white flex justify-center items-center rounded-md cursor-pointer"
          style={{marginLeft:'46.4vw', marginTop:'22.5vh'}}
          onClick={() => {
            navigator.geolocation.getCurrentPosition(
              (position) => {
                panTo({
                  lat: position.coords.latitude,
                  lng: position.coords.longitude,
                });
              },
              () => null
            );
          }}
        >
          <MdMyLocation size='23' className=' text-gray-800' />
        </div>
    );
  }

  
  
  const Search = (props: any) => {

    const [map, SetMap] = React.useState({
      lat: 9.0820,
      lng: 8.6753,
    })

    const [zoom, SetZoom] = React.useState(8)

    const {
      ready,
      value,
      suggestions: { status, data },
      setValue,
      clearSuggestions,
    } = usePlacesAutocomplete({
      requestOptions: {
        location: new google.maps.LatLng(center),
        radius: 100 * 1000,
      },
      
    });
  
    // https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service#AutocompletionRequest
  
    const handleInput = (e: any) => {
      setValue(e.target.value);
    };
  
    const handleSelect = async (address: any) => {
      setValue(address, false);
      clearSuggestions();
      try {
        const results = await getGeocode({ address });
        const { lat, lng } = await getLatLng(results[0]);
        props.panTo({ lat, lng });
        SetMap({lat:lat, lng: lng})
        SetZoom(14)
        console.log('address '+lat, lng)
      } catch (error) {
        console.log("😱 Error: ", error);
      }
    };

    React.useEffect(()=> {
      center = map
      defaultzoom = zoom
      if(value === '' ){
        location_address = 'Location'
      }else {
        location_address = value
      }
    })

    return (
      <div className='  mt-4 w-full flex justify-center' >
        <div className=" relative rounded-md  ">
          <Combobox onSelect={handleSelect}>
            <ComboboxInput
              value={value}
              className=' w-350px rounded-lg h-10 border-px flex pl-4 text-black justify-center items-center'
              onChange={handleInput}
              disabled={!ready}
              placeholder="Search your location"
            />
            <ComboboxPopover>
              <ComboboxList>
                {status === "OK" &&
                  data.map(({ id, description }, index: any) => (
                    <ComboboxOption key={index} value={description} />
                  ))}
              </ComboboxList>
            </ComboboxPopover>
          </Combobox>
        </div>
      </div>
    );
  }