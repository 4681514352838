import React from 'react'
import { MdKeyboardArrowLeft, MdKeyboardArrowRight, MdClear } from 'react-icons/md';
import VendorList from './Event_Components/Vendors_Component/VendorList';
import axios from 'axios'; 
import { BASEURL } from '../../../../globals/URL'
import { EventContext, EventSchema } from '../../../../Contexts/EventContext';

export default function VendorsAndCreators(props: any) {
 
    const [container, setContainer] = React.useState(0);
    const [viewbutton, setViewButton] = React.useState(true);
    const [showModal, setShowModal ] = React.useState(false);
    const [sendInvite, setSendInvite] = React.useState(false);
    const [getArrayIndex, setGetArrayIndex] = React.useState(0);
    const [vendorsList, setVendorsList] = React.useState([]);
    const [vendorName, setVendorName] = React.useState('');
    const [selectedVendor, setSelectedVendor] = React.useState([]as any);
    const [slides, setSlides] = React.useState(false);
    const [progressButton, setProgressButton] = React.useState(true); 
    const eventContext: EventSchema = React.useContext(EventContext);
    const ref: any = React.useRef(null);

    const RemoveVendor = (id: any) => {

        const newList = selectedVendor.filter((item: any) => item !== id); 
        setSelectedVendor(newList)
        setContainer(container-1)  

        let value = container-1  

        if(value < 1){
            setSlides(false); 
            setProgressButton(true);
        } 
    }

    const scroll = (scrolloffset : any ) =>{
        ref.current.scrollLeft += scrolloffset
    };

    const ViewVendorDetail = (index: any) => {
        setGetArrayIndex(index);
        setShowModal(true);
        setVendorsList(selectedVendor) 
        setVendorName(selectedVendor[index].BusinessType)
        setSendInvite(true)
        setViewButton(false) 
    }

    const ClickHandler =(vendor: any)=> {
        setShowModal(true)
        setVendorName(vendor)
        axios.get(`${BASEURL.URL}/user/vendors/${vendor}`)
            .then(res => { 
                console.log(res.data.data); 
                setVendorsList(res.data.data) 
            })
            .catch(error => { 
                console.error('There was an error!', error);
                setVendorsList([])
            })
    }  

    const SubmitHandler =()=>{
        eventContext.setEventData({...eventContext.eventData, 
            vendorsList: selectedVendor
        })
        props.click()
    }

    return (
        <div className=' bg-primary w-full flex flex-col text-primary rounded-lg mt-5 pt-10 pb-20' >
            {slides ? (
                <div className='w-full px-40 flex flex-col ' >
                    <div className=' w-full h-12 bg-secondary rounded-md px-6 flex flex-row items-center '>
                            <h1 className="font-rubik-medium text-sm " >Invited Vendors</h1>
                        <div className='w-full flex flex-1' />
                        <div className=' h-6 bg-carousel cursor-pointer rounded-sm mr-2' onClick={() => scroll(-350)}>
                            <MdKeyboardArrowLeft size='25px' />
                        </div >
                        <div className=' h-6 bg-carousel cursor-pointer rounded-sm mr-2' onClick={() => scroll(350)}>
                            <MdKeyboardArrowRight size='25px' />
                        </div>
                    </div>
                    <div className='w-full flex flex-row overflow-x-auto my-4 py-1 pb-4 scroll_event' ref={ref} >
                        {[...Array(container)].map(
                        (_value: undefined, index: number) => (
                            <div key={index} className='w-auto m-2' >
                                <div className=' relative w-350px h-24 flex flex-wrap  bg-secondary p-px rounded-md' >
                                    <div className=' w-full  px-3 flex flex-row items-center bg-primary rounded-md ' >
                                        <div style={{height:'75px', width:'75px'}} className='  rounded-full bg-gray-200 flex justify-center items-center' >
                                            <img src = {selectedVendor[index].displaypic} className='w-full h-full flex flex-1 rounded-full object-cover' alt=""/>
                                        </div>
                                        {/* <img src={vendor[index].displaypic} alt='' className=' w-20 h-20 rounded-full'  /> */}
                                        <div className='w-auto flex flex-col mx-2' >
                                            <p className='font-rubik-medium text-sm mb-1' >{selectedVendor[index].firstname+' '+selectedVendor[index].lastname}</p>
                                            <p className='font-rubik-light text-xs mb-1' >{selectedVendor[index].rating}</p>
                                            <p className='font-rubik-light text-xs' >{selectedVendor[index].prize}</p>
                                        </div>                                        
                                        <button onClick={()=> ViewVendorDetail(index)} className='py-2 w-auto px-6 flex ml-6  bg-ventlyRed text-xs text-white font-heebo-medium' style={{borderRadius:'3px'}} >View</button>
                                        
                                    </div>
                                    <div className='w-full absolute flex flex-row ' >
                                        <div className='w-full flex flex-1' />
                                        <div onClick={() => RemoveVendor(selectedVendor[index])} className=' flex justify-center items-center bg-secondary w-6 h-6 cursor-pointer mt-1 mr-1 rounded-full' >
                                            <MdClear size='18' className='bg-secondary' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            ) : null}
        
            <p className='font-rubik-medium text-md ml-auto mr-auto' >Invite Vendors / Creatives</p>
            <div className=' w-full py-6 px-40 flex flex-col' >
                <p className=' font-rubik-medium text-sm mb-6' >Select A Category</p>
                <div className='w-full flex flex-row' >
                        <>
                            <div className='w-full flex flex-col mr-8' >
                                <div onClick={()=> ClickHandler('Photographer')} className=' w-full flex flex-row bg-secondary my-1 py-3 pl-6 rounded-md cursor-pointer' >
                                    <img src={require('../../../../assets/icons/photo_icon.svg')} alt='' sizes='20' />
                                    <p className='font-rubik-regular text-sm ml-2 ml-4 mt-1' >Photographers</p>
                                </div>
                                <div onClick={()=> ClickHandler('Disc jockey')} className=' w-full flex flex-row bg-secondary my-1 py-3 pl-6 rounded-md cursor-pointer' >
                                    <img src={require('../../../../assets/icons/dj-mixer.svg')} alt='' sizes='20' />
                                    <p className='font-rubik-regular text-sm ml-2 ml-4 mt-1' >Disc Jockeys</p>
                                </div>
                                <div onClick={()=> ClickHandler('Host')} className=' w-full flex flex-row bg-secondary my-1 py-3 pl-6 rounded-md cursor-pointer' >
                                    <img src={require('../../../../assets/icons/mc_icon.svg')} alt='' sizes='20' className='ml-1' />
                                    <p className='font-rubik-regular text-sm ml-2 ml-6 mt-1' >Hosts</p>
                                </div>
                                <div onClick={()=> ClickHandler('Comedian')} className=' w-full flex flex-row bg-secondary my-1 py-3 pl-4 rounded-md cursor-pointer' >
                                    <img src={require('../../../../assets/icons/clown.svg')} alt='' sizes='20' />
                                    <p className=' font-rubik-regular text-sm ml-2 ml-4 mt-1' >Comedians</p>
                                </div>
                                <div onClick={()=> ClickHandler('Cinematographer')} className=' w-full flex flex-row bg-secondary my-1 py-3 pl-6 rounded-md cursor-pointer' >
                                    <img src={require('../../../../assets/icons/cinema_icon.svg')} alt='' sizes='20' />
                                    <p className='font-rubik-regular text-sm ml-2 ml-4 mt-1' >Cinematographers</p>
                                </div>
                            </div>

                            <div className='w-full flex flex-col ml-8'>
                                <div onClick={()=> ClickHandler('Dancer')} className=' w-full flex flex-row bg-secondary my-1 py-3 pl-6 rounded-md cursor-pointer' >
                                    <img src={require('../../../../assets/icons/dancer_icon.svg')} alt='' sizes='20' />
                                    <p className='font-rubik-regular text-sm ml-2 ml-5 mt-1' >Dancers</p>
                                </div>
                                <div onClick={()=> ClickHandler('Caterer')} className=' w-full flex flex-row bg-secondary my-1 py-3 pl-6 rounded-md cursor-pointer' >
                                    <img src={require('../../../../assets/icons/food_icon.svg')} alt='' sizes='20' />
                                    <p className='font-rubik-regular text-sm ml-2 ml-4 mt-1' >Caterers</p>
                                </div>
                                <div onClick={()=> ClickHandler('Artist')} className=' w-full flex flex-row bg-secondary my-1 py-3 pl-6 rounded-md cursor-pointer' >
                                    <img src={require('../../../../assets/icons/singer_icon.svg')} alt='' sizes='20' />
                                    <p className='font-rubik-regular text-sm ml-2 ml-5 mt-1' >Artistes</p>
                                </div>
                                <div onClick={()=> ClickHandler('Usher')} className=' w-full flex flex-row bg-secondary my-1 py-3 pl-6 rounded-md cursor-pointer' >
                                    <img src={require('../../../../assets/icons/usher_icon.svg')} alt='' sizes='20' />
                                    <p className='font-rubik-regular text-sm ml-2 ml-6 mt-1' >Ushers</p>
                                </div>
                            </div>
                        </>                    
                </div>
                <div className=' w-full flex flex-row'>
                    <div className='w-full flex flex-1' />
                    {progressButton ? (
                        <button onClick={() => SubmitHandler()} className='py-2 w-32 h-10 border-1 mt-12 border-ventlyRed text-xs text-ventlyRed font-rubik-medium' style={{borderRadius:'3px'}} >Skip</button>
                    ): (
                        <button onClick={() => SubmitHandler()} className='py-2 w-32 h-10 mt-12 bg-ventlyRed text-xs text-white font-rubik-medium' style={{borderRadius:'3px'}} >Continue</button>
                    )}
                </div>
            </div>
            {showModal ? (
                    <>
                        <div className="justify-center items-center w-auto flex flex-wrap overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                            <VendorList array={vendorsList} vendorType={vendorName} close={setShowModal} view={viewbutton} setview={setViewButton} newIndex={setGetArrayIndex} index={getArrayIndex} invite={sendInvite} showInvite={setSendInvite} progressButton={setProgressButton} container={container} newContainer={setContainer} vendors={selectedVendor} newVendors={setSelectedVendor} slides={setSlides} />
                        </div>
                        <div className="opacity-75 fixed inset-0 z-40 bg-black"></div>
                    </>
            ) : null}  
        </div>
    )
}
