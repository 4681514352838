import React from 'react'
import {
    GoogleMap,
    useLoadScript,
    Marker
  } from "@react-google-maps/api";
import { MdClose } from 'react-icons/md';
import { EventContext, EventSchema } from '../../../../../Contexts/EventContext';

  const containerStyle = {
        width: '50vw',
        height: '57vh',
    };
   

export default function Map(props: any) {
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: 'AIzaSyB_p6rCYMrZmZf2WxlRIVqGPOO4qO48fYw',
        libraries: ["places"],
      });

  const options = {
    disableDefaultUI: true,
    zoomControl: true,
  };
    // const [map, setMap] = React.useState(null)
    const [showModal, setShowModal] = React.useState(false); 
    const eventContext: EventSchema = React.useContext(EventContext);
    const [marker, setMarker] = React.useState({} as any)

    function elements(){ 
        setMarker(eventContext.marker)
    }

    React.useEffect(()=> {
        elements();
    })

    const [container, SetContainer] =React.useState('w-full')

    const modal =()=> {
        setShowModal(true);
        SetContainer('fixed top-0 right-0 left-0 bottom-0 w-full h-full flex items-center bg-black bg-opacity-75 justify-center')
    }

    const close=()=>{
        setShowModal(false);
        SetContainer('w-full')
    }

    // const onUnmount = React.useCallback(function callback(map) {
    //    setMap(null)
    // }, [])
    
    const mapRef:any = React.useRef();

    const onMapLoad = React.useCallback((map) => {
        mapRef.current = map;
    }, []);

    if (loadError) return <div className=' w-full flex justify-center' ><p>Error Loading Map</p></div>
    if (!isLoaded) return <div className=' w-full flex justify-center'>
        <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-10 w-10"></div>    
    </div>

    return (
        <div className={container} >
            {!showModal ? (
                <div className='w-full h-auto relative flex flex-1 rounded-md mb-4' >
                    <div className='absolute w-full h-full bg-black bg-opacity-50 flex rounded-md justify-center items-center'>
                        <div onClick={()=>modal()} className=' cursor-pointer py-2 px-8 border-1 border-white ' style={{borderRadius:'4px'}}>
                        <p className='font-rubik-medium text-sm text-white'>Get Directions On Map</p>
                        </div>
                    </div>
                    <img alt='' src={require('../../../../../assets/images/map.png')} className='w-full rounded-md' />
                </div>
            ):(
                <div className=' w-map_width relative  flex flex-col mt-8 justify-center items-center'>
                    <div className='w-full h-12 flex flex-row items-center justify-center bg-white text-black' style={{borderTopLeftRadius: '20px', borderTopRightRadius:'20px'}}>
                        <div className='w-full flex flex-1 justify-center'>
                            <p className=' text-md font-rubik-medium' >Select Location on map</p>
                        </div>
                        <div onClick={() => close()} className=' cursor-pointer bg-black bg-opacity-25 rounded-full  mr-4 '>
                            <MdClose size='20' className=' text-black m-1' />
                        </div>  
                    </div>
                    <GoogleMap
                        id="map"
                        mapContainerStyle={containerStyle}
                        center={marker}
                        zoom={14}
                        options={options}
                        onLoad={onMapLoad}
                        // onUnmount={onUnmount} 
                        > 
                            <Marker position={{ lat: marker.lat, lng: marker.lng }}/>
                    </GoogleMap>
                    <div className=' relative h-10 w-full flex flex-row items-center px-8 py-3 justify-center bg-white text-black' style={{borderBottomLeftRadius: '20px', borderBottomRightRadius:'20px'}} >
                        <div className='w-full flex flex-1' >
                        </div>                   
                        {/* <button onClick={() => close()} className='bg-ventlyRed text-white font-rubik-medium text-xs py-2 px-4 ' style={{borderRadius: '5px'}} >Ok</button> */}
                    </div>
                </div>
            )} 

        </div>
    )
}
