import React from 'react'

import { MdMoreHoriz } from 'react-icons/md'
import Skeleton from 'react-loading-skeleton' 

export default function TrendingChip() {

    const [loader, SetLoader] = React.useState(true) 
     
    React.useEffect(() => {
        SetLoader(true); 
        const timing = setTimeout(() => {
            SetLoader(false);
        }, 2000);
        return () => clearTimeout(timing); 
      }, []);

    return (
        <div className='w-full text-primary' >
            {!loader ? (
                <>
                    <div className='bg-primary text-primary rounded-t-md pt-5 pr-4 pl-4'>
                        <div className='w-full flex flex-row' >
                            <h1 className='font-rubik-medium flex flex-1 text-md' >Trending Events</h1>
                            <MdMoreHoriz size='25' className='cursor-pointer m-0 bg-primary' />
                        </div>
                        <p className='font-rubik-regular text-sm mt-2 pb-5'  >Lagos, Nigeria</p>
                    </div>
                    <div className='bg-primary mt-px pt-5 pl-4'>
                        <h1 className='font-rubik-medium  text-sm'>New Decade 2020 VIP Pool Party</h1>
                        <p className='font-rubik-regular text-xs mt-2 pb-5'>450,890 attending</p>
                    </div>
                    <div className='bg-primary mt-px pt-5 pl-4'>
                        <h1 className='font-rubik-medium text-sm' >The Xperience Lagos 6th Edition</h1>
                        <p className='font-rubik-regular text-xs mt-2 pb-5'>450,890 attending</p>
                    </div>
                    <div className='bg-primary mt-px pt-5 pl-4'>
                        <h1 className='font-rubik-medium text-sm' >Felabration 2020</h1>
                        <p className='font-rubik-regular text-xs mt-2 pb-5' >450,890 attending</p>
                    </div>
                    <div className='w-full bg-primary rounded-b-md flex mt-px  justify-center item-center'>
                        <button className='w-full bg-secondary m-5 font-rubik-regular text-sm p-3 rounded-md ' >Show All</button>
                    </div>
                    <div className='bg-primary rounded-t-md mt-4 pt-5 pl-4'>
                        <h1 className='font-rubik-medium text-md pb-3'>Coming Soon</h1>
                    </div>
                    <div className='bg-primary rounded-b-md mb-8 mt-px pt-5 pl-4'>
                        <h1 className='font-rubik-medium text-sm'>New Decade 2020 VIP Pool Party</h1>
                        <p className='font-rubik-regular text-xs mt-2 pb-5'>450,890 attending</p>
                    </div>
                </>
            ):(
                <>
                    <div className='bg-primary text-primary rounded-t-md py-5 pr-4 pl-4'>
                        <div className='w-full flex flex-row' >
                            <Skeleton style={{width:'100px', height:'1rem'}}/>
                            <div className='w-full flex flex-1' />
                            <Skeleton style={{width:'25px', height:'0.5rem'}}/>
                        </div>
                        <Skeleton style={{width:'95px', height:'0.875rem', marginTop:'0.5rem'}}/>
                    </div>
                    <div className='bg-primary flex flex-col mt-px pt-5 pl-4 pb-5'>
                        <Skeleton style={{width:'220px', height:'0.875rem'}}/>
                        <Skeleton style={{width:'150px', height:'0.75rem'}}/>
                    </div>
                    <div className='bg-primary flex flex-col mt-px pt-5 pl-4 pb-5'>
                        <Skeleton style={{width:'220px', height:'0.875rem'}}/>
                        <Skeleton style={{width:'150px', height:'0.75rem'}}/>
                    </div>
                    <div className='bg-primary flex flex-col mt-px pt-5 pb-5 pl-4'>
                        <Skeleton style={{width:'150px', height:'0.875rem'}}/>
                        <Skeleton style={{width:'120px', height:'0.75rem'}}/>
                    </div>
                    <div className='w-full p-5 bg-primary rounded-b-md flex mt-px  justify-center item-center'>
                        <Skeleton style={{width:'250px', height:'2rem', display:'flex', borderRadius:'0.375rem'}}/>
                    </div>
                    <div className='bg-primary rounded-t-md mt-4 pt-5 pl-4 pb-3'>
                        <Skeleton style={{width:'100px', height:'0.875rem'}}/>
                    </div>
                    <div className='bg-primary rounded-b-md mb-8 mt-px pt-5 pb-5 flex flex-col pl-4'>
                        <Skeleton style={{width:'220px', height:'0.875rem'}}/>
                        <Skeleton style={{width:'150px', height:'0.75rem'}}/>
                    </div>
                </>
            )}
            
        </div>
    )
}
