import React from 'react'

// import { Switch } from '@chakra-ui/core'
import { MdSearch } from "react-icons/md";
import { IUser, UserContext } from '../../Contexts/UserContext';

export default function Navbar() {
 
    const userContext: IUser = React.useContext(UserContext);

    return (
        <div className="w-full bg-primary text-primary px-16">
            <div className=" flex py-4"> 
                <div className='w-300px flex items-center' >
                <svg xmlns="http://www.w3.org/2000/svg" width="26.974" height="21" viewBox="0 0 26.974 21">
                    <g transform="translate(-32.5 -56.8)">
                        <g transform="translate(32.5 56.8)">
                            <path className="a fill-current text-primary" d="M54.479,56.806,46.664,77.8H42.027a2.715,2.715,0,0,1-2.47-1.6l-.129-.351L32.5,56.806h7.082l2.833,9.743a48.613,48.613,0,0,1,1.2,5.105h.129c.345-1.712.69-3.307,1.158-5.105l1.2-4.329H43.85l.788-3.11A2.7,2.7,0,0,1,47.311,56.8c.1,0,.191.006.289.012l.006-.012h6.873Z" transform="translate(-32.5 -56.8)"/>
                        </g><circle className="b fill-current text-ventlyRed" cx="3.024" cy="3.024" r="3.024" transform="translate(53.426 71.66)"/>
                    </g>
                </svg>
                    <p className="font-abeat ml-1 text-sm mt-1">Vently</p>
                </div>    
                <div className="w-full flex justify-center ">
                    <SearchBar />
                </div>
                <div className="w-300px flex justify-end mr-10 ">
                    <div className="pt-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="23.709" height="28.449" viewBox="0 0 23.709 28.449">
                            <g transform="translate(0)">
                                <g transform="translate(0 4.149)">
                                    <g transform="translate(0)">
                                        <path className="a fill-current text-secondary" d="M66.281,94.514l-2.037-3.393a10.084,10.084,0,0,1-1.436-5.185V82.965a8.3,8.3,0,1,0-16.6,0v2.972a10.084,10.084,0,0,1-1.436,5.185l-2.037,3.393a.592.592,0,0,0,.509.9H65.773a.592.592,0,0,0,.509-.9ZM44.3,94.226l1.5-2.5a11.259,11.259,0,0,0,1.6-5.794V82.965a7.112,7.112,0,1,1,14.225,0v2.972a11.269,11.269,0,0,0,1.6,5.794l1.5,2.5Z" transform="translate(-42.657 -74.667)"/>
                                    </g>
                                </g>
                                <g transform="translate(9.484)">
                                    <g transform="translate(0)">
                                        <path className="a fill-current text-secondary" d="M215.7,0a2.373,2.373,0,0,0-2.371,2.371V4.742a.593.593,0,0,0,1.185,0V2.371a1.185,1.185,0,1,1,2.371,0V4.742a.593.593,0,0,0,1.185,0V2.371A2.373,2.373,0,0,0,215.7,0Z" transform="translate(-213.333)"/>
                                    </g>
                                </g>
                                <g transform="translate(8.891 23.708)">
                                    <path className="a fill-current text-secondary" d="M208.195,426.972a.593.593,0,0,0-1.024.6,1.778,1.778,0,1,1-3.08,0,.593.593,0,1,0-1.024-.6,2.965,2.965,0,1,0,5.128,0Z" transform="translate(-202.666 -426.68)"/>
                                </g>
                            </g>
                        </svg> 
                    </div>
                        <div style={{height:'40px', width:'40px'}} className=' rounded-full bg-gray-200 flex ml-8 justify-center items-center' >
                            <img src = {userContext.userData.displaypic} className='w-full h-full flex flex-1 rounded-full object-cover' alt=""/>
                        </div> 
                </div>
            </div>
        </div>
    )
}

function SearchBar(){
    return(
    <div className=' relative w-550px flex ml-5'>
        <input type="text" className=" rounded-md bg-secondary items-center text-sm font-heebo-medium text-primary w-full p-4 " placeholder='Search' />
        <div  className='flex items-center flex-row ml-480px absolute '>
            <button className='w-auto cursor-pointer p-4 rounded-md ml-4' >
                <MdSearch size='20' className=' bg-secondary' />
            </button>
        </div>        
    </div> 
     )
}
   
