// import Axios from 'axios';
import React from 'react'

import { FiPlusCircle} from 'react-icons/fi'
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
// import Skeleton from 'react-loading-skeleton'; 
// import DisplayPic from './FeedSkeleton'
// import { BASEURL } from '../../../../globals/URL';

export default function DiscoverProfile(props: any) { 

    const ref: any = React.useRef(null);  
    const scroll = (scrolloffset : any ) =>{
        ref.current.scrollLeft += scrolloffset 
    };        

    // function shuffleArray(array: any) {
    //     let i = array.length - 1;
    //     for (; i > 0; i--) {
    //       const j = Math.floor(Math.random() * (i + 1));
    //       const temp = array[i];
    //       array[i] = array[j];
    //       array[j] = temp;
    //     }
    //     return array;
    // } 

    // React.useEffect(() => {
        // SetLoader(true); 
        //     React.useEffect(() => {
            // Axios.get(`${BASEURL.URL}/user/${userId}` )
            // .then((response)=> {  
            //     setUserData(response.data.data)
            // })  
        //   }, []);    
    //   }, []);

    // function displayPic(userId: any){
    // //     React.useEffect(() => {
    //     // Axios.get(`${BASEURL.URL}/user/${userId}` )
    //     // .then((response)=> {  
    //     //     setUserData(response.data.data)
    //     // })  
    // //   }, []);  

    //     return(
    //         <div style={{height:'40px', width:'40px'}} className=' rounded-full bg-gray-200 flex mr-2 justify-center items-center' >
    //             <img src = {userData.displaypic} className='w-full h-full flex flex-1 rounded-full object-cover' alt=""/>
    //         </div>
    //     )
    // }

    return (
        <div className='w-full p-4 pt-5 bg-primary text-primary mb-8 rounded-md '>  
            <div className='w-full'>
                <div className='flex flex-row pb-4'>
                    <div className='w-full flex flex-col'>
                        <h1 className="font-rubik-medium text-md " >Events You May Like</h1>
                        <p className="font-rubik-light text-sm ">Based on those you follow</p> 
                    </div>
                    <div className=' h-6 bg-carousel cursor-pointer rounded-sm mr-2' onClick={() => scroll(-400)}>
                        <MdKeyboardArrowLeft size='25px' />
                    </div >
                    <div className=' h-6 bg-carousel cursor-pointer rounded-sm mr-2' onClick={() => scroll(400)}>
                        <MdKeyboardArrowRight size='25px' />
                    </div>
                </div>
                <div className='w-full flex flex-row overflow-x-auto scroll_event ' ref={ref} > 
                    {props.events.map((item: any, index: any) => {
                        return(  
                            <div key={index} className='w-full' >
                                <div key={index} className='w-300px h-400px rounded-md mr-3 text-primary' style={{backgroundImage:`url(${item.banner_image})`,backgroundRepeat:'no-repeat', backgroundSize:'cover', backgroundPosition: 'center'}}>
                                    <div className='w-full h-full p-5 flex flex-col bg-black bg-opacity-25 rounded-md' >
                                        <div className='w-full h-full flex flex-row' >
                                            <p className='font-rubik-regular text-white flex flex-1 text-xs mt-1'>19h ago</p> 
                                            <div className='w-full flex flex-1' />
                                            {/* {displayPic(item.user_id)} */}
                                            {/* <div style={{height:'40px', width:'40px'}} className=' rounded-full bg-gray-200 flex mr-2 justify-center items-center' >
                                                <img src = {userData.displaypic} className='w-full h-full flex flex-1 rounded-full object-cover' alt=""/>
                                            </div> */}
                                        </div>
                                        <div className='w-full flex flex-row' >
                                            <div className='w-full flex flex-col' >
                                                <p className='font-rubik-medium text-white flex flex-1 text-sm'>{item.title}</p>
                                                <p className='font-rubik-regular text-white flex flex-1 text-xs'>{item.description.substring(0, 31)}</p>
                                            </div>
                                            <FiPlusCircle color="white" size={25} />
                                        </div>
                                    </div>
                                </div>
                            </div> 
                        )
                    })}     
                </div>
            </div> 
        </div>
    )
}
