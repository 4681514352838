import React from 'react' 

import Ticket from './Event_Components/EventTicket'

export default function TicketAndPricing(props: any) { 

    const [ display, SetDisplay] = React.useState(true)  

    const NextPage = () => {
        props.click()
    } 

    return (
        <div className='w-full bg-primary flex flex-col justify-center rounded-lg mt-5' >
            {display? (
                <div className='w-full pt-20 pb-24 flex flex-col justify-center items-center bg-primary pt-12 px-5 rounded-md'>
                    <img src={require('../../../../assets/icons/tickets.svg')} alt='' className=' w-20 mb-6' />
                    <p className='text-sm font-rubik-bold' >Add Tickets For Your Event</p>
                    <p className='text-xs font-rubik-regular text-center py-6' >An event is never complete without a ticket, if your event is a free <br/> event please skip this section we'll generate an automatic ticket type<br/> for your attendees</p>
                    <div className='flex flex-row mb-4' >
                        <button onClick={() => props.click()} className='py-2 w-32 h-10 border-1 mx-2 border-ventlyRed text-xs text-ventlyRed font-rubik-medium' style={{borderRadius:'3px'}} >Skip</button>
                        <button onClick={()=> SetDisplay(false)} className='py-2 w-32 mx-2 bg-ventlyRed text-xs text-white font-rubik-medium' style={{borderRadius:'3px'}} >Create My Tickets</button>
                    </div>
                </div> 
            ):(
                <div className=' w-full h-full flex flex-col justify-center rounded-lg' >
                    <Ticket click={NextPage}/>
                </div>
            )}          
        </div>
    )
}