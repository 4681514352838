import React from 'react';

export interface EventSchema {
    type: string,
    setType: Function,
    eventData: any,
    setEventData: Function,
    banner: any,
    setBanner: Function,
    featureImage: any,
    setFeaturedImage: Function
    bannerfile: any, 
    setBannerFile: Function
    marker: any,
    setMarker: Function
    vsecure: any, 
    setVsecure: Function,
    videourl: any
    setVideoUrl: Function
}

export const EventContext = React.createContext({} as EventSchema);

const EventContextWrapper = (props: any) => {
    const [type, setType] = React.useState('');
    const [banner, setBanner] = React.useState('');
    const [marker, setMarker] = React.useState({} as any);
    const [bannerfile, setBannerFile] = React.useState('');
    const [featureImage, setFeaturedImage] = React.useState([]as any);
    const [videourl, setVideoUrl] = React.useState('');
    const [eventData, setEventData] = React.useState({
        user_id: localStorage.getItem('id')+'',
        title: '',
        description: '',
        location: '',
        latitude: '',
        longitude: '',
        start_date: '',
        end_date: '', 
        category: '',
        privacy: false,
        isLiveEvent: true,
        isMature: false,
        draft: false, 
        vendorsList: []as any,
        ticketsTypesList: [{
            name: 'free',
            amount: 0,
            quantity: 0,
            description: 'free', 
            soldout: false
        }]
    })
    const [vsecure, setVsecure] = React.useState([]as any);
    return (
        <EventContext.Provider value={{ type, setType, videourl, setVideoUrl, vsecure, setVsecure, marker, setMarker, bannerfile, setBannerFile, eventData, setEventData, featureImage, setFeaturedImage, banner, setBanner }}>
            {
                props.children
            }
        </EventContext.Provider>
    )
}

export default EventContextWrapper;