import React from 'react'
 
import { FiPlusCircle, FiMoreVertical} from 'react-icons/fi'
import { FaTicketAlt } from 'react-icons/fa';
import { BiTime } from 'react-icons/bi'
import { ImLocation2 } from 'react-icons/im'
import { FiHeart } from 'react-icons/fi'
import { MdEventNote, MdReceipt} from 'react-icons/md'  
import Axios from 'axios';
import { BASEURL } from '../../../../globals/URL'; 

export default function FeedChip(props: any) { 
    const [userData, setUserData] = React.useState({} as any)  

    React.useEffect(() => { 
        Axios.get(`${BASEURL.URL}/user/${props.userId}` )
        .then((response)=> {  
            setUserData(response.data.data)
        })     
      }, []);

    return (
        <div className="w-full h-auto rounded-md shadow bg-primary text-primary flex flex-col mb-8"> 
            <div className="w-full flex justify-between px-5 pt-5 ">
                <div className="flex items-center">
                    <div style={{height:'55px', width:'55px'}} className=' rounded-full bg-gray-200 flex mr-2 justify-center items-center' >
                        <img src = {userData.displaypic} className='w-full h-full flex flex-1 rounded-full object-cover' alt=""/>
                    </div>
                    <div className="ml-2">
                        <p className="font-rubik-medium text-sm mt-1">{userData.firstname+' '+userData.lastname}</p>
                        <p className="text-xs font-rubik-regular">@{userData.username}</p>
                    </div>
                </div>

                <div className="flex">
                    <div className="flex pt-2 mr-6">
                        <FiPlusCircle className='bg-primary'size={25} />
                        <p className="text-xs ml-2 mt-1">Follow</p>
                    </div>
                    <div className="pt-2">
                        <FiMoreVertical className='bg-primary' size={25} />
                    </div>
                </div>
            </div>

            <div className="w-full h-72 px-5 mt-5 mb-5">
                <img src={props.img} alt="" className="w-full h-full rounded-md object-cover"/>
                <div style={{ marginTop: -50 }} className="p-2 z-20 ml-6 w-32 just flex rounded-full opacity-75 bg-black text-white items-center text-center text-xs">
                    <FaTicketAlt color="white" size={20} className="mr-3 transform rotate-45 " />
                    <p>348.9K going</p>
                </div>
            </div>

            <div className='w-full flex flex-row'>
                <div className='w-full pt-4'>
                    <div className="px-5">
                        <p className="font-rubik-medium text-lg ">{props.title}</p>
                    </div>

                    <div className="flex px-5 mt-5">
                        <div className="flex items-center text-xs border-r-1 border-gray-200 pr-5 mr-5">
                            <BiTime className='bg-primary' size={20} />
                            <p className="ml-1">{props.date}</p>
                            {/* <p className="ml-3">Tuesday - </p>
                            <p>17 Nov, 1 – 7 pm</p> */}
                        </div>

                        <div className="flex items-center text-xs ">
                            <ImLocation2 color="#FF517B" size={20} />
                            <p className="ml-1">{props.location}</p>
                        </div>
                    </div>
                </div>
                <div className='w-20 h-16 rounded-md bg-ventlyRed mr-5 flex flex-col items-center justify-center'>
                    <p className="text-sm text-white font-rubik-medium">{props.month}</p>
                    <p className="text-md text-white font-rubik-medium font-bold mt-px ">{props.day}</p>
                </div>
            </div>            

            <div className="px-5 mt-5">
                <p className="text-xs font-rubik-regular">
                    {props.description}
                </p>
            </div>

            <div className="w-full h-10 bg-secondary flex justify-between pt-px mt-5">
                <div className="flex bg-primary justify-center items-center flex-1 text-xs mr-px rounded-bl-md">
                    <div className="flex">
                        <FiHeart className='bg-primary' size={18} />
                        <p className="ml-2">Like</p>
                    </div>
                </div>

                <div className="flex bg-primary justify-center items-center mr-px text-xs flex-1 ">
                    <div className="flex">
                        <MdEventNote className='bg-primary'  size={18} />
                        <p className="ml-2">Event Details</p>
                    </div>
                </div>

                <div className=" bg-primary flex justify-center items-center text-xs flex-1 rounded-br-md">
                    <div className="flex">
                        <MdReceipt className='bg-primary' size={18} />
                        <p className="ml-2">Get Tickets</p>
                    </div>
                </div>
            </div> 
        </div>
            
    )
}