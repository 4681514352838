import React from 'react'
import { EventContext, EventSchema } from '../../../../../Contexts/EventContext';

const EventPhoto = () => {

    const [image, SetImage] = React.useState('');  
    const eventContext: EventSchema = React.useContext(EventContext); 

    const handleImageChange = (e: any ) => {

        const selected = e.target.files[0]; 
        const TYPES = ["image/png", "image/jpg", "image/jpeg" ];        
        if (selected && TYPES.includes(selected.type)) {
            SetImage(selected)
            const reader: any = new FileReader();
            reader.onloadend= () => {  
                eventContext.setBanner(reader.result)
            }
            reader.readAsDataURL(selected)
        } else {
            console.log('Error')
        }  

        eventContext.setBannerFile(selected)
    } 

    const renderPhotos = (source: any) => {
			return <div className='w-full h-auto relative ' >
                        <div className=' w-full flex flex-row absolute bg-transparent' >
                            <div className=' w-full flex flex-1' />
                            <div className='w-auto py-2 flex items-center rounded-sm mt-5 mr-5 flex-row bg-ventlyPhoto'>
                                <label>
                                    <input style={{display:'none'}} type="file" accept="image/*" id="input" onChange={handleImageChange} />
                                    <img src={require('../../../../../assets/images/Camera.svg')} width='20' className='cursor-pointer ml-3' alt="" />
                                </label>
                                    <p  className='text-xs mx-3 text-white font-rubik-regular'>Change Photo</p>
                            </div>
                        </div>
                        <img className='w-full h-auto rounded-md object-cover' src={eventContext.banner} alt=""/>
                   </div>
	};

    return(
        
        <div className='w-full fill-current text-secondary h-auto rounded-md bg-secondary flex flex-col justify-center items-center'>
            {!image && (
                <div className='w-full h-300px rounded-md bg-secondary flex flex-col justify-center items-center'>
                    <label>
                        <input style={{display:'none'}} type="file" accept="image/*" id="input" onChange={handleImageChange} />
                        <svg className='cursor-pointer' xmlns="http://www.w3.org/2000/svg" width="45" viewBox="0 0 49.2 38.249">
                            <path className="a" d="M45.614,39.315H32.591L29.961,34.9a1.426,1.426,0,0,0-1.225-.7H20.464a1.426,1.426,0,0,0-1.225.7l-2.63,4.418H12.336v-.974a1.426,1.426,0,0,0-1.426-1.426H5.633a1.426,1.426,0,0,0-1.426,1.426v.974H3.586A3.586,3.586,0,0,0,0,42.9V68.863a3.586,3.586,0,0,0,3.586,3.586H45.614A3.586,3.586,0,0,0,49.2,68.863V42.9A3.586,3.586,0,0,0,45.614,39.315ZM24.6,66.089A10.207,10.207,0,1,1,34.807,55.882,10.207,10.207,0,0,1,24.6,66.089ZM43.377,46.707H38.908a1.426,1.426,0,0,1,0-2.852h4.468a1.426,1.426,0,1,1,0,2.852Z" transform="translate(0 -34.2)"/>
                            <circle className="a" cx="7.131" cy="7.131" r="7.131" transform="translate(17.469 14.551)"/>
                        </svg>
                    </label>
                    <p className='text-xs text-primary mt-5 font-rubik-regular' >TAP TO ADD A COVER PHOTO</p>
                </div>
            )}
            {image && (
                <div className='w-full h-auto rounded-md '>
                    {renderPhotos(eventContext.banner)}
                </div>
            )}
        </div>
    )
}

export default EventPhoto