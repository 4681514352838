import React from 'react'
import { useHistory } from 'react-router-dom';
import { motion } from 'framer-motion'; 
import * as yup from 'yup'
import { useFormik } from 'formik';
import { IReturnObject } from "../../../globals/ReturnOject";
import {Login as LoginAction} from '../../Actions/LoginAction';
import Photo from '../../Universal-Components/Background_Photo';  

const loginSchema = yup.object({
    email: yup.string().email('This email is not valid').required('Your email is required'),
    password: yup.string().required('Your password is required').min(8, 'A minimium of 8 characters')
})

export default function Login() {
    // local state
    const [showpassword, setShowpass] = React.useState(false);
    const [submitting, setSubmitting] = React.useState(false);  
    const history = useHistory(); 
 
    // formik
    const formik = useFormik({
        initialValues: {email: '', password: ''},
        validationSchema: loginSchema,
        onSubmit: () => {},
    });

    const handleShowpassword = () => {
        setShowpass(prev => !prev);
    }

    const handleSubmit = async() => {  
        setSubmitting(true)
        if (!formik.dirty) {
            alert("Please enter your details to login");
            setSubmitting(false)
        } else if (!formik.isValid) {
            alert("Please fillin the form properly"); 
            setSubmitting(false)
        }else {
            // handle signup here
            const returnValue: IReturnObject = (await LoginAction(
                formik.values
            )) as IReturnObject;
            // await LoginAction(formik.values);
            if(returnValue.statusCode === 200) {
                console.log('Login Sucessfull'); 

                const t1 = setTimeout(() => {
                    history.push('/feed');
                    clearTimeout(t1);
                  }, 3000);
      
            }else if (returnValue.statusCode === 400) {
                // create appropriate alert later
                alert('Incorrect User Or Password');
                setSubmitting(false)
            } else if (returnValue.statusCode === 500) {
                alert(returnValue.errorMessage);
                setSubmitting(false)
            }
        }
    }

    return (
        <> 
            <div className="w-screen h-screen flex ">
 
            <div className='sm:hidden md:hidden lg:flex'>
                <Photo img={require('../../../assets/images/1.png')} />
            </div>
                
                <div className="relative flex-1 h-full overflow-y-scroll flex-col flex bg-white py-20 px-10">

                    <motion.div 
                    initial={{ y: -50, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ delay: 1.2, type: 'tween' }}
                    className="w-full flex justify-end">
                        <img src={require('../../../assets/icons/logo.png')} width="100" alt=""/>
                    </motion.div>

                    <div className="w-full flex-1 flex justify-center items-center">
                        <motion.div 
                        initial={{ y: -50, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        transition={{ delay: 2, type: 'tween' }}
                        className="flex flex-col">
                            <p className="text-2xl font-poppins-medium">Welcome Back</p>
                            <p className="text-sm mt-2 text-gray-600 font-heebo-regular">Great to see you back , log In To Continue</p>

                            <div className="w-full flex justify-evenly mt-5">

                                <motion.button 
                                whileHover={{ scale: 1.05 }}
                                className="bg-blue-600 rounded w-32 text-white text-xs h-10 flex justify-center items-center flex-1">
                                    <img src={require('../../../assets/icons/facebook.svg')} width="10" alt=""/>
                                    <p className="ml-1 font-heebo-regular">Facebook</p>
                                </motion.button>

                                <motion.button 
                                whileHover={{ scale: 1.05 }}
                                className="border-1 border-gray-600 rounded w-32 text-black text-xs h-10 ml-5 flex justify-center items-center flex-1">
                                    <img src={require('../../../assets/icons/google.svg')} width="20" alt=""/>
                                    <p className="ml-1 font-heebo-regular">Google</p>
                                </motion.button>

                            </div>

                            <form>

                                <div className="w-full flex mt-10 flex flex-col"> 
                                    <div className='w-full'>
                                        <div className='w-full  flex'>
                                            <div className="mt-3">
                                                <img src={require("../../../assets/icons/email.svg")} width="23" className="absolute ml-4" alt=""/>
                                            </div>
                                            <input
                                                name="email"
                                                onChange={formik.handleChange}
                                                onFocus={() =>
                                                    formik.setFieldTouched("email", true, true)
                                                } 
                                                type="text"
                                                placeholder="example@vent.ly"
                                                className=" lg:w-300px w-full py-3 rounded pl-12 pr-2 text-xs font-heebo-regular" style={{backgroundColor:'#ECECEC91'}} />
                                        </div>
                                        <div className="w-full h-auto pt-2">
                                            {formik.touched.email && formik.errors.email && (
                                            <motion.p
                                                initial={{ y: -100, opacity: 0 }}
                                                animate={{ y: 0, opacity: 1 }}
                                                className="text-xs text-ventlyRed"
                                            >
                                                {formik.errors.email}
                                            </motion.p>
                                            )}
                                        </div>
                                    </div>
                                    <div className='w-full' >
                                        <div className='relative w-full mt-6 flex'>
                                            <div className="mt-2">
                                                <img src={require("../../../assets/icons/lock3.svg")} width="20" className="absolute ml-4" alt=""/>
                                            </div>
                                            <input
                                                name="password"
                                                onChange={formik.handleChange}
                                                onFocus={() =>
                                                formik.setFieldTouched("password", true, true)
                                                }
                                                placeholder="Password"
                                                type={showpassword ? "text" : "password"}
                                                className=" lg:w-300px w-full py-3 rounded pl-12 text-xs pr-2 font-heebo-regular" style={{backgroundColor:'#ECECEC91'}} />
                                            <div onClick={handleShowpassword} className='flex justify-end'>
                                                {!showpassword ? (
                                                    <div className='absolute mr-1 p-2'>
                                                        <img
                                                            src={require("../../../assets/icons/open.svg")}
                                                            width="27" 
                                                            alt="" />
                                                    </div>
                                                ) : (
                                                    <div className='absolute mr-1 p-2'>
                                                        <img
                                                            src={require("../../../assets/icons/close.svg")}
                                                            width="27" 
                                                            alt="" />
                                                    </div> 
                                                )}
                                            </div>
                                        </div> 
                                        <div className="w-full h-auto pt-2">
                                            {formik.touched.password && formik.errors.password && (
                                            <motion.p
                                                initial={{ y: -100, opacity: 0 }}
                                                animate={{ y: 0, opacity: 1 }}
                                                className="text-xs text-ventlyRed"
                                                // style={{marginTop:'4.3rem'}}
                                            >
                                                {formik.errors.password}
                                            </motion.p>
                                            )}
                                        </div>
                                    </div>
                                                        
                                    <motion.p 
                                    whileHover={{ scale: 1.05 }}
                                    className=" text-ventlyRed text-right text-xs font-heebo-regular cursor-pointer">Forgot Password?</motion.p>

                                    <motion.button 
                                    disabled={submitting}
                                    whileHover={{ scale: 1.05 }}
                                    onClick={()=> handleSubmit()}
                                    type='submit'
                                    className=" bg-ventlyRed mt-6 w-300px flex justify-center rounded text-white text-xs p-3 font-heebo-regular">
                                        {
                                            submitting ? <div className="progressloader ease-linear rounded-full flex border-2 border-ventlyRed h-4 w-4"/>:
                                            'LOG IN'
                                        }
                                    </motion.button>

                                    <motion.p 
                                    whileHover={{ scale: 1.05 }}
                                    onClick={() => history.push('/signup')}
                                    className=" text-gray-700 text-center text-xs mt-5 cursor-pointer font-heebo-regular">Don't have an account? <span className="underline text-ventlyRed">Sign up</span></motion.p>
                                </div>

                            </form>

                        </motion.div>
                    </div> 
                </div>
            </div>   
        </>
    )
}