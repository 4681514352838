import React from 'react'
import './styles/event.css'

import { MdControlPoint, MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md"; 
import { IUser, UserContext } from '../../../../Contexts/UserContext';

export default function EventCreators(props: any) {

    const userContext: IUser = React.useContext(UserContext);  
    const ref: any = React.useRef(null); 

    function shuffleArray(array: any) {
        let i = array.length - 1;
        for (; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          const temp = array[i];
          array[i] = array[j];
          array[j] = temp;
        }
        return array;
    }

    const scroll = (scrolloffset : any ) =>{
        ref.current.scrollLeft += scrolloffset 
    };        
 

    return (  
        <div className='w-full p-4 pt-5 bg-primary text-primary mb-8 rounded-md '>   
            <div className='w-full'>
                <div className='flex flex-row pb-4'>
                    <div className='w-full flex flex-col'>
                        <h1 className="font-rubik-medium text-md " >Discover Creators</h1>
                        <p className="font-rubik-light text-sm ">Based on those you follow</p>
                    </div>
                    <div className=' h-6 bg-carousel cursor-pointer rounded-sm mr-2' onClick={() => scroll(-400)}>
                        <MdKeyboardArrowLeft size='25px' />
                    </div >
                    <div className=' h-6 bg-carousel cursor-pointer rounded-sm mr-2' onClick={() => scroll(400)}>
                        <MdKeyboardArrowRight size='25px' />
                    </div>
                </div>
                <div className='w-full flex flex-row overflow-x-auto scroll_event ' ref={ref} > 
                    {shuffleArray(props.vendor).map((item: any, index: any) => {
                        return( 
                            <div key={index}>
                                {userContext.userData.id !== item.id ?
                                    <div key={index} className='w-full text-primary' >
                                        <div className='w-400px mr-4 rounded-md ' >
                                            <img key={index} src={item.displaypic} className='w-full h-64 object-cover rounded-md' alt='' />
                                            <div className='flex flex-row mt-5' >
                                                <div className='w-full' >
                                                    <div className='w-full flex flex-row items-center'> 
                                                        <h1 key={index} className="font-rubik-medium text-sm mt-1">{item.firstname+' '+item.lastname}</h1> 
                                                        { item.verified === true ?
                                                            <svg className='mt-1 ml-1' xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24">
                                                                <g  data-name="Group 165">
                                                                    <g  data-name="Group 164">
                                                                    <path style={{fillRule:'evenodd'}} data-name="Path 150" d="M24,0H0V-24H24Z" transform="translate(0 24)" fill="none" />
                                                                    <path className='fill-current text-accent' style={{fillRule:'evenodd'}} data-name="Path 151" d="M21.445,11.966a1.966,1.966,0,0,1-.327-2.29,1.968,1.968,0,0,0,.054-1.732A1.941,1.941,0,0,0,19.838,6.85a1.948,1.948,0,0,1-1.5-1.749,1.956,1.956,0,0,0-.884-1.487,1.923,1.923,0,0,0-1.708-.193,1.928,1.928,0,0,1-2.2-.652,1.928,1.928,0,0,0-3.082,0,1.928,1.928,0,0,1-2.2.652,1.924,1.924,0,0,0-1.708.193A1.956,1.956,0,0,0,5.665,5.1a1.949,1.949,0,0,1-1.5,1.749A1.941,1.941,0,0,0,2.828,7.944a1.967,1.967,0,0,0,.054,1.732,1.966,1.966,0,0,1-.327,2.29,1.963,1.963,0,0,0,.439,3.076,1.959,1.959,0,0,1,.953,2.1,1.966,1.966,0,0,0,.433,1.678,1.93,1.93,0,0,0,1.586.67A1.936,1.936,0,0,1,7.9,20.746,1.944,1.944,0,0,0,9.16,21.922a1.924,1.924,0,0,0,1.693-.3,1.925,1.925,0,0,1,2.295,0,1.931,1.931,0,0,0,2.957-.875A1.937,1.937,0,0,1,18.036,19.5a1.929,1.929,0,0,0,1.585-.67,1.966,1.966,0,0,0,.433-1.678,1.959,1.959,0,0,1,.953-2.1,1.963,1.963,0,0,0,.438-3.076ZM10.477,15.018l.515.482.5-.494L17,9.616,15.968,8.5l-4.975,4.888L8.007,10.627,7,11.766Z"  />
                                                                    </g>
                                                                </g>
                                                            </svg>
                                                        :null}
                                                    </div>
                                                    <p key={index} className="text-xs font-rubik-light">@{' '+item.username}</p> 
                                                </div>
                                                <button className=" bg-ventlyRed rounded text-white text-sm p-2 w-200px font-heebo-regular flex flex-row justify-center items-center" ><MdControlPoint size='25px' className='mr-2' /> Follow </button>
                                            </div>   
                                        </div>  
                                    </div>:null
                                }
                            </div>
                            )
                    })}                              
                </div>
            </div> 
        </div>
    )
}