import React from 'react'

import { BrowserRouter as Router } from 'react-router-dom';
import { RecoilRoot} from 'recoil';
import { isMobile } from "react-device-detect";

// contexts
import UserContext from './Contexts/UserContext'; 
import EventContext from './Contexts/EventContext'; 
import Routes from './Routes/WebsiteRoute' 


export default function Authentication() {     

    React.useEffect(() => {  
        if(isMobile && !localStorage.getItem('token')){   
            document.location.reload(false);
            window.location.replace('https://m.vent.ly')
        }
    });

    return (
        <RecoilRoot>
            <UserContext>
                <EventContext>
                    <Router>
                        <Routes />
                    </Router>
                </EventContext>
            </UserContext>
        </RecoilRoot>
    )
}