import React from 'react';
import { Route, useHistory } from 'react-router-dom';
import { IUser, UserContext } from '../Contexts/UserContext';
import { FeedData as FeedAction } from "../Website-Pages/Actions/FeedAction";   

export default function ProtectedRoute({ isAuthenticated, ...props }: any) { 
     

    const history = useHistory();
    const userContext: IUser = React.useContext(UserContext); 
    const { status, data } = FeedAction();  

    React.useEffect(() => { 


        const token = localStorage.getItem('token') as string; 
        const signuptoken = sessionStorage.getItem('token') as string; 
        const details = JSON.parse(localStorage.getItem('details') as string)

        if(!token){ 
            if(!signuptoken){ 
                history.push('/')
            } else {
                userContext.setUserData(details)
            }
        } else {
            userContext.setUserData(details)
        }
    },[history])


        if(status !== "loading" && status !== undefined){
            if(data === undefined){  
                localStorage.clear();
                history.go(0);
            }
        }

    return  <Route {...props} /> 

    // return !isAuthenticated ?
    //     (
    //         <Redirect to='/' />
    //     ):(
    //         <Route {...props} />
    //     )
} 

