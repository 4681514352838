import React from 'react'
 
import NavBar from '../../Universal-Components/Navbar'
import Menu from './Components/Menu'
// import Newevent from './Components/PostEvent'
// import FeedChip from './Components/FeedChip';
import TrendingChip from './Components/TrendingChip'
// import Creator from './Components/EventCreators'
// import OtherFeedsContents from './Components/OtherFeedsContents'
// import OtherEvent from './Components/OtherEvent'
import './Components/styles/event.css'
// import Ticket from './Components/GetTicket'
// import MorePhoto from './Components/EventPhoto'
// import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import Layout from '../../../Darkmode/Layout'   

import { FeedData as FeedAction } from "../../Actions/FeedAction";   
// import Skeleton from 'react-loading-skeleton';
import FeedSkeleton from './Components/FeedSkeleton';
import FeedData from './FeedContent';

export default function Feeds() {  
    
    const { status, data, isFetching } = FeedAction();   

    return (
        <Layout >
            <div className="flex flex-col w-full h-screen bg-secondary overflow-hidden">
                <div className="w-full h-auto shadow z-20 flex flex-col ">
                    <NavBar />
                </div>
                <div className="flex-1 flex bg-secondary overflow-auto"> 
                    <div className="w-full flex flex-row ">
                        <div className='overflow-y-auto overflow-x-hidden w-550px m-0 '>
                            <Menu />
                        </div>
                        <div className="w-full flex flex-col h-full overflow-x-hidden overflow-y-auto mx-4 pt-4 pb-9" > 
                            {status !== "loading" && status !== undefined && isFetching === false ? 
                                (
                                    <>
                                        <FeedData data={data.data} />
                                    </>
                                ): (
                                    <>
                                        <FeedSkeleton />
                                    </>
                                )
                            }
                            {/* 
                            <div className='w-full p-4 pt-5 bg-primary text-primary mb-8 rounded-md '>
                                <div className='w-full'>
                                    <div className='flex flex-row pb-4'>
                                        <div className='w-full flex flex-col'>
                                            <h1 className="font-rubik-medium text-md " >Discover Creators</h1>
                                            <p className="font-rubik-light text-sm ">Based on those you follow</p>
                                        </div>
                                        <div className=' h-6 bg-carousel cursor-pointer rounded-sm mr-2' onClick={() => scroll(-400)}>
                                            <MdKeyboardArrowLeft size='25px' />
                                        </div >
                                        <div className=' h-6 bg-carousel cursor-pointer rounded-sm mr-2' onClick={() => scroll(400)}>
                                            <MdKeyboardArrowRight size='25px' />
                                        </div>
                                    </div>
                                    <div className='w-full flex flex-row overflow-x-auto scroll_event ' ref={ref} >
                                        <Creator img={require('../../../assets/images/chad.png')} alt='' />
                                        <Creator img={require('../../../assets/images/tyler.png')} alt=''/>
                                        <Creator img={require('../../../assets/images/chad.png')} alt=''/>
                                    </div>
                                </div>
                                
                            </div>
                            <FeedChip displaypic={''} username={''} fullname={''} img={require('../../../assets/images/mac.png')} />
                            <MorePhoto userpic={''} firstimg={require('../../../assets/images/photo1.png')} secondimg={require('../../../assets/images/photo.png')} />
                            <FeedChip displaypic={''} username={''} fullname={''} img={require('../../../assets/images/mac.png')} />
                            <div className='w-full p-4 pt-5 bg-primary text-primary mb-8 rounded-md '>
                                <div className='w-full'>
                                    <div className='flex flex-row pb-4'>
                                        <div className='w-full flex flex-col'>
                                            <h1 className="font-rubik-medium text-md " >Events You May Like</h1>
                                            <p className="font-rubik-light text-sm ">Based on those you follow</p>
                                        </div>
                                        <div className=' h-6 bg-carousel cursor-pointer rounded-sm mr-2' onClick={() => scroll(-400)}>
                                            <MdKeyboardArrowLeft size='25px' />
                                        </div >
                                        <div className=' h-6 bg-carousel cursor-pointer rounded-sm mr-2' onClick={() => scroll(400)}>
                                            <MdKeyboardArrowRight size='25px' />
                                        </div>
                                    </div>
                                    <div className='w-full flex flex-row overflow-x-auto pb-5 scroll_event ' ref={ref1} >
                                        <OtherEvent userpic={''} img={require('../../../assets/images/event3.png')} alt='' />
                                        <OtherEvent userpic={''} img={require('../../../assets/images/event1.png')} alt=''/>
                                        <OtherEvent userpic={''} img={require('../../../assets/images/event2.png')} alt=''/>
                                    </div>
                                </div>
                                
                            </div>
                            <Ticket userpic={''} img={require('../../../assets/images/mac.png')} /> */}
                        </div>
                        <div className='w-550px flex flex-col h-auto overflow-y-auto pt-4 pr-4'  >
                            <TrendingChip /> 
                        </div>
                    </div>                
                </div>
            </div>
        </Layout>
    ) 
}  