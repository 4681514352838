import React from 'react'
import { MdClear } from 'react-icons/md'; 

export default function EventMedia(props: any) {
    const [ selectedFiles, setSelectedFiles ] = React.useState([]);
    const [ imageFiles, setImageFiles ] = React.useState([]  as Array<string>);  

    function elements(){
        props.media(imageFiles);  
    }

    React.useEffect(()=> { 
        elements();
    });  

    const [show, setShow] = React.useState(true)
        
	const handleImageChange = (e:any) => {  
		if (e.target.files) {
			const filesArray: any = Array.from(e.target.files).map((file) => URL.createObjectURL(file)); 
            const fileName = Array.from(e.target.files).map((file) => file);  

            setImageFiles((prevImages: any) => prevImages.concat(fileName));
			setSelectedFiles((prevImages: any) => prevImages.concat(filesArray));
			Array.from(e.target.files).map(
				(file : any) => URL.revokeObjectURL(file) // avoid memory leak
			);
        }
        setShow(prev => !prev);

    }; 

    const handleImage = (e:any) => {  

        const files = e.target.files

        for (var i = 0; i < files.length; i++) {
            const clone = [...imageFiles, files[i]];
            setImageFiles(clone); 
        }
		if (e.target.files[0]) {
			const filesArray: any = Array.from(e.target.files).map((file) => URL.createObjectURL(file)); 
            
			setSelectedFiles((prevImages: any) => prevImages.concat(filesArray));
			Array.from(e.target.files).map(
				(file : any) => URL.revokeObjectURL(file) // avoid memory leak
			);
        }
    };
    function handleRemove(id: any, file: any) {
        const newList = selectedFiles.filter((item: any) => item !== id);
        const clone = [...imageFiles];
        const index = clone.indexOf(file); 
        clone.splice(index, 1); 
        setImageFiles(clone); 
        if(Object.keys(newList).length === 0){                   
            setShow(prev => !prev);
        }
            setSelectedFiles(newList); 
      }

	const renderPhotos = (source: any) => {
		return source.map((photo: any, index: any) => { 
            return(
                <div key={index}>
                    {files(index, photo)}
                </div>
             )            
        });
    };  

    const files = (index: any, photo: any) => {
        if(index < 5){
            return (
                <div key={index} className=' bg-secondary p-px w-40 h-40 rounded-md mx-2'  >
                    <div style={{backgroundImage:'url(' + photo + ')',width:'100%', backgroundRepeat:'no-repeat', backgroundSize:'cover', height: '100%', display: 'flex', backgroundPosition: 'center', borderRadius: '5px' }} >
                        <div className='w-full flex flex-1' />
                        <div onClick={() => handleRemove(photo, imageFiles[index])} className=' flex justify-center items-center bg-secondary w-6 h-6 cursor-pointer mt-1 mr-1 rounded-full' >
                            <MdClear size='18' className='bg-secondary' />
                        </div>                    
                    </div>
                </div>                    
            )    
        }else{
            selectedFiles.pop();
            imageFiles.pop();
        }
    }

	return (
		<div className="w-full">
            
            {show && (
                    <label className="  rounded-md bg-secondary text-primary flex justify-center cursor-pointer items-center text-xs font-Rubik-regular text-darkmodefont w-full h-12 ">
                        <input   style={{display:'none' , width:'100%', height: '100%'}} type="file" id="file" multiple onChange={handleImageChange} />
                        <p >Add Photos</p>
                    </label>
            )}
            {!show && (
				<div className=" w-full grid grid-cols-5 gap-1 fill-current text-secondary flex rounded-md ">
                    {renderPhotos(selectedFiles)} 
                        <label className=" w-40 cursor-pointer h-40 mx-2 bg-secondary flex rounded-md fill-current text-secondary flex justify-center items-center">
                            <input style={{display:'none' , width: '100%', height: '100%'}} type="file" id="file" onChange={handleImage} />
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" viewBox="0 0 49.2 38.249">
                                <path className="a" d="M45.614,39.315H32.591L29.961,34.9a1.426,1.426,0,0,0-1.225-.7H20.464a1.426,1.426,0,0,0-1.225.7l-2.63,4.418H12.336v-.974a1.426,1.426,0,0,0-1.426-1.426H5.633a1.426,1.426,0,0,0-1.426,1.426v.974H3.586A3.586,3.586,0,0,0,0,42.9V68.863a3.586,3.586,0,0,0,3.586,3.586H45.614A3.586,3.586,0,0,0,49.2,68.863V42.9A3.586,3.586,0,0,0,45.614,39.315ZM24.6,66.089A10.207,10.207,0,1,1,34.807,55.882,10.207,10.207,0,0,1,24.6,66.089ZM43.377,46.707H38.908a1.426,1.426,0,0,1,0-2.852h4.468a1.426,1.426,0,1,1,0,2.852Z" transform="translate(0 -34.2)"/>
                                <circle className="a" cx="7.131" cy="7.131" r="7.131" transform="translate(17.469 14.551)"/>
                            </svg>
                        </label> 
                </div>
            )}
		</div>
	);
};