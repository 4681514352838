import React from "react";
import { useHistory } from "react-router-dom";
import { motion } from "framer-motion"; 
// import {IUser, SignupContext} from '../Contexts/SignupContext'
import {VerifyCode, ResendCode} from '../../Actions/Verifyemail';
import {useFormik} from 'formik';
import * as yup from 'yup'
import { IReturnObject } from "../../../globals/ReturnOject"; 
import Photo from '../../Universal-Components/Background_Photo'

const validationSchema = yup.object({
    code: yup.string().required('The code is required'),
});

export default function EmailVerify() {
  const [submitting, setSubmitting] = React.useState(false);
  const [resending, setResending] = React.useState(false);

  const [initialTime, setInitialTime] = React.useState(0);
  const [startTimer, setStartTimer] = React.useState(false);  
  const history = useHistory();   

  const formik = useFormik({
      initialValues: {code: ''},
      onSubmit: () => {},
      validationSchema: validationSchema
  });

  const handleSubmit = async () => {
      setSubmitting(true);
      if (!formik.dirty) {
          // appropriate alert
          setSubmitting(false);
          alert('You have to put in the code to continue')
      } else if (!formik.isValid) {
          alert('Please fill in the form correctly');
      } else if (formik.isValid) {
          // make request
          const result: IReturnObject = await VerifyCode(parseInt(formik.values.code));
          if (result.statusCode === 200) {
            setSubmitting(false);
            history.push('/setusername')
          } else if (result.statusCode === 400) {
            setSubmitting(false);
            alert('This Code Provided is Incorrect');
          }else if (result.statusCode === 500) {
            setSubmitting(false);
            alert(result.errorMessage);
          }
      }
  }

  React.useEffect(() => {
    if (initialTime > 0) {
      setTimeout(() => { 
        setInitialTime(initialTime - 1);
      }, 1000);
    }

    if (initialTime === 0 && startTimer) {
      console.log("done");
      setStartTimer(false); 
      setResending(false);
    }
  }, [initialTime, startTimer]);

  const resendCode = async () => {
        setResending(true);
      const result = await ResendCode();
      if (result.statusCode === 200) { 
          setInitialTime(59);
          setStartTimer(true); 
      } else if (result.statusCode === 400) {
        setSubmitting(false);
        alert(result.errorMessage);
      }else if (result.statusCode === 500) {
        setSubmitting(false);
        alert(result.errorMessage);
      }
  }

  return (
    <>
      <div className="w-screen h-screen flex"> 

        <Photo img={require('../../../assets/images/3.png')} /> 

        <div className="flex-1 bg-white py-20 px-10 flex flex-col">
          <motion.div
            initial={{ y: -50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 1.2, type: "tween" }}
            className="w-full flex justify-end"
          >
            <img src={require("../../../assets/icons/logo.png")} width="100" alt="" />
          </motion.div>

          <div className="w-full flex justify-center items-center flex-1">
            <motion.div
              initial={{ y: -50, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 2, type: "tween" }}
              className="flex flex-col"
            >
              <p className="text-2xl font-poppins-medium">
                Verify Your Email Address
              </p>
              <p className="text-sm mt-2 text-gray-600 text-justify font-heebo-regular">
                We just sent a code to your email address. Please <br /> enter
                it below to verify your identity
              </p> 
                <div className="w-full flex mt-10 flex flex-col">
                  <div className="w-full flex flex-col mb-5">
                    <div>
                      <img
                        src={require("../../../assets/icons/lock2.svg")}
                        width="20"
                        className="z-10 mt-3 ml-2 absolute"
                        alt=""
                      />
                      <input
                        name="code"
                        type="text"
                        placeholder="Code"
                        max={6}
                        value={formik.values.code}
                        onChange={formik.handleChange}
                        onFocus={() => formik.setFieldTouched('code', true, true)}
                        className="w-300px py-3 rounded bg-gray-300 absolute pl-8 text-xs"
                      />
                    </div>
                    <div className="h-10 pt-10">
                    {
                        formik.touched.code && formik.errors.code && 
                        (
                          <motion.div 
                          initial={{ y: -100, opacity: 0 }}
                          animate={{ y: 0, opacity: 1 }}
                          className="w-full text-ventlyRed text-xs">{formik.errors.code}</motion.div>
                        )
                    }
                    </div>
                  </div>

                  <div className="w-full pt-2">
                    <motion.button
                      whileHover={{ scale: 1.05 }}
                      onClick={handleSubmit}
                      type='submit'
                      className="bg-ventlyRed flex justify-center items-center w-300px rounded text-white text-xs p-3 absolute"
                    >
                      {
                          submitting ? <div className="progressloader ease-linear rounded-full flex border-2 border-ventlyRed h-4 w-4"/> :
                          'Continue'
                      }
                    </motion.button>
                  </div>

                  <motion.p
                    onClick={resendCode}
                    whileHover={{ scale: 1.05 }}
                    className="text-ventlyRed text-center mt-16 cursor-pointer text-xs"
                  >
                    {
                        resending ? 
                          <span>{0} : {initialTime}</span> :
                          'Resend code'
                    }
                  </motion.p>

                  <motion.p
                    whileHover={{ scale: 1.05 }}
                    className="text-center text-xs mt-6"
                  >
                    Having issues?{" "}
                    <span className="cursor-pointer text-ventlyRed">
                      Contact our support
                    </span>{" "}
                  </motion.p>
                </div> 
            </motion.div>
          </div>
        </div>
      </div>
    </>
  );
}
