import React from 'react';
import { MdArrowBack } from 'react-icons/md';
import { useHistory } from 'react-router-dom';

import NavBar from '../../Universal-Components/Navbar'
import Layout from '../../../Darkmode/Layout'

import EventInfo from './Components/Event_Information'
import TicketAndPrice from './Components/TicketAndPricing'
import VendorAndCreators from './Components/VendorsAndCreators'
import Preview from './Components/Preview'
import {Event} from '../../Actions/EventAction' 
import { IReturnObject } from '../../../globals/ReturnOject'; 
import { Bannerimage, Featuredimages } from '../../Actions/EventImageAction'; 
import {EventSchema, EventContext} from '../../../Contexts/EventContext';

export default function CreateEvent() {
  
    const history = useHistory();   
    const [submitting, setSubmitting] = React.useState(false);  
    const eventContext: EventSchema = React.useContext(EventContext);

    const [display, SetDisplay] = React.useState('firsttab')

    console.log(localStorage.getItem('id'))

    const bgactive = 'flex cursor-pointer flex-row bg-secondary items-center rounded-md my-2 py-2 p-3' 
    const indicator_active = 'w-8 h-8 flex bg-active text-accent rounded-full justify-center items-center'

    const bg_notactive = 'flex cursor-pointer flex-row hover:bg-secondary items-center rounded-md my-2 py-2 p-3'
    const indicator_notactive = 'w-8 h-8 flex bg-notactive text-white rounded-full justify-center items-center'

    const tab_information = 'w-full pl-16 pr-48 py-8 bg-primary rounded-md'

    const EventImages = async (index: any) => { 

        await Bannerimage(eventContext.bannerfile, index)
        await Featuredimages(eventContext.featureImage, index) 

        history.push('/feed') 
        setSubmitting(false); 
        alert('Event Created Successfully')
    }

    const ShowTab = (e: any) => {
        if(e.target.id === 'firsttab'){
            SetDisplay('firsttab');
        }else if(e.target.id === 'secondtab'){
            SetDisplay('secondtab');
        }else if(e.target.id === 'thirdtab'){
            SetDisplay('thirdtab');
        }else if(e.target.id === 'forthtab'){
            SetDisplay('forthtab');
        }
    }

    const SecondTab = () => {
        SetDisplay('secondtab'); 
    }

    const ThirdTab = () => {
        SetDisplay('thirdtab'); 
    }

    const ForthTab = () => {
        SetDisplay('forthtab'); 
    }   

    console.log(eventContext.videourl)

    const handleSubmit = async () => {

        setSubmitting(true);
        if (Object.values(eventContext.eventData.title)+'' === '') {
            alert("Please complete filling your event information");
            setSubmitting(false); 
        }else {    
            const result : IReturnObject  = await Event(eventContext.eventData) as IReturnObject;  
            if (result.statusCode === 200) {     
                EventImages(result.data.id) 
                // Bannerimage(eventContext.bannerfile, result.data.id) 
                // Featuredimages(eventContext.featureImage, result.data.id)  
                // history.push('/feed') 
                // alert('Event Created Successfully')
            }else if (result.statusCode === 400) {
                alert(result.errorMessage); 
                setSubmitting(false);
            }else {
                alert(result.errorMessage);
                setSubmitting(false);
            }
        }
    }

    return (
        <Layout>
            <div className="flex flex-1 flex-col w-full h-screen  bg-secondary overflow-hidden"> 
                <div className="w-full h-auto bg-white shadow z-20 flex flex-col ">
                    <NavBar />
                </div>
                <div className="w-full h-auto flex flex-col bg-secondary text-primary p-12 pt-4 overflow-auto">
                    <div className='w-full h-auto flex items-center my-6'>
                        <div className='rounded-full bg-ventlyRed p-1 cursor-pointer' onClick={()=> history.push('./feed')}>
                            <MdArrowBack  size='25' color='white'  />     
                        </div>
                        <div className=' w-auto flex flex-col ml-4 '>
                            <h1 className="text-md mb-1 font-rubik-bold">Create New Event</h1>
                            <p className="text-xs font-rubik-regular">Physical Event - Single Event</p>
                        </div>
                    </div>
                    <div className='w-full flex flex-row bg-primary text-primary rounded-lg' >
                        <div className=' w-350px bg-primary h-auto flex flex-col rounded-lg px-5 py-8' >
                            <h1 className="text-md mb-1 font-rubik-medium">Getting Started</h1>
                            <p className="text-xs font-rubik-regular">Complete the steps to create your event</p>
                            <div className='mt-5' >
                                <div onClick={ShowTab} id='firsttab' className={ display === 'firsttab' ? bgactive: bg_notactive }  >
                                    <div id='firsttab' className={ display === 'firsttab' ? indicator_active: indicator_notactive } >
                                        <p id='firsttab' className='font-rubik-medium text-sm' style={{marginRight:'3px'}} >1</p>
                                    </div>
                                    <p id='firsttab' className="font-rubik-regular text-primary  text-xs ml-5" >Enter Event Information</p>
                                </div>
                                <div onClick={ShowTab} id='secondtab' className={ display === 'secondtab'?  bgactive: bg_notactive }>
                                    <div id='secondtab' className={ display === 'secondtab'? indicator_active: indicator_notactive } >
                                        <p id='secondtab'  className='font-rubik-medium text-sm' >2</p>
                                    </div>
                                    <p id='secondtab'  className="font-rubik-regular  text-xs text-primary ml-5" >Setup Ticket & Pricing</p>
                                </div>
                                <div onClick={ShowTab} id='thirdtab' className={ display === 'thirdtab'?  bgactive: bg_notactive }>
                                    <div id='thirdtab' className={ display === 'thirdtab' ? indicator_active: indicator_notactive } >
                                        <p id='thirdtab' className='font-rubik-medium text-sm' >3</p>
                                    </div>
                                    <p id='thirdtab' className="font-rubik-regular text-primary text-xs ml-5" >Invite Vendors & Creators</p>
                                </div>
                                <div onClick={ShowTab} id='forthtab' className={ display === 'forthtab' ?  bgactive: bg_notactive }>
                                    <div id='forthtab' className={ display === 'forthtab' ? indicator_active: indicator_notactive }>
                                        <p id='forthtab' className='font-rubik-medium text-sm' >4</p>
                                    </div>
                                    <p id='forthtab' className="font-rubik-regular text-primary  text-xs ml-5" >Preview & Setup V-Secure</p>
                                </div>
                            </div>
                        </div>
                        <div className='flex flex-1 flex-col px-5 py-12' >
                            <div className='flex flex-row' >
                                <div className='flex flex-1' />
                                <button className='py-2 w-32 border-1 mx-1 border-ventlyRed text-xs text-ventlyRed font-rubik-medium' style={{borderRadius:'3px'}} >Save As Draft</button>
                                {eventContext.eventData.title !== "" ?  
                                    <button onClick={()=>handleSubmit()} className='py-2 w-32 mx-1 bg-ventlyRed flex items-center justify-center text-xs text-white font-rubik-medium' style={{borderRadius:'3px'}} >
                                        {
                                            submitting ? 
                                            <div className="progressloader ease-linear rounded-full flex border-2 border-ventlyRed h-4 w-4"/> :
                                            'Publish'
                                        }
                                    </button>
                                :null}
                            </div>
                            <div className='w-auto bg-secondary my-10 ml-16 p-px mr-4 rounded-md' >
                                <div className={display === 'firsttab' ? tab_information : 'hidden'} >
                                    <img src={require('../../../assets/icons/idea.svg')} alt='' className='w-6 mb-4' />
                                    <p className='text-sm text-primary font-rubik-medium mb-2'>Let People know what your event is all about</p>
                                    <p className='text-xs text-primary font-rubik-regular'>It's easier for people to find your event and connect with you if you have a proper description and detailed Information about the event </p>
                                </div>

                                <div className={display === 'secondtab' ? tab_information : 'hidden'} >
                                    <img src={require('../../../assets/icons/idea.svg')} alt='' className='w-6 mb-4' />
                                    <p className='text-sm text-primary font-rubik-medium mb-2'>Customize & Set The Prize Of Your Tickets</p>
                                    <p className='text-xs text-primary font-rubik-regular'>If you're looking to add gate fees or tickets to your event this section should help you get that up seamlessly. You can watch how to setup tickets properly - <a href=' ' className='text-ventlyRed' >Here</a></p>
                                </div>
                                
                                <div className={display === 'thirdtab' ? tab_information : 'hidden'} >
                                    <img src={require('../../../assets/icons/idea.svg')} alt='' className='w-6 mb-4' />
                                    <p className='text-sm text-primary font-rubik-medium mb-2'>Spice Up Your Events By Inviting Amazing Vendors & Creators</p>
                                    <p className='text-xs text-primary font-rubik-regular'>An event is never complete without the people that bring in extra vibes to the party. Send out invites to them, You'll be able to negotiate before you pay them.</p>
                                </div>
                                
                                <div className={display === 'forthtab' ? tab_information : 'hidden'} >
                                    <img src={require('../../../assets/icons/idea.svg')} alt='' className='w-6 mb-4' />
                                    <p className='text-sm text-primary font-rubik-medium mb-2'>Add the finishing touches</p>
                                    <p className='text-xs text-primary font-rubik-regular'>Have a look at what your event page looks like before you publish. Also setup V-Secure to enable the full functionalities of your tickets.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className={display === 'firsttab' ? 'flex' : 'hidden'}>
                        <EventInfo click={SecondTab} skip={ThirdTab} />
                    </div>
                    <div className={display === 'secondtab' ? 'flex' : 'hidden'}>
                        <TicketAndPrice click={ThirdTab} />
                    </div>
                    <div className={display === 'thirdtab' ? 'flex' : 'hidden'}>
                        <VendorAndCreators click={ForthTab} />
                    </div>
                    <div className={display === 'forthtab' ? 'flex' : 'hidden'}>
                        <Preview/>
                    </div>
                </div>
            </div> 
        </Layout>
    );
} 
