import React from 'react'
import Skeleton from 'react-loading-skeleton'; 

export default function FeedSkeleton(userId: any) { 

    return(
        <div className='mb-8'>
            <>
                <div className='rounded-t-md p-5 bg-primary text-primary' >
                    <Skeleton style={{width:'150px', height:'0.875rem'}} />
                </div>
                <div className='w-full flex flex-row rounded-b-md items-center mr-2 mt-px p-4 mb-5 bg-primary'>
                    <Skeleton style={{width:'50px', height:'50px', borderRadius: '100%'}} /> 
                    <div className='w-full m-2 fill-current text-secondary hover:fill-white flex flex-row items-center hover:bg-ventlyRed hover:text-white bg-secondary p-3 rounded-md cursor-pointer'>
                        <div className='p-3 bg-eventborder rounded-md'>
                            <Skeleton style={{width:'35px', height:'35px', borderRadius: '5px'}} />
                        </div>
                        <Skeleton style={{width:'150px', height:'1.2rem', marginLeft:'1.25rem'}} />
                    </div>
                    <div className='w-full fill-current text-secondary hover:fill-white m-2 flex flex-row items-center hover:bg-ventlyRed hover:text-white bg-secondary p-3 rounded-md cursor-pointer'>
                        <div className='p-3 bg-eventborder rounded-md'>
                            <Skeleton style={{width:'35px', height:'35px', borderRadius: '5px'}} />
                        </div>
                        <Skeleton style={{width:'150px', height:'1.2rem', marginLeft:'1.25rem'}} />
                    </div>
                </div>
            </>
            <div className='my-2 bg-primary rounded-md'>
                <div className="w-full flex justify-between px-5 pt-5 ">
                    <div className="flex items-center">
                        <Skeleton style={{width:'65px', height:'65px', borderRadius: '100%'}} />
                        <div className="ml-2 flex flex-col mt-1">
                            <Skeleton style={{width:'100px', height:'0.875rem'}}/>
                            <Skeleton style={{width:'60px', height:'0.75rem'}} />
                        </div>
                    </div>

                    <div className="flex">
                        <div className="flex pt-2 mr-6">
                            <Skeleton style={{width:'25px', height:'25px', borderRadius:'100%'}}/>
                            <Skeleton style={{width:'50px', height:'0.75rem', marginTop:'10px', marginLeft:'5px'}} />
                        </div>
                        <div className="pt-2">
                            <Skeleton style={{width:'10px', height:'30px'}}/>
                        </div>
                    </div>
                </div>

                <div className="w-full h-auto px-5 mt-5 mb-5 rounded-md">
                    <Skeleton style={{width:'100%', height:'500px', borderRadius:'0.375rem'}}/>
                </div>

                <div className='w-full flex flex-row'>
                    <div className='w-full'>
                        <div className="px-5">
                            <Skeleton style={{width:'220px', height:'0.875rem'}}/>
                        </div>

                        <div className="flex px-5 mt-5">
                            <div className="flex text-xs border-r-1 border-gray-200 pr-5 mr-5">
                                <Skeleton style={{width:'20px', height:'20px', borderRadius:'100%'}}/>
                                <Skeleton style={{width:'140px', height:'0.875rem', marginTop:'5px', marginLeft:'5px'}}/>
                            </div>

                            <div className="flex text-xs ">
                                <Skeleton style={{width:'20px', height:'20px', borderRadius:'100%'}}/>
                                <Skeleton style={{width:'140px', height:'0.875rem', marginTop:'5px', marginLeft:'5px'}}/>
                            </div>
                        </div>
                    </div>
                    
                    <Skeleton style={{width:'5rem', height:'4rem', borderRadius:'0.375rem', marginRight:'1.25rem'}}/>
                </div>            

                <div className="px-5 mt-5">
                    <Skeleton style={{width:'100%', height:'0.875rem'}} count={2}/>
                </div>

                <div className="w-full h-10 bg-secondary flex justify-between pt-px mt-5">
                    <div className="flex bg-primary justify-center items-center flex-1 text-xs mr-px rounded-bl-md">
                        <div className="flex">
                            <Skeleton style={{width:'18px', height:'18px', borderRadius:'100%'}}/>
                            <Skeleton style={{width:'40px', height:'0.75rem', marginTop: '5px', marginLeft:'5px'}}/>
                        </div>
                    </div>

                    <div className="flex bg-primary justify-center items-center mr-px text-xs flex-1 ">
                        <div className="flex">
                            <Skeleton style={{width:'18px', height:'18px', borderRadius:'100%'}}/>
                            <Skeleton style={{width:'55px', height:'0.75rem', marginTop: '5px', marginLeft:'5px'}}/>
                        </div>
                    </div>

                    <div className=" bg-primary flex justify-center items-center text-xs flex-1 rounded-br-md">
                        <div className="flex">
                            <Skeleton style={{width:'18px', height:'18px', borderRadius:'100%'}}/>
                            <Skeleton style={{width:'50px', height:'0.75rem', marginTop: '5px', marginLeft:'5px'}}/>
                        </div>
                    </div>
                </div> 
            </div>
            <div className='my-2 bg-primary rounded-md' >
                <div className="w-full flex justify-between px-5 pt-5 ">
                    <div className="flex items-center">
                        <Skeleton style={{width:'65px', height:'65px', borderRadius: '100%'}} />
                        <div className="ml-2 flex flex-col mt-1">
                            <Skeleton style={{width:'100px', height:'0.875rem'}}/>
                            <Skeleton style={{width:'60px', height:'0.75rem'}} />
                        </div>
                    </div>

                    <div className="flex">
                        <div className="flex pt-2 mr-6">
                            <Skeleton style={{width:'25px', height:'25px', borderRadius:'100%'}}/>
                            <Skeleton style={{width:'50px', height:'0.75rem', marginTop:'10px', marginLeft:'5px'}} />
                        </div>
                        <div className="pt-2">
                            <Skeleton style={{width:'10px', height:'30px'}}/>
                        </div>
                    </div>
                </div>

                <div className="w-full h-auto px-5 mt-5 mb-5 rounded-md">
                    <Skeleton style={{width:'100%', height:'500px', borderRadius:'0.375rem'}}/>
                </div>

                <div className='w-full flex flex-row'>
                    <div className='w-full'>
                        <div className="px-5">
                            <Skeleton style={{width:'220px', height:'0.875rem'}}/>
                        </div>

                        <div className="flex px-5 mt-5">
                            <div className="flex text-xs border-r-1 border-gray-200 pr-5 mr-5">
                                <Skeleton style={{width:'20px', height:'20px', borderRadius:'100%'}}/>
                                <Skeleton style={{width:'140px', height:'0.875rem', marginTop:'5px', marginLeft:'5px'}}/>
                            </div>

                            <div className="flex text-xs ">
                                <Skeleton style={{width:'20px', height:'20px', borderRadius:'100%'}}/>
                                <Skeleton style={{width:'140px', height:'0.875rem', marginTop:'5px', marginLeft:'5px'}}/>
                            </div>
                        </div>
                    </div>
                    
                    <Skeleton style={{width:'5rem', height:'4rem', borderRadius:'0.375rem', marginRight:'1.25rem'}}/>
                </div>            

                <div className="px-5 mt-5">
                    <Skeleton style={{width:'100%', height:'0.875rem'}} count={2}/>
                </div>

                <div className="w-full h-10 bg-secondary flex justify-between pt-px mt-5">
                    <div className="flex bg-primary justify-center items-center flex-1 text-xs mr-px rounded-bl-md">
                        <div className="flex">
                            <Skeleton style={{width:'18px', height:'18px', borderRadius:'100%'}}/>
                            <Skeleton style={{width:'40px', height:'0.75rem', marginTop: '5px', marginLeft:'5px'}}/>
                        </div>
                    </div>

                    <div className="flex bg-primary justify-center items-center mr-px text-xs flex-1 ">
                        <div className="flex">
                            <Skeleton style={{width:'18px', height:'18px', borderRadius:'100%'}}/>
                            <Skeleton style={{width:'55px', height:'0.75rem', marginTop: '5px', marginLeft:'5px'}}/>
                        </div>
                    </div>

                    <div className=" bg-primary flex justify-center items-center text-xs flex-1 rounded-br-md">
                        <div className="flex">
                            <Skeleton style={{width:'18px', height:'18px', borderRadius:'100%'}}/>
                            <Skeleton style={{width:'50px', height:'0.75rem', marginTop: '5px', marginLeft:'5px'}}/>
                        </div>
                    </div>
                </div> 
            </div>
            <div className='my-2 bg-primary rounded-md' > 
                <div className='flex flex-row pb-4 items-center'>
                    <div className='w-full flex flex-col'> 
                        <Skeleton style={{width:'150px', height:'22px', borderRadius:'0.375rem'}} />
                        <Skeleton style={{width:'180px', height:'18px', borderRadius:'0.375rem'}} /> 
                    </div>
                    <div className=' h-6 bg-carousel cursor-pointer rounded-sm mr-2' >
                        <Skeleton style={{width:'30px', height:'30px', borderRadius:'0.375rem'}} />
                    </div >
                    <div className=' h-6 bg-carousel cursor-pointer rounded-sm mr-2' >
                        <Skeleton style={{width:'30px', height:'30px', borderRadius:'0.375rem'}} />
                    </div>
                </div>
                <div className='w-full flex flex-row overflow-x-auto scroll_event ' > 
                    <div className='w-400px mr-3 ' >
                        <Skeleton style={{width:'400px', height:'220px'}} />
                        <div className='flex flex-row mt-5' >
                            <div className='w-full mt-1 flex-1 flex flex-col' >
                                <Skeleton style={{width:'120px', height:'0.875rem'}} />
                                <Skeleton style={{width:'70px', height:'0.75rem'}} />
                            </div>
                            <Skeleton style={{width:'100px', height:'40px'}} />
                        </div>                                    
                    </div>
                    <div className='w-400px mr-3 ' >
                        <Skeleton style={{width:'400px', height:'220px'}} />
                        <div className='flex flex-row mt-5' >
                            <div className='w-full mt-1 flex-1 flex flex-col' >
                                <Skeleton style={{width:'120px', height:'0.875rem'}} />
                                <Skeleton style={{width:'70px', height:'0.75rem'}} />
                            </div>
                            <Skeleton style={{width:'100px', height:'40px'}} />
                        </div>                                    
                    </div> 
                    <div className='w-400px mr-3 ' >
                        <Skeleton style={{width:'400px', height:'220px'}} />
                        <div className='flex flex-row mt-5' >
                            <div className='w-full mt-1 flex-1 flex flex-col' >
                                <Skeleton style={{width:'120px', height:'0.875rem'}} />
                                <Skeleton style={{width:'70px', height:'0.75rem'}} />
                            </div>
                            <Skeleton style={{width:'100px', height:'40px'}} />
                        </div>                                    
                    </div> 
                </div>
            </div>
            <div className='my-2 bg-primary rounded-md' >
                <div className="w-full flex justify-between px-5 pt-5 ">
                    <div className="flex items-center">
                        <Skeleton style={{width:'65px', height:'65px', borderRadius: '100%'}} />
                        <div className="ml-2 flex flex-col mt-1">
                            <Skeleton style={{width:'100px', height:'0.875rem'}}/>
                            <Skeleton style={{width:'60px', height:'0.75rem'}} />
                        </div>
                    </div>

                    <div className="flex">
                        <div className="flex pt-2 mr-6">
                            <Skeleton style={{width:'25px', height:'25px', borderRadius:'100%'}}/>
                            <Skeleton style={{width:'50px', height:'0.75rem', marginTop:'10px', marginLeft:'5px'}} />
                        </div>
                        <div className="pt-2">
                            <Skeleton style={{width:'10px', height:'30px'}}/>
                        </div>
                    </div>
                </div>

                <div className="w-full h-auto px-5 mt-5 mb-5 rounded-md">
                    <Skeleton style={{width:'100%', height:'500px', borderRadius:'0.375rem'}}/>
                </div>

                <div className='w-full flex flex-row'>
                    <div className='w-full'>
                        <div className="px-5">
                            <Skeleton style={{width:'220px', height:'0.875rem'}}/>
                        </div>

                        <div className="flex px-5 mt-5">
                            <div className="flex text-xs border-r-1 border-gray-200 pr-5 mr-5">
                                <Skeleton style={{width:'20px', height:'20px', borderRadius:'100%'}}/>
                                <Skeleton style={{width:'140px', height:'0.875rem', marginTop:'5px', marginLeft:'5px'}}/>
                            </div>

                            <div className="flex text-xs ">
                                <Skeleton style={{width:'20px', height:'20px', borderRadius:'100%'}}/>
                                <Skeleton style={{width:'140px', height:'0.875rem', marginTop:'5px', marginLeft:'5px'}}/>
                            </div>
                        </div>
                    </div>
                    
                    <Skeleton style={{width:'5rem', height:'4rem', borderRadius:'0.375rem', marginRight:'1.25rem'}}/>
                </div>            

                <div className="px-5 mt-5">
                    <Skeleton style={{width:'100%', height:'0.875rem'}} count={2}/>
                </div>

                <div className="w-full h-10 bg-secondary flex justify-between pt-px mt-5">
                    <div className="flex bg-primary justify-center items-center flex-1 text-xs mr-px rounded-bl-md">
                        <div className="flex">
                            <Skeleton style={{width:'18px', height:'18px', borderRadius:'100%'}}/>
                            <Skeleton style={{width:'40px', height:'0.75rem', marginTop: '5px', marginLeft:'5px'}}/>
                        </div>
                    </div>

                    <div className="flex bg-primary justify-center items-center mr-px text-xs flex-1 ">
                        <div className="flex">
                            <Skeleton style={{width:'18px', height:'18px', borderRadius:'100%'}}/>
                            <Skeleton style={{width:'55px', height:'0.75rem', marginTop: '5px', marginLeft:'5px'}}/>
                        </div>
                    </div>

                    <div className=" bg-primary flex justify-center items-center text-xs flex-1 rounded-br-md">
                        <div className="flex">
                            <Skeleton style={{width:'18px', height:'18px', borderRadius:'100%'}}/>
                            <Skeleton style={{width:'50px', height:'0.75rem', marginTop: '5px', marginLeft:'5px'}}/>
                        </div>
                    </div>
                </div> 
            </div>
            <div className='my-2 bg-primary rounded-md' >
                <div className="w-full flex justify-between px-5 pt-5 ">
                    <div className="flex items-center">
                        <Skeleton style={{width:'65px', height:'65px', borderRadius: '100%'}} />
                        <div className="ml-2 flex flex-col mt-1">
                            <Skeleton style={{width:'100px', height:'0.875rem'}}/>
                            <Skeleton style={{width:'60px', height:'0.75rem'}} />
                        </div>
                    </div>

                    <div className="flex">
                        <div className="flex pt-2 mr-6">
                            <Skeleton style={{width:'25px', height:'25px', borderRadius:'100%'}}/>
                            <Skeleton style={{width:'50px', height:'0.75rem', marginTop:'10px', marginLeft:'5px'}} />
                        </div>
                        <div className="pt-2">
                            <Skeleton style={{width:'10px', height:'30px'}}/>
                        </div>
                    </div>
                </div>

                <div className="w-full h-auto px-5 mt-5 mb-5 rounded-md">
                    <Skeleton style={{width:'100%', height:'500px', borderRadius:'0.375rem'}}/>
                </div>

                <div className='w-full flex flex-row'>
                    <div className='w-full'>
                        <div className="px-5">
                            <Skeleton style={{width:'220px', height:'0.875rem'}}/>
                        </div>

                        <div className="flex px-5 mt-5">
                            <div className="flex text-xs border-r-1 border-gray-200 pr-5 mr-5">
                                <Skeleton style={{width:'20px', height:'20px', borderRadius:'100%'}}/>
                                <Skeleton style={{width:'140px', height:'0.875rem', marginTop:'5px', marginLeft:'5px'}}/>
                            </div>

                            <div className="flex text-xs ">
                                <Skeleton style={{width:'20px', height:'20px', borderRadius:'100%'}}/>
                                <Skeleton style={{width:'140px', height:'0.875rem', marginTop:'5px', marginLeft:'5px'}}/>
                            </div>
                        </div>
                    </div>
                    
                    <Skeleton style={{width:'5rem', height:'4rem', borderRadius:'0.375rem', marginRight:'1.25rem'}}/>
                </div>            

                <div className="px-5 mt-5">
                    <Skeleton style={{width:'100%', height:'0.875rem'}} count={2}/>
                </div>

                <div className="w-full h-10 bg-secondary flex justify-between pt-px mt-5">
                    <div className="flex bg-primary justify-center items-center flex-1 text-xs mr-px rounded-bl-md">
                        <div className="flex">
                            <Skeleton style={{width:'18px', height:'18px', borderRadius:'100%'}}/>
                            <Skeleton style={{width:'40px', height:'0.75rem', marginTop: '5px', marginLeft:'5px'}}/>
                        </div>
                    </div>

                    <div className="flex bg-primary justify-center items-center mr-px text-xs flex-1 ">
                        <div className="flex">
                            <Skeleton style={{width:'18px', height:'18px', borderRadius:'100%'}}/>
                            <Skeleton style={{width:'55px', height:'0.75rem', marginTop: '5px', marginLeft:'5px'}}/>
                        </div>
                    </div>

                    <div className=" bg-primary flex justify-center items-center text-xs flex-1 rounded-br-md">
                        <div className="flex">
                            <Skeleton style={{width:'18px', height:'18px', borderRadius:'100%'}}/>
                            <Skeleton style={{width:'50px', height:'0.75rem', marginTop: '5px', marginLeft:'5px'}}/>
                        </div>
                    </div>
                </div> 
            </div>
        </div>
    )
}
