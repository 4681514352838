import React from 'react';
 
import {IoIosClock, IoIosHeart, IoIosMore, IoMdPeople, IoMdPin} from 'react-icons/io'
import Map from './Event_Components/Map'
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import VSecure from './Event_Components/V_Secure_modal'
import ReactPlayer from 'react-player/youtube' 
import { EventContext, EventSchema } from '../../../../Contexts/EventContext';
import { IUser, UserContext } from '../../../../Contexts/UserContext';


export default function Preview(props: any) {
 
  const [show, setShow] = React.useState(true)
  const [fullname, SetFullname] = React.useState('');  
  const [limit, setLimit] = React.useState(2) 
  const eventContext: EventSchema = React.useContext(EventContext);
  const userContext: IUser = React.useContext(UserContext);  
  let str = userContext.userData.firstname+' '+ userContext.userData.lastname
   
  var featured_images: any = Array.from(eventContext.featureImage).map((file) => URL.createObjectURL(file)); 
  let Total = 0;

  React.useEffect(() => {
    if(str.length > 25){
        return( 
            SetFullname(str.substring(0,25)+'...')
        )
    } else {
        return(
            SetFullname(str)
        )
    }
}, [str]);

  const ClickHandler = () => {
    setShow(prev => !prev)
  }

  const NumberOfPeople = (source: any) => {
    const rowLen = source.length;

    const Sum = (index: any, rowLen: any, item: any) => {
      Total = Number(Total) + Number(item.quantity)
      if(rowLen === index + 1){
      return(
        <p key={index} className='font-rubik-regular text-sm ml-2'>{Total+''}</p>
      )}
    }
    
    return source.map((item: any, index: any) => {
        return(
          <div key={index}>
            {Sum(index, rowLen, item)}
          </div>
        )
    })
  }

  const FirstValue = (source: any) => { 
    return  source.map((item: any, index: any) => { 
        return(
            <p key={index} className={index === 0 ? 'font-rubik-regular text-sm ml-1': 'hidden'}>${item.amount}</p>
        ) 
    })
   
  }

  const LastValue = (source: any) => {
    if(source.length - 1 > 0){
      return(
        <div className='flex flex-row ml-2' >
          <p className='font-rubik-regular text-sm ml-1'>-</p>
          <p className='font-rubik-regular text-sm ml-2'>${source[source.length - 1].amount} </p>
        </div>
    )}
  }

  const VendorsList =()=> { 
    if(eventContext.eventData.vendorsList !== []){
      return eventContext.eventData.vendorsList.slice(0, limit).map((item: any, index: any) => {
        return(
          <div key={item+''+index} className='w-full h-24 mt-px bg-secondary flex flex-row items-center px-4' >
            <div style={{height:'65px', width:'65px'}} className='  rounded-full bg-gray-200 flex justify-center items-center' >
                <img src = {item.displaypic+""} className='w-full h-full flex flex-1 rounded-full object-cover' alt=""/>
            </div>
            <div className='w-auto ml-2 flex flex-col' >
              <p className='font-rubik-medium text-sm' >{item.firstname+' '+item.lastname}</p>
              <div className='flex flex-row'>
                <p className='font-rubik-light text-xs mr-1' >{'@ '+item.username}</p> 
              </div>
            </div>
            <div className='w-full flex flex-1' />
            <button className='py-2 w-20 h-8 bg-ventlyRed ml-8 text-xs text-white font-rubik-medium' style={{borderRadius:'3px'}} >Follow</button>
          </div>
        )
      })
    }
  }   

  return (
    <div className='w-full h-full bg-secondary flex flex-col text-primary mt-4' >
        <VSecure />
        <div className='w-full h-full px-8 flex flex-col py-8 items-center bg-primary rounded-lg' >
            <div className='w-full h-32 bg-secondary flex items-center justify-center rounded-md' >
                <p className='font-rubik-regular text-sm '>This is a preview of your event page, only you can see this at the moment.</p>
            </div>
            <div className='w-full h-350px bg-secondary my-4 rounded-md' > 
                <img className='w-full h-full rounded-md object-cover' src={eventContext.banner} alt=""/>
            </div>
            <div className='w-full h-full mt-12 flex flex-row' >
              <div className='w-full flex flex-1 flex-col mt-1' >
                <div className='w-full h-10 flex flex-row mr-1 items-center' >
                  <p className='font-rubik-medium text-xl' >{Object.values(eventContext.eventData.title)}</p>
                  <div className='w-full flex flex-1' />
                  <button className='py-1 px-2 mx-1 w-auto h-10 bg-carousel text-xs text-white font-rubik-medium' style={{borderRadius:'3px'}} ><IoIosHeart size='25px' className='text-secondary' /></button>
                  <button className='py-2 w-32 h-10 bg-ventlyRed text-xs text-white font-rubik-medium' style={{borderRadius:'3px'}} >Follow</button>
                  <button className='py-1 px-3 mx-1 w-auto h-10 bg-carousel text-xs text-white font-rubik-medium' style={{borderRadius:'3px'}} ><IoIosMore size='15px' className='text-secondary' /></button>
                </div>
                <div className='w-full flex flex-col' >
                  <div className='w-full flex flex-row'>
                    <p className='font-rubik-medium text-xs' >Hosted By</p>
                    <p className='font-rubik-medium text-xs ml-2' style={{color:'#FFAABE'}} >Jessica Anderson</p>
                  </div>
                  <p className='font-rubik-regular text-xs mt-8 mr-12' >{Object.values(eventContext.eventData.description)}</p>
                </div>
                <div className='w-full h-450px pr-4 mt-8' >  
                  <ReactPlayer 
                    url={eventContext.videourl}
                    width='100%' 
                    height='100%' 
                  />
                </div>
                <div className='w-full mt-4 grid grid-cols-5 gap-1' >
                  {featured_images.map((pic: any, index: any) => { 
                        return (
                          <div key={index} className=' bg-secondary p-px w-40 h-40 rounded-md mr-2'  >
                              <div style={{backgroundImage:'url(' + pic + ')',width:'100%', backgroundRepeat:'no-repeat', backgroundSize:'cover', height: '100%', display: 'flex', backgroundPosition: 'center', borderRadius: '5px' }} />
                          </div>  
                        )
                      })
                    }
                </div>
                <div className='w-full pr-4 mt-10' >
                  <div onClick={ClickHandler} className=' w-full bg-secondary cursor-pointer rounded-md h-12 pl-4 flex flex-row items-center' >
                    <p className='font-rubik-medium text-sm' >Discussion (0)</p>
                    <div className='w-full flex flex-1' />
                    {!show ? (
                      <MdKeyboardArrowDown className='w-6 h-6 text-primary mr-4' />
                    ): (
                      <MdKeyboardArrowUp className='w-6 h-6 text-primary mr-4' />
                    )}                    
                  </div>
                  {show ? (
                    <div className='w-full' >
                      <p className='font-rubik-regular text-xs my-4 text-primary inline-flex ml-2'>No comments yet. Be the first to<span className='font-rubik-regular text-xs text-ventlyRed ml-1' >leave a comment</span></p>
                      <textarea className="w-full  h-40 rounded-md bg-secondary text-primary text-xs p-5 font-Rubik-regular text-darkmodefont " placeholder='Enter your comment' />
                      <div className='w-full flex flex-1 flex-row mt-4'>
                        <div className='w-full flex' />
                        <button className='py-3 w-32 bg-ventlyRed text-xs text-white font-rubik-medium' style={{borderRadius:'3px'}} >Comment</button>
                      </div>
                    </div>
                    
                  ): null}
                  
                </div>
              </div>
              <div className='w-350px h-full bg-primary mr-4' >
                <div className='w-full h-auto bg-primary rounded-md' >
                  <div className='w-full bg-secondary rounded-t-md h-24 flex flex-col justify-center px-4' >
                    <p className='font-rubik-medium text-xs' >Date & Time</p>
                    <div className='w-full flex flex-row mt-2 items-center' >
                      <IoIosClock size='25px'  className='text-secondary' />
                      <div className='w-auto text-primary text-sm font-Rubik-regular ml-2'>
                          <p>{Object.values(eventContext.eventData.start_date)}</p>
                      </div> 
                    </div>
                  </div>
                  <div className='w-full bg-secondary mt-px h-24 flex flex-col justify-center px-4' >
                    <p className='font-rubik-medium text-xs' >Location</p>
                    <div className='w-full flex flex-row mt-2 items-center' >
                      <IoMdPin size='25px'  className=' text-ventlyRed' />
                      <p className='font-rubik-regular text-sm ml-2'>{Object.values(eventContext.eventData.location)}</p>
                    </div>
                  </div>
                  <div className='w-full bg-secondary mt-px h-24 flex flex-col justify-center px-4' >
                    <p className='font-rubik-medium text-xs' >Attendees</p>
                    <div className='w-full flex flex-row mt-2 items-center' >
                      <IoMdPeople size='25px'  color='#1EACD1' />
                      {NumberOfPeople(eventContext.eventData.ticketsTypesList)}
                    </div>
                  </div>
                  <div className='w-full bg-secondary h-40 flex flex-col mt-px justify-center px-4 rounded-b-md' >
                    <p className='font-rubik-medium text-xs' >Ticket Pricing</p>
                    <div className='w-full flex flex-row mt-2 items-center ' >
                      <IoMdPeople size='25px'  color='#1EACD1' />
                      <div className='flex flex-row ml-2' >
                        {FirstValue(eventContext.eventData.ticketsTypesList)}
                        {LastValue(eventContext.eventData.ticketsTypesList)}
                      </div>
                    </div>
                    <button className='py-2 w-full h-10 bg-ventlyRed mt-4 text-xs text-white font-rubik-medium' style={{borderRadius:'3px'}} >Buy Tickets</button>
                  </div>
                </div>
                <div className='w-full h-auto bg-primary rounded-md mt-4' >
                  <div className='w-full h-12 bg-secondary rounded-t-md flex flex-col justify-center px-4' >
                      <p className='font-rubik-medium text-sm' >Host</p>
                  </div>
                  <div className='w-full h-24 mt-px bg-secondary rounded-b-md flex flex-row items-center px-4' >
                    <img src={userContext.userData.displaypic} alt='' className=' w-16 h-16 rounded-full object-cover' />
                    <div className='w-auto ml-2 flex flex-col' >
                      <p className='font-rubik-medium text-sm' >{fullname}</p>
                      <div className='flex flex-row'>
                        <p className='font-rubik-light text-xs mr-1' >@{userContext.userData.username}</p>
                        {/* <p className='font-rubik-light text-xs'>.</p>
                        <p className='font-rubik-light text-xs ml-1' >@a.suarez</p> */}
                      </div>
                    </div>
                    <button className='py-2 w-20 h-8 bg-ventlyRed ml-8 text-xs text-white font-rubik-medium' style={{borderRadius:'3px'}} >Follow</button>
                  </div>
                </div> 
                <div className='w-full h-auto bg-primary rounded-md mt-4 mb-2' >
                  <div className='w-full h-12 bg-secondary rounded-t-md flex flex-col justify-center px-4' >
                      <p className='font-rubik-medium text-sm' >Vendors & Creators Attending</p>
                  </div>
                  {VendorsList()}
                  <div className='w-full py-2 px-4 mb-4 flex bg-secondary rounded-b-md'>
                    <div className='w-full flex flex-1' />
                    {limit < eventContext.eventData.vendorsList.length  ?
                      <p onClick={()=> setLimit(limit+2)} className='font-rubik-medium cursor-pointer text-sm text-ventlyRed'>view more</p>
                    :null}
                  </div>
                </div> 
                <div className=' w-full flex flex-col rounded-md bg-primary mt-4' >
                  <div className='w-full flex rounded-t-md bg-secondary py-4 px-4' >
                    <p className='font-rubik-medium text-sm text-primary mt-2' >Map</p>
                  </div>
                  <div className=' w-full px-2 py-4 flex bg-secondary mt-px rounded-b-md' >
                    <Map />
                  </div>
                </div>
              </div>
            </div>
        </div>
    </div>
  );
}