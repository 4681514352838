import React from 'react'; 
import Creator from './EventCreators'
import Ticket from './GetTicket'
import MorePhoto from './EventPhoto'
import OtherEvent from './OtherEvent'

export default function Others(props: any) {

    const [showContent, setShowContent] = React.useState(0);

    React.useEffect(() => {
        if(showContent=== 4){
            setShowContent(0)
        } else { 
            let initial  = showContent
            setShowContent(initial+1)  
        }
    },[])

    // const NextContent =(index: any)=> {

    //     let initial  = index
    //     setShowContent(initial+1)    

    //     return index
    // }



    return (
        <div> 
            {props.index % 4 !== 0 && props.index % 6 !== 0  && props.index % 8 !== 0 && props.vendor !== undefined ?
                <>
                    <Creator vendor={props.vendor} />  
                </>:null
            }
            {props.index % 4 === 0 && props.index % 6 !== 0 && props.index % 8 !== 0 ?
                <>
                    <MorePhoto userpic={require('../../../../assets/images/avatar.png')} firstimg={require('../../../../assets/images/photo1.png')} secondimg={require('../../../../assets/images/photo.png')} />
                </>:null
            }
            {props.index % 6 === 0 && props.index % 8 !== 0  ?
                <>
                    <OtherEvent events={props.events} />
                </>:null
            }
            {props.index % 8 === 0 ?
                <>
                    <Ticket userpic={require('../../../../assets/images/avatar.png')} img={require('../../../../assets/images/mac.png')} /> 
                </>:null
            }
        </div>
    );
}
