import * as axios from 'axios'   
import { IReturnObject } from '../../globals/ReturnOject' 
import { BASEURL } from '../../globals/URL'  

type eventPayload = {  
    user_id: string,
    title: string,
    description: string,
    location: string,
    latitude: string,
    longitude: string,
    start_date: string,
    end_date: string, 
    category: string,
    privacy: boolean,
    isLiveEvent: boolean,
    isMature: boolean,
    draft: boolean, 
    vendorsList: any,
    ticketsTypesList: any
} 

export const Event = async( payload: eventPayload): Promise<IReturnObject> => {
    const ReturnObj: IReturnObject = {
        error: false,
        data: null,
        errorMessage: '',
        errorData: null,
        successMessage: '',
        statusCode: 200
    } 
    try {
        // make request to vently server
        const request = await axios.default.post(`${BASEURL.URL}/events/create/${localStorage.getItem('id')}`, payload, {
                headers: { 'content-type': 'application/json'}
            })   
            console.log(request)

            return request.data as IReturnObject;
    } catch (error) {
        ReturnObj.error = true;
        ReturnObj.errorMessage = 'An error occured while trying to Create an event';
        ReturnObj.errorData = error;
        ReturnObj.statusCode = 400;
        return ReturnObj;
    }
}