import React from 'react'

import { FieldProps, getIn } from "formik";
import { motion } from "framer-motion";


const Input = ({ field, form: {errors}}: FieldProps ) => {
    const errorMassage = getIn(errors, field.name);

    if(field.name.slice(-4) === 'name'){

        return(
            <>
                <div className='w-auto flex flex-col'>
                    <input type='text' className=" rounded-md bg-secondary text-primary items-center text-xs px-5 font-Rubik-regular w-300px h-12 " placeholder='Ticket Name - Regular, VIP etc' {...field} />
                    {errorMassage && <div className="w-full h-5 max-h-5">
                    <motion.p 
                        initial={{ y: -100, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        className="text-xs text-ventlyRed mt-2">{errorMassage}</motion.p>
                    </div>}
                </div>
                
            </>
        )
    } else if (field.name.slice(-6) === 'amount'){
        
        return(
            <>
                <div className='w-auto flex flex-col'>
                    <div className=' relative flex flex-wrap items-center' >
                        <input type='number' 
                            className=" rounded-md bg-secondary text-primary items-center text-xs pl-8 pr-4 font-Rubik-regular w-300px h-12 " 
                            placeholder='Enter Price Of Tickets' {...field} />
                        <p className=' absolute text-primary text-sm font-Rubik-regular ml-4 ' >$</p>
                    </div>
                    {errorMassage && <div className="w-full h-5 max-h-5">
                        <motion.p 
                            initial={{ y: -100, opacity: 0 }}
                            animate={{ y: 0, opacity: 1 }}
                            className="text-xs text-ventlyRed mt-2">{errorMassage}</motion.p>
                    </div>}                 
                    
                </div>
                
            </>
        )
    } else if (field.name.slice(-8) === 'quantity'){

        return(
            <>
                <div className='w-auto flex flex-col'>
                    <input type='number' 
                        className=" rounded-md bg-secondary text-primary items-center  text-xs px-5 font-Rubik-regular w-300px h-12 " 
                        placeholder='Enter number of available tickets' {...field} />
                    {errorMassage && <div className="w-full h-5 max-h-5">
                    <motion.p 
                        initial={{ y: -100, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        className="text-xs text-ventlyRed mt-2">{errorMassage}</motion.p>
                    </div>}
                </div>
                
            </>
        )
    } else if (field.name.slice(-11) === 'description'){
        
        return(
            <>
                <div className='w-auto flex flex-col'>
                    <textarea {...field}
                        className="w-300px h-24 rounded-md bg-secondary text-primary text-xs p-2 font-Rubik-regular " 
                        placeholder='Additional Ticket Information - e.g : This ticket gives you access to the VIP lounge & 5 cocktails' />
                    {errorMassage && <div className="w-full h-5 max-h-5">
                        <motion.p 
                            initial={{ y: -100, opacity: 0 }}
                            animate={{ y: 0, opacity: 1 }}
                            className="text-xs text-ventlyRed mt-2">{errorMassage}</motion.p>
                    </div>}
                </div>
                
            </>
        )
    } else {
        return(
            <div>null</div>
        )
    }
    
}

export default Input