import { motion } from 'framer-motion'
import React from 'react'

export default function Backgroud_Photo(props: any) {
    return (
        <motion.div 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1}}
            transition={{ delay: 0.5, duration: 0.5 }}
            className="w-full h-full bg-ventlyRed flex-1">
                <img className='w-full h-full flex object-cover' src={props.img} alt={props.alt} />
        </motion.div>
    )
}
