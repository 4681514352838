import React from 'react'

import NavBar from '../../Universal-Components/Navbar'
import { MdArrowBack, MdLock } from 'react-icons/md'
import { useHistory } from 'react-router-dom'
import Layout from '../../../Darkmode/Layout'
import { ThemeContext } from '../../../Darkmode/themeContext'
import '../../../styles/Settings.css'

export default function Settings() { 
     
    const history = useHistory(); 

    return (
        <Layout>
        <div className="flex flex-1 flex-col w-full h-screen bg-secondary overflow-hidden">
            <div className="w-full h-auto bg-white shadow z-20 flex flex-col ">
                <NavBar />
            </div>
            <div className="flex flex-col bg-secondary text-primary p-12 overflow-auto">
                <div className='flex items-center mb-6'>
                    <div className='rounded-full bg-ventlyRed p-1 cursor-pointer' onClick={()=> history.push('./feed')}>
                        <MdArrowBack size='25' color='white'  />
                    </div>
                    <h1 className="text-md font-rubik-medium  ml-3">Settings</h1>
                </div>
                <div className='w-full h-auto flex flex-row text-primary'>
                    <div className='w-350px flex flex-col mr-8' >
                        <div className=" w-full flex flex-row bg-primary items-center mb-8 p-8 rounded-lg">
                            <p className="text-sm font-rubik-regular flex-1">Turn On Dark Mode</p>
                            <Toggle/>
                        </div>
                        <div className=" w-full flex flex-col bg-primary items items-center mb-8 py-4 px-1 rounded-lg">
                            <div className=" w-full flex bg-secondary items-center mx-1 my-1 px-4 py-3 rounded-md">
                                <p className="text-sm font-rubik-medium flex-1">General Setting</p>
                            </div>
                            <div className=" w-full flex items-center mx-1 my-1 px-4 py-3 rounded-md">
                                <p className="text-sm font-rubik-regular flex-1">Support Tickets</p>
                            </div>
                            <div className=" w-full flex items-center mx-1 my-1 px-4 py-3 rounded-md">
                                <p className="text-sm font-rubik-regular flex-1">Event Feeds Settings</p>
                            </div>
                            <div className=" w-full flex items-center mx-1 my-1 px-4 py-3 rounded-md">
                                <p className="text-sm font-rubik-regular flex-1">Notifications</p>
                            </div>
                            <div className=" w-full flex  items-center mx-1 my-1 px-4 py-3 rounded-md">
                                <p className="text-sm font-rubik-regular flex-1">Card & Banks</p> 
                            </div>
                            <div className=" w-full flex items-center mx-1 my-1 px-4 py-3 rounded-md">
                                <p className="text-sm font-rubik-regular  flex-1">Privacy & Security</p>
                            </div>
                            <div className=" w-full flex items-center mx-1 my-1 px-4 py-3 rounded-md">
                                <p className="text-sm font-rubik-regular flex-1">Report A Problem</p>
                            </div>
                        </div>
                    </div>  
                    <div className='w-full h-auto text-primary' >
                        <div className='w-full bg-primary items mb-px px-16 py-10 rounded-t-lg'>
                            <p className="text-mb font-rubik-medium ">General Settings</p>    
                        </div>        
                        <div className='w-full flex-1 flex flex-row bg-primary px-16 pt-10'>
                            <div className='w-full flex flex-col mr-4'>
                                <div className='w-full'>
                                    <p className="text-sm font-rubik-medium  mb-2"> Name</p>
                                    <input type="text" className=" rounded-md bg-secondary text-primary items-center text-sm font-heebo-medium text-darkmodefont w-full p-4 " placeholder='Search' />
                                </div>
    
                                <div className='w-full mt-24'>
                                    <p className="text-sm font-rubik-medium mb-2">UserName</p>
                                    <input type="text" className=" rounded-md bg-secondary text-primary items-center text-sm font-heebo-medium text-darkmodefont w-full p-4 " placeholder='Search' />
                                    <p className="text-xs font-rubik-regular mt-2">You can only this once every 14 days</p>
                                </div>
                            </div>
                            <div className='w-full flex flex-col ml-4'>
                                <div className='w-full'>
                                    <p className="text-sm font-rubik-medium mb-2">Email Address</p>
                                    <input type="text" className=" rounded-md bg-secondary text-primary items-center text-sm font-heebo-medium text-darkmodefont w-full p-4 " placeholder='Search' />
                                    <div className='flex flex-1'>
                                        <div className='pt-2 flex flex-row'>
                                            <MdLock size='35' className='bg-primary mr-2'  />
                                            <div className='mt-1' >
                                                <p><span className="text-xs font-rubik-regular ">For security reasons you can't change your email address manually yet</span>
                                                <span className="text-xs text-ventlyRed font-rubik-bold cursor-pointer"> Contact Support </span><span className="text-xs font-rubik-regular ">and we'll provide you wth a form to request a change your email.</span></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>  
                        <div className='w-full bg-primary rounded-b-lg flex pt-4 pb-8 px-16'>
                            <div className=' w-full flex flex-1' />
                            <button className='bg-ventlyRed px-8 py-3 font-rubik-medium text-sm text-white ' style={{borderRadius:'3px'}} >Save</button>
                        </div>
                    </div>
                            
                </div>
            </div>
        </div>
    </Layout>
    )
}

const Toggle = () => {
    const { theme, setTheme } = React.useContext(ThemeContext);

    function isDark() {
      return theme === 'dark'
    }
    return (
        <div>
            <label className="switch">
                <input type="checkbox" checked={isDark()}
                    onChange={e => setTheme(e.target.checked ? "dark" : "light")}/>
                <span className="slider round"></span>
            </label>
        </div> 
    )
  }