import React from 'react'
 
import ProtectedRoute from './ProtectedRoute';
import AccessRoute from './AccessedRoute'
import { Switch } from 'react-router-dom';

// import Desktop from './DesktopRoutes'
// import Mobile from './MobileComponent' 
// import useWindowSize from './WindowSize'  
import SignupRoute from './SignupRoute';
import HomePage from '../Website-Pages/Desktop-Screens/Home-Screen/Homepage';
import Login from '../Website-Pages/Desktop-Screens/Login-Screen/Login';
import Signup from '../Website-Pages/Desktop-Screens/Registration-Screens/Signup';
import VerifyEmail from '../Website-Pages/Desktop-Screens/Registration-Screens/EmailVerify';
import Setusername from '../Website-Pages/Desktop-Screens/Registration-Screens/Setusername';
import Interests from '../Website-Pages/Desktop-Screens/Registration-Screens/Interest';
import Feeds from '../Website-Pages/Desktop-Screens/Feeds-Screen/Feeds';
import Settings from '../Website-Pages/Desktop-Screens/Settings-Screen/Settings';
import Newevent from '../Website-Pages/Desktop-Screens/Event-Creation-Screen/CreateEvent' 

export default function WebsiteRoute() {

    // const userContext: IUser = React.useContext(UserContext);  

    // const token = localStorage.getItem('token') as string;
    // const [token, setToken] = React.useState(localStorage.getItem('token')); 
    // const [width, setWidth] = React.useState(useWindowSize());
    // const newWidth= useWindowSize(); 

    // const getToken = () => {
    //     const tokenString: any = localStorage.getItem('token');
    //     setToken(tokenString);  
    // };

    // React.useEffect(() => {
    //     getToken();  
    // },[]);  

    return (
        <Switch>  
            <AccessRoute  
                exact
                path='/'
                component={HomePage} />  

            <AccessRoute  
                exact
                path='/login'
                component={Login} />  

            <AccessRoute  
                exact
                path='/signup'
                component={Signup}/> 

            <ProtectedRoute 
                exact
                path='/feed'
                component={Feeds} />  

            <SignupRoute 
                exact
                path="/verifyemail" 
                component={VerifyEmail} 
            /> 

            <SignupRoute 
                exact
                path="/setusername"
                component={Setusername} 
            />   

            <ProtectedRoute 
                exact
                path="/settings"
                component={Settings} 
            /> 

            <SignupRoute
                exact
                path="/interests" 
                component={Interests} 
            /> 

            <ProtectedRoute 
                exact
                path="/newevent" 
                component={Newevent} 
            />  

        </Switch>
    )
} 