import * as axios from 'axios'   
// import { IReturnObject } from '../../globals/ReturnOject' 
import { BASEURL } from '../../globals/URL' 

export const Bannerimage = async( file: any, ID: any) => {
  
    try {

        let formData = new FormData()

        formData.append('bannerimage', file)   

        // make request to vently server
        await axios.default.post(`${BASEURL.URL}/events/bannerimage/${ID}`, formData, {
            headers: { 'content-type': 'application/json'}
        })   
             
    } catch (error) {
        console.log(error)
    }
}

export const Featuredimages = async( payload: any, ID: any) => {

    try {
        // make request to vently server

        let formData = new FormData() 
        
        payload.map((item: any) => 
            formData.append('featuredimage', item)
        );
 
        await axios.default.post(`${BASEURL.URL}/events/featuredimages/${ID}`, formData, {
            headers: { 'content-type': 'application/json'}
        })    
    } catch (error) {
        console.log(error)
    }
}