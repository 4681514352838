import React from 'react'
import { MdClose, MdCheck } from 'react-icons/md' 
import { IUser, UserContext } from '../../../../../Contexts/UserContext';
// import { NavLink } from 'react-router-dom'

export default function V_Secure_modal() {

    const [showModal, setShowModal] = React.useState(false);
    const [v_Secure, set_V_Secure] = React.useState(false);
    const userContext: IUser = React.useContext(UserContext);  

    const SaveEmail = () => {
        set_V_Secure(true);
        setShowModal(false);
    }
    
    return (
        <div >
            <div className='w-full h-24 px-8 flex flex-row items-center bg-primary mb-4 rounded-lg' >
                {!v_Secure ? (
                    <p className='font-rubik-medium text-sm ' >You'll need to setup V-Secure TM For this event to enable your tickets</p>
                ):(
                    <>
                        <div className='h-10 w-10 flex justify-center items-center rounded-full mr-8' style={{backgroundColor:'#33E16D'}} ><MdCheck size='20' color='white' /></div>
                        <p className='font-rubik-medium text-sm '>V-Secure has been setup for this event</p>
                    </>
                )}
                
                <div className='w-full flex flex-1' />
                <button onClick={() =>setShowModal(true)} className='py-2 w-32 h-10 bg-ventlyRed text-xs text-white font-rubik-medium' style={{borderRadius:'3px'}} >Set Up V-Secure</button>
            </div>
            {showModal ? (
                    <>
                        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                            <div className="relative rounded-md w-850px my-6 mx-auto max-w-3xl"> 
                                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-secondary outline-none focus:outline-none"> 
                                    <div className="flex items-center bg-primary justify-center px-5 py-3 mb-px rounded-t">
                                        <div className='w-full flex flex-1 justify-center rounded-t-lg ' >
                                            <h3 className=" font-rubik-bold text-sm text-primary ">
                                                Setup V-Secure
                                            </h3>
                                        </div>
                                        <div className=" cursor-pointer h-8 w-8 bg-secondary flex justify-center items-center rounded-full text-primary text-2xl" onClick={() => setShowModal(false)} >
                                            <MdClose size='18' />
                                        </div>
                                    </div> 
                                    <div className='w-full px-8 bg-primary rounded-b-lg justify-center flex flex-col'>
                                        <p className='font-rubik-regular text-sm text-center mt-8' >Without V-Secure, Users cannot be signed in to your event, you cannot use our ticketing system.and you lose track of important event analytics</p>
                                        <div className='w-full flex flex-col items-center mt-8' >
                                            <p className='font-rubik-medium text-sm' >V-Secure Event ID</p>
                                            <p className='font-rubik-regular mt-2 text-xl' >1781972972890</p>
                                        </div>
                                        <div className='w-full flex flex-col mt-8'>
                                            <p  className='font-rubik-medium text-sm text-center'>Team Members</p>
                                            <p className=' font-heebo-regular text-sm text-center mt-2'>These are those with permissions to sign attendees in. Don't grant anyone access if you are not sure of the email address</p>
                                            <div className='w-full flex flex-col mt-4' >
                                                <div className='w-full flex flex-row my-2' >
                                                    <p className=' w-300px flex font-rubik-medium text-sm'>Email Address</p>
                                                    <p className=' flex font-rubik-medium text-sm'>Time Added</p>
                                                    <div className='w-full flex flex-1' />
                                                    <p className='mr-24 font-rubik-medium text-sm'>Action</p>
                                                </div>
                                                <div className='w-full h-10 flex flex-row items-center mt-3' >
                                                    <p className=' w-300px font-rubik-regular text-sm'>{userContext.userData.email}</p>
                                                    <p className='flex font-rubik-regular text-sm ml-3'>2h ago</p>
                                                    <div className='w-full flex flex-1 ' />
                                                    <div className='flex' >
                                                        <button className='py-2 mr-1 bg-ventlyRed text-xs text-white font-rubik-medium opacity-50 cursor-not-allowed' style={{borderRadius:'3px', width:'71px'}} >Edit</button>
                                                        <button className='py-2 border-1 mx-1 border-ventlyRed text-xs text-ventlyRed font-rubik-medium opacity-50 cursor-not-allowed' style={{borderRadius:'3px', width:'71px'}} >Delete</button>
                                                    </div>
                                                </div>
                                                <AddEmail />
                                                <div className='w-full flex flex-row items-center mt-4 mb-8'>
                                                    <div className='w-full flex flex-1' />
                                                    <button onClick={() => SaveEmail()} className='h-12 w-32 mx-1 bg-ventlyRed text-xs text-white font-rubik-medium' style={{borderRadius:'3px'}} >Save</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div> 
                                </div>
                            </div>
                        </div>
                        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                    </>
                ) : null}             
        </div>
    )
}

function AddEmail(){
    
    const [addmail, setAddMail] = React.useState(false)
    const [email, setEmail] = React.useState([])
    const [edit, setEdit] = React.useState(-1)

    let newArray: any = [...email]

    const inputHandler = (e: any) => {
        newArray.push(e.target.value)  
    }

    const editHandler = (e: any, index: any) => {
        newArray[index]= [e.target.value]  
    }

    const changeHandler = () => {
        setEdit(-1)
        setEmail(newArray)
    }

    const clickHandler = () => {
        setEmail(newArray)
        setAddMail(false)
    }

    function removeHandler(id: any) {
        const newList = email.filter((item: any) => item !== id);
        setEmail(newList);
    }
    
    const NewEmail = (source: any, value: any) => {

        return source.map((item: any, index: any) => {
            return(
                <div>
                    {files(index, item, value)}
                </div>
            )
        })
    }

    const files =(index: any, item: any, value: any) => {
        if(index > -1){
            if(index === value){
                return(
                    <div key={index} className='w-full flex flex-col' >
                        <div className='w-full flex flex-row items-center mt-3'>
                            <input type="text" name='email' onChange={(e)=> editHandler(e, index)} className=" rounded-md bg-secondary text-primary items-center  text-xs pl-5 font-Rubik-regular text-darkmodefont w-300px h-12 " placeholder={item} />
                            <button onClick={()=> changeHandler()} className='h-12 ml-2 text-xs text-white font-rubik-medium' style={{borderRadius:'3px', width:'88px', backgroundColor:'#7D7D7D'}} >Done</button>
                        </div>
                        <p onClick={()=>  setEdit(-1)} className='font-heebo-medium text-sm text-ventlyRed mt-2 cursor-pointer' >Cancel</p>
                    </div> 
                )
            } else{
                return(
                    <div key={index} className='w-full h-10 flex flex-row items-center mt-3' >
                        <p className=' w-300px font-rubik-regular text-sm'>{item}</p>
    
                        <p className='flex font-rubik-regular text-sm ml-3'>2h ago</p>
                    
                        <div className='w-full flex flex-1' />
                        
                        <div className='flex' >
                            <button className='py-2 mr-1 bg-ventlyRed text-xs text-white font-rubik-medium ' style={{borderRadius:'3px', width:'71px'}} onClick={() =>  setEdit(index)} >Edit</button>
                            <button className='py-2 border-1 mx-1 border-ventlyRed text-xs text-ventlyRed font-rubik-medium ' style={{borderRadius:'3px', width:'71px'}} onClick={() => removeHandler(item)} >Delete</button>
                        </div>
                    </div>
                )
            }
        }
    }

    return(
        <div className='w-full'>
            {NewEmail(email, edit)}
            {!addmail ? (
                <div className='w-full h-10 flex flex-row items-center mt-3'>
                    <p onClick={()=> setAddMail(true)} className=' cursor-pointer font-heebo-medium text-sm text-ventlyRed' >+ Add User</p>
                </div>
            ):(
                <div className='w-full flex flex-col' >
                    <div className='w-full flex flex-row items-center mt-3'>
                        <input type="text" name='email' onChange={(e)=> inputHandler(e)} className=" rounded-md bg-secondary text-primary items-center  text-xs pl-5 font-Rubik-regular text-darkmodefont w-300px h-12 " placeholder='Event Name' />
                        <button onClick={clickHandler} className='h-12 ml-2 text-xs text-white font-rubik-medium' style={{borderRadius:'3px', width:'88px', backgroundColor:'#7D7D7D'}} >Done</button>
                    </div>
                    <p onClick={()=> setAddMail(false)} className='font-heebo-medium text-sm text-ventlyRed mt-2 cursor-pointer' >Cancel</p>
                </div> 
            )}
        </div>
    )
}