import React from "react"

import { FieldArray, Form, Field, Formik} from 'formik'
import * as yup from "yup";
import { MdClear } from'react-icons/md' 
import Input from './TicketField' 
import { EventContext, EventSchema } from "../../../../../Contexts/EventContext";

export default function EventTicket(props: any) {    

    const [display, SetDisplay] = React.useState(0);
    const eventContext: EventSchema = React.useContext(EventContext);

    const ticketSchema = yup.object({
        Ticket: yup.array()
            .of(
                yup.object().shape({
                    name: yup.string()
                        .required('Required')
                        .min(3, 'A minimium of 3 characters'),
                    amount: yup.number()
                        .required('Required'),
                    quantity: yup.number()
                        .required('Required'),
                    description: yup.string()
                        .required('Required')
                        .min(9, 'A minimium of 9 characters'),                      

                })
            ),
    })

    const Increase = (push: any) => {
            SetDisplay(display+1);
            push({name: '', amount: '', quantity: '', description: ''})
        }

    const Decrease = (index: any, remove: any) => {
        let intial = 0
        if (index !== intial){
            remove(index)
            SetDisplay(display-1)
        }
    }

    const ViewTicket = (index: any, item:any) => {
        
        if(index <  display){
            return(
                <div >
                    <div  className=' w-350px ml-20 flex flex-col justify-start text-primary'>
                        <h1 className=' font-rubik-bold text-md text-left' >{item.name} Ticket</h1>
                        <p className=' font-rubik-regular text-xs'>{item.description}</p>
                    </div>
                </div>
            )
        } else {

        }
    }   

    // const AddButton = (index:any, push:any) => {
    //     if(index >= display){
    //         return(
    //             <div className='absolute ml-300px' style={{marginTop:'-37px'}}>
    //                 <button onClick={()=> Increase(push)} className=' w-8 h-8 flex justify-center items-center bg-secondary shadow-md ml-4 ' style={{ borderRadius:'3px'}} ><MdAdd size='20' className='text-primary' /></button>
    //             </div>
    //         )
    //     }
    // }

    const MinusButton = (index: any, remove: any) => { 
        if(index !== 0)
        return(
            <div className='absolute ml-300px mt-20'>
                <button onClick={()=> Decrease(index, remove)} className=' w-8 h-8 flex justify-center items-center bg-secondary shadow-md ml-4 ' style={{ borderRadius:'3px'}} ><MdClear size='20' className='text-primary' /></button>
            </div>
        ) 
    } 

    return(
        <div className=' w-full h-full flex flex-col items-center justify-center rounded-lg' >
            <Formik 
                initialValues={{
                    Ticket: [{
                        name: '',
                        amount: '',
                        quantity: '',
                        description: '',
                        soldout: false
                    }],
                }}
                onSubmit = {(values) => { 
                    props.click()  
                    eventContext.setEventData({...eventContext.eventData, 
                        ticketsTypesList: values.Ticket
                    })
                }}
                validationSchema={ticketSchema}>

                {({ values }:any) => (
                    <Form className='w-full flex flex-col' >
                        <FieldArray name='Ticket'>
                            {({ push, remove }) => (
                                <div>
                                    {values.Ticket.map((item:any, index:any) => {
                                        return(
                                            <div key={index} className='w-full flex flex-row justify-center items-center' >
                                                {ViewTicket (index, item)} 
                                                <div className='w-auto bg-secondary flex ml-auto mr-auto flex-wrap mt-10 mb-4 p-px  rounded-md'>
                                                    <div  className='w-auto rounded-md bg-primary flex flex-row justify-center px-6 py-8 flex-row' >
                                                        <div className='flex-col mx-2 ' >
                                                            <div className='w-auto ' >
                                                                <p className="text-xs font-rubik-medium  mb-2">Ticket Name</p>
                                                                <Field name={`Ticket[${index}].name`} component={Input} />
                                                            </div>
                                                            <div className='w-auto mt-6'>
                                                                <p className="text-xs font-rubik-medium mb-2">Ticket Description</p>
                                                                <Field name={`Ticket[${index}].description`} component={Input} />
                                                            </div>
                                                        </div>
                                                        <div className=' flex-col mx-2 ' >
                                                            <div className='relative w-auto'>
                                                                <div className='w-auto ' >
                                                                    <p className="text-xs font-rubik-medium mb-2">Ticket Price</p>
                                                                    <Field name={`Ticket[${index}].amount`} component={Input} />
                                                                </div>
                                                                {MinusButton (index, remove)}
                                                                <div className='w-auto mt-6'>
                                                                    <p className="text-xs font-rubik-medium  mb-2">Number Of Tickets</p>
                                                                    <Field  name={`Ticket[${index}].quantity`} component={Input} />
                                                                </div>
                                                                <p className="text-xs font-rubik-regular mt-8">This ticket will become sold out once it gets to this <br/> number .Maximum number of tickets is 100,000</p>
                                                                {/* {AddButton (index, push)} */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> 
                                        )
                                        
                                    })}
                                    <div className='w-full flex justify-center items-center py-4 ' >
                                        <p onClick={()=> Increase(push)} className='cursor-pointer text-xs font-rubik-bold text-ventlyRed ml-540px'>Add More</p>
                                    </div>
                                </div>
                            )}
                        </FieldArray>  

                        <div className=' w-full flex flex-row justify-center ml-auto mr-auto pr-5' >
                            <p className='text-xs font-rubik-regular mr-40' >Entries would be saved automatically</p>
                            <div className=' mr-64'/>
                        </div>
                        <div className=' w-full h-20 flex mt-20 bg-secondary pt-px rounded-b-lg ' >
                            <div className='w-full h-full flex flex-row items-center bg-primary px-8 rounded-b-lg ' >
                                <div className='w-full flex flex-1' />
                                    <button id='thirdtab' type='submit' className='py-3 w-24 h-10 ml-40 bg-ventlyRed text-xs text-white font-rubik-medium' style={{borderRadius:'3px'}} >Continue</button>
                                </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )
}