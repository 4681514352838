import React from 'react'
import Skeleton from 'react-loading-skeleton'
import { useHistory } from 'react-router-dom'

import { NavLink } from 'react-router-dom'   
   
import { IUser, UserContext } from '../../../../Contexts/UserContext';

export default function Menu() {  

    const [loader, SetLoader] = React.useState(true); 
    const [fullname, SetFullname] = React.useState('');  
    const userContext: IUser = React.useContext(UserContext);  
    const history = useHistory();      
    let str = userContext.userData.firstname+' '+ userContext.userData.lastname

    React.useEffect(() => {  
        SetLoader(true);   
        const timing = setTimeout(() => {
            SetLoader(false);
        }, 2000);
        return () => clearTimeout(timing);  
    }, []);

    React.useEffect(() => {
        if(str.length > 25){
            return( 
                SetFullname(str.substring(0,25)+'...')
            )
        } else {
            return(
                SetFullname(str)
            )
        }
    }, [str]);

    const LogOut = () => {
        localStorage.clear();
        history.go(0);
    }

    return (
        <div className='w-full h-auto bg-primary text-primary rounded-br-lg pb-4 flex flex-col'>
            {!loader ? (
                <>
                    <div className='w-full px-2' > 
                        <div className='w-full flex flex-row items-center rounded-md mt-4 mb-5 pl-4 py-3 bg-secondary'>
                            <div style={{height:'60px', width:'60px'}} className='  rounded-full bg-gray-200 flex justify-center items-center' >
                                <img src = {userContext.userData.displaypic} className='w-full h-full flex flex-1 rounded-full object-cover' alt=""/>
                            </div>
                            <div className="ml-2">
                                <div className='flex flex-row items-center' >
                                    <h1 className="font-rubik-medium text-sm mt-1">{fullname}</h1>
                                    { userContext.userData.verified === true ?
                                        <svg className='mt-1 ml-1' xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24">
                                            <g  data-name="Group 165">
                                                <g  data-name="Group 164">
                                                <path style={{fillRule:'evenodd'}} data-name="Path 150" d="M24,0H0V-24H24Z" transform="translate(0 24)" fill="none" />
                                                <path className='fill-current text-accent' style={{fillRule:'evenodd'}} data-name="Path 151" d="M21.445,11.966a1.966,1.966,0,0,1-.327-2.29,1.968,1.968,0,0,0,.054-1.732A1.941,1.941,0,0,0,19.838,6.85a1.948,1.948,0,0,1-1.5-1.749,1.956,1.956,0,0,0-.884-1.487,1.923,1.923,0,0,0-1.708-.193,1.928,1.928,0,0,1-2.2-.652,1.928,1.928,0,0,0-3.082,0,1.928,1.928,0,0,1-2.2.652,1.924,1.924,0,0,0-1.708.193A1.956,1.956,0,0,0,5.665,5.1a1.949,1.949,0,0,1-1.5,1.749A1.941,1.941,0,0,0,2.828,7.944a1.967,1.967,0,0,0,.054,1.732,1.966,1.966,0,0,1-.327,2.29,1.963,1.963,0,0,0,.439,3.076,1.959,1.959,0,0,1,.953,2.1,1.966,1.966,0,0,0,.433,1.678,1.93,1.93,0,0,0,1.586.67A1.936,1.936,0,0,1,7.9,20.746,1.944,1.944,0,0,0,9.16,21.922a1.924,1.924,0,0,0,1.693-.3,1.925,1.925,0,0,1,2.295,0,1.931,1.931,0,0,0,2.957-.875A1.937,1.937,0,0,1,18.036,19.5a1.929,1.929,0,0,0,1.585-.67,1.966,1.966,0,0,0,.433-1.678,1.959,1.959,0,0,1,.953-2.1,1.963,1.963,0,0,0,.438-3.076ZM10.477,15.018l.515.482.5-.494L17,9.616,15.968,8.5l-4.975,4.888L8.007,10.627,7,11.766Z"  />
                                                </g>
                                            </g>
                                        </svg>
                                    :null}
                                </div>
                            <p className="text-xs font-rubik-regular">@{userContext.userData.username}</p>
                            </div>
                        </div >
                    </div> 
                    <NavLink activeClassName='bg-secondary text-ventlyRed font-rubik-medium  ' to='./feed' className='flex flex-row items-center hover:bg-secondary rounded-md m-2 p-3'>
                        <img src={require('../../../../assets/icons/post.svg')} className='ml-5' sizes='20px' alt=''  />
                        <h1 className="text-sm ml-5 "  >Feed</h1>
                    </NavLink>
                    <div className='flex flex-row hover:bg-secondary items-center rounded-md m-2 p-3'>
                        <img src={require('../../../../assets/icons/calendar.svg')} className='ml-5' sizes='20px' alt=''  />
                        <p className="font-rubik-regular  text-sm ml-5" >Events</p>
                    </div>
                    <div className='flex flex-row hover:bg-secondary items-center rounded-md m-2 p-3'>
                        <img src={require('../../../../assets/icons/tickets.svg')} className='ml-5' sizes='20px' alt='' />     
                        <p className="font-rubik-regular text-sm ml-5" >Tickets</p>
                    </div>
                    <div className='flex flex-row hover:bg-secondary items-center rounded-md m-2 p-3'>
                        <img src={require('../../../../assets/icons/download.svg')} className='ml-5' sizes='20px' alt=''  />
                        <p className="font-rubik-regular text-sm ml-5">Downloads & Purchases</p>
                    </div>
                    <div className='flex flex-row hover:bg-secondary items-center rounded-md m-2 p-3'>
                        <img src={require('../../../../assets/icons/chat.svg')} className='ml-5' sizes='20px' alt='' />
                        <p className="font-rubik-regular text-sm ml-5" >Messages</p>
                    </div>
                    <div className='flex flex-row hover:bg-secondary items-center rounded-md m-2 p-3'>
                        <img src={require('../../../../assets/icons/purse.svg')} className='ml-5' sizes='20px' alt='' />
                        <p className="font-rubik-regular text-sm ml-5" >Wallet</p>
                    </div>
                    <div className="mt-5 flex flex-row items-center rounded-md m-2 p-3">
                        <p className="font-rubik-regular text-sm ml-5 font-bold" >SHORTCUTS</p>
                    </div>
                    <div className='flex flex-row hover:bg-secondary items-center rounded-md m-2 p-3'>
                        <img src={require('../../../../assets/icons/calendar.svg')} className='ml-5' sizes='20px' alt=''  />
                        <p className="font-rubik-regular text-sm ml-5" >Upcoming Events</p>
                    </div>
                    <NavLink activeClassName='bg-secondary' to='./settings'  className='flex flex-row hover:bg-secondary items-center rounded-md m-2 p-3'>
                        <img src={require('../../../../assets/icons/settings.svg')} className='ml-5' sizes='20px' alt='' />
                        <p className="font-rubik-regular text-sm ml-5" >Settings</p>
                    </NavLink>
                    <div onClick={()=> LogOut()} className= 'flex hover:bg-secondary flex-row items-center cursor-pointer rounded-md m-2 p-3'>
                        <img src={require('../../../../assets/icons/logout.svg')} className='ml-5' sizes='20px' alt=''  />
                        <p className="font-rubik-regular text-sm ml-5" >Log Out</p>
                    </div>
                </>
            ):(
                <>
                    <div className='w-auto flex flex-row items-center rounded-md mr-5 mt-4 m-2 mb-5  p-3 bg-secondary'>
                        <Skeleton style={{width:'58px', height:'58px', marginLeft:'1.25rem', borderRadius:'100%'}} />
                        <div className="ml-2 flex flex-col mt-1">
                            <Skeleton style={{width:'100px', height:'0.875rem'}}/>
                            <Skeleton style={{width:'60px', height:'0.75rem'}} />
                        </div>
                    </div >
                    <div className='flex flex-row items-center rounded-md m-2 p-3'>
                        <Skeleton style={{width:'25px', height:'25px', marginLeft:'1.25rem', borderRadius:'100%'}} />
                        <div className='mt-2'>
                            <Skeleton style={{width:'70px', height:'0.875rem', marginLeft:'1.25rem'}}/>
                        </div>
                    </div>
                    <div className='flex flex-row items-center rounded-md m-2 p-3'>
                        <Skeleton style={{width:'25px', height:'25px', marginLeft:'1.25rem', borderRadius:'100%'}} />
                        <div className='mt-2'>
                            <Skeleton style={{width:'70px', height:'0.875rem', marginLeft:'1.25rem'}}/>
                        </div>
                    </div>
                    <div className='flex flex-row items-center rounded-md m-2 p-3'>
                        <Skeleton style={{width:'25px', height:'25px', marginLeft:'1.25rem', borderRadius:'100%'}} />
                        <div className='mt-2'>
                            <Skeleton style={{width:'70px', height:'0.875rem', marginLeft:'1.25rem'}}/>
                        </div>
                    </div>
                    <div className='flex flex-row items-center rounded-md m-2 p-3'>
                        <Skeleton style={{width:'25px', height:'25px', marginLeft:'1.25rem', borderRadius:'100%'}} />
                        <div className='mt-2'>
                            <Skeleton style={{width:'120px', height:'0.875rem', marginLeft:'1.25rem'}}/>
                        </div>
                    </div>
                    <div className='flex flex-row items-center rounded-md m-2 p-3'>
                        <Skeleton style={{width:'25px', height:'25px', marginLeft:'1.25rem', borderRadius:'100%'}} />
                        <div className='mt-2'>
                            <Skeleton style={{width:'70px', height:'0.875rem', marginLeft:'1.25rem'}}/>
                        </div>
                    </div>
                    <div className='flex flex-row items-center rounded-md m-2 p-3'>
                        <Skeleton style={{width:'25px', height:'25px', marginLeft:'1.25rem', borderRadius:'100%'}} />
                        <div className='mt-2'>
                            <Skeleton style={{width:'70px', height:'0.875rem', marginLeft:'1.25rem'}}/>
                        </div>
                    </div>
                    <div className="mt-5 flex flex-row hover:bg-secondary items-center rounded-md m-2 p-3">
                        <Skeleton style={{width:'90px', height:'1rem', marginLeft:'1.25rem'}}/>
                    </div>
                    <div className='flex flex-row items-center rounded-md m-2 p-3'>
                        <Skeleton style={{width:'25px', height:'25px', marginLeft:'1.25rem', borderRadius:'100%'}} />
                        <div className='mt-2'>
                            <Skeleton style={{width:'70px', height:'0.875rem', marginLeft:'1.25rem'}}/>
                        </div>
                    </div>
                    <div className='flex flex-row items-center rounded-md m-2 p-3'>
                        <Skeleton style={{width:'25px', height:'25px', marginLeft:'1.25rem', borderRadius:'100%'}} />
                        <div className='mt-2'>
                            <Skeleton style={{width:'70px', height:'0.875rem', marginLeft:'1.25rem'}}/>
                        </div>
                    </div>
                    <div className='flex flex-row items-center rounded-md m-2 p-3'>
                        <Skeleton style={{width:'25px', height:'25px', marginLeft:'1.25rem', borderRadius:'100%'}} />
                        <div className='mt-2'>
                            <Skeleton style={{width:'70px', height:'0.875rem', marginLeft:'1.25rem'}}/>
                        </div>
                    </div>
                </>
            )}
        </div>
    )
}
