import React from 'react'
import './styles/event.css'

import { FiPlusCircle, FiMoreVertical} from 'react-icons/fi' 

export default function EventPhoto(props: any) {  

    return (
        <div className="w-full h-auto text-primary rounded-md shadow bg-primary flex flex-col mb-8"> 
            <div className="w-full flex justify-between px-5 pt-5 ">
                <div className="flex">
                    <div style={{height:'50px', width:'50px'}} className=' rounded-full bg-gray-200 flex mr-2 justify-center items-center' >
                        <img src = {props.userpic} className='w-full h-full flex flex-1 rounded-full object-cover' alt=""/>
                    </div>
                    {/* <img src={require('../../../../assets/images/avatar.png')} width="50" alt=""/> */}
                    <div className="ml-2">
                        <p className="font-rubik-medium text-sm mt-1">Sandra Michaels</p>
                        <p className="text-xs font-rubik-regular">@sandra124</p>
                    </div>
                </div>

                <div className="flex">
                    <div className="flex pt-2 mr-6">
                        <FiPlusCircle className='bg-primary' size={25} />
                        <p className="text-xs  ml-2 mt-1">Follow</p>
                    </div>
                    <div className="pt-2">
                        <FiMoreVertical className='bg-primary' size={25} />
                    </div>
                </div>
            </div>

            <div className='w-auto mt-4 mx-4 flex flex-row' >
                <div className='w-full flex mx-1' >
                    <img src={props.firstimg} className='w-full rounded-md' alt='' />
                </div>
                <div className='w-full flex mx-1 rounded-md' style={{backgroundImage:`url(${props.secondimg})`, width:'100%', backgroundRepeat:'no-repeat', backgroundSize:'cover'}} >
                    <div className='w-full flex justify-center items-center bg-black bg-opacity-50 rounded-md' >
                        <p className='font-rubik-medium text-white text-sm mt-1'>VIEW MORE PHOTOS</p>
                    </div>
                </div>
            </div>

            <div className=' mt-5 flex fles-row ml-5 mb-8'>
                <p className="text-sm font-rubik-regular mr-2">Samual Kington added 9 new photo to the event: </p>
                <p className="text-sm text-ventlyRed font-rubik-regular">The Xperience Lagos, 6th Edition</p>
            </div>    
        </div>
    )
}
